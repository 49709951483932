import React, { Component } from 'react'
import Platforms from "../components/Platforms";
import Clipboard from 'react-clipboard.js';


export default class LeaveAReviewSection extends Component {
  state = {
    copySuccess: false
  }

  onSuccess = () => {
    this.setState({ copySuccess: !this.state.copySuccess })
  }

  getText = () => {
    let review = localStorage.getItem('reviewloop_review');
    return review
  }

  render() {
    let review;
    if (localStorage.getItem('reviewloop_review') === null) {
      review = "";
    } else {
      review = localStorage.getItem('reviewloop_review');
    };
    return (
      this.props.reviewRating >= 4 ?
        <div className="rating-container">
          <h2>Fantastic! 🎉</h2>
          {this.props.platforms.length > 0 &&
            <div>
                <p>Use any of the links below to leave us a review</p>
                {review.length > 0 &&
                <div className="clipboard-container">
                    <p>Copy your message and share on any of the links below!</p>
                    <div className="input-group mb-2 mr-sm-2">
                        <input type="text" disabled className="form-control" id="inlineFormInputGroupUsername2"
                               value={review}/>
                        <div className="input-group-append">
                            <Clipboard option-text={this.getText} onSuccess={this.onSuccess}>
                                <div>
                          <span
                              className={this.state.copySuccess === true ? "copy-success" : ''}>{this.state.copySuccess === true ? "Copied!" : "Copy"}
                              <i className="fa fa-clone"></i>
                          </span>
                                </div>
                            </Clipboard>
                        </div>
                    </div>
                </div>
                }
                <Platforms platforms={this.props.platforms} hash={this.props.reviewHash} />
            </div>
          }
        </div >
        :
        <div className="rating-container">
          <h2>Thank you for your feedback!</h2>
        </div>
    )
  }
}
