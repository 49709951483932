import React, { Component } from 'react'
import CSVDescription from './CSVDescription';
import AddReview from './AddReview';
import CustomerInput from "./CustomerInput";
const Papa = require("papaparse/papaparse.min.js");


const firstNameOptions = ['first_name', 'First_Name','First_name', 'FIRST_NAME', 'FirstName', 'First Name', 'first name', 'name', 'Name', 'firstname'];
const lastNameOptions = ['last_name', 'Last_Name', 'Last_name','LAST_NAME', 'LastName', 'Last Name', 'last name', 'lastname'];
const phoneOptions = ['phone_number', 'phone number', 'Phone Number', 'PHONE NUMBER', 'Phone_Number', 'Number', 'number', 'telephone', 'TELEPHONE', 'cell_phone', ' CELL_PHONE', ' Cell', 'CELL', 'Cell Phone', 'cell phone', 'phone', 'PHONE'];
export default class CSVSection extends Component {
    state = {
        fileName: "No File Chosen",
        fileData: []
    }

    validateData = (data) => {
        data.forEach(item => {
            item.hasOwnProperty('first_name') === false && (this.setState({ incorrectDataFormatError: true }));
            item.hasOwnProperty('last_name') === false && (this.setState({ incorrectDataFormatError: true }));
            item.hasOwnProperty('phone_number') === false && (this.setState({ incorrectDataFormatError: true }));
        });
        let phone;
        // Copy current fileData state
        let originalData = this.state.fileData;
        let reviews = [];
        reviews = originalData.slice();
        // Push batch data onto FileData
        data.forEach(item => reviews.push(item));
        // Verify content exists
        if (reviews.length >= 1) {
            // Validate all items in fileData
            reviews.forEach((element, index) => {
                element.id = index;
                element.valid = true;
                if (element.phone_number) {
                    phone = element.phone_number.replace(/[^0-9]/g, '');
                } else {
                    phone = '';
                }
                element.phone_number = phone;
                element.editing = false;
                for (let key in element) {
                    element[key].length === 0 && (element.valid = false);
                }
                element.phone_number.length !== 10 && (element.valid = false);
                let isNum = /^\d+$/.test(element.phone_number);
                isNum === false && (element.valid = false);
            })
        }
        this.props.liftState(reviews)
    }

    formatData = (result) => {
        const fileData = result;
        let newArray = fileData.reduce((all, customer, index) => {
            let obj = obj || {};
            for (let key in customer) {
                let trimKey = key.trim();
                let normalized = trimKey.toLowerCase();
                if (firstNameOptions.includes(normalized)) {
                    obj.first_name = customer[key];
                }
                if (lastNameOptions.includes(normalized)) {
                    obj.last_name = customer[key];
                }
                if (phoneOptions.includes(normalized)) {
                    obj.phone_number = customer[key];
                }
            }
            all.push(obj);
            return all;
        }, [])
        this.validateData(newArray)
    }

    handleFile = e => {
        const file = e.target.files[0];
        if (file === undefined) {
            this.props.displayCSVError();
        } else {
                this.setState({ fileName: file.name })
                Papa.parse(file, {
                    header: true,
                    download: false,
                    skipEmptyLines: true,
                    complete: (results, file) => {
                        if(results.errors.length > 10){
                            this.props.displayCSVError();
                        } else {
                            this.formatData(results.data)
                        }
                    }
                })
        }
    };
    render() {
        return (
            <div className="csv-container">
                <CSVDescription handleFile={this.handleFile} fileName={this.state.fileName} />
                {
                    this.props.customerInfo.length !== 0 &&
                    <AddReview
                        customerInfo={this.props.customerInfo}
                        handleInputChange={this.props.handleInputChange}
                        onPlatformChange={this.props.onPlatformChange}
                        page={this.props.page}
                        liftState={this.props.liftState}
                        type={this.props.type}
                        removeReview={this.props.removeReview}
                        handleNextNotePage={this.props.handleNextNotePage}
                        handlePrevNotePage={this.props.handlePrevNotePage}
                        saveReview={this.props.saveReview}
                    />
                }
            </div>
        )

    }
}
