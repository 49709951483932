import React, { Component } from 'react'

export default class PaymentErr extends Component {


    render() {
        return (
            (this.props.paymentErrorMessage || this.props.inputError) ?
                <div>
                    <p className="stripe-input-error">{this.props.paymentErrorMessage || this.props.inputError}</p>
                </div>
                : null
        )
    }
}
