
const phoneValid = (currentValue) => {
    let phone = currentValue.phone_number.replace(/[^0-9]/g, '');
    return phone.length === 10;
}
const phoneIsNum = (currentValue) => {
    let phone = currentValue.phone_number.replace(/[^0-9]/g, '');
    let isNum = /^\d+$/.test(phone);
    return isNum === true;
}
const firstNameValid = (currentValue) => {
    return currentValue.first_name.length !== 0;

}
const lastNameValid = (currentValue) => {
    return currentValue.last_name.length !== 0;

}

export const validateInput = (data) => {
    let originalData = data;
    let newArray = [];
    newArray = originalData.slice();
    let phone = newArray.every(phoneValid);
    let phoneNum = newArray.every(phoneIsNum);
    let firstName = newArray.every(firstNameValid);
    let lastName = newArray.every(lastNameValid);
    if (phone === true && phoneNum === true && firstName === true && lastName === true) {
        return false
    } else {
        return true;
    }
}