import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { fetchReview, updateReview } from "../redux/actions";
import { ViewWithLoader } from "../../../common";
import logo from "../../../images/logo-white.svg";

import FilterReviewForm from "../components/FilterReviewForm";

import ReviewEmojis from "./ReviewEmojis";
import LeaveAReviewSection from './LeaveAReviewSection';
class ReviewsShow extends Component {

  state = {
    isFetching: true,
    display: "Review",
  };

  static defaultProps = {
    reviewData: {},
  }


  componentDidMount() {
    this.props.fetchReview(this.props.match.params.review_hash, () => {
      this.setState({ isFetching: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.reviewData || {}).rating !== (this.props.reviewData || {}).rating) {
      if (this.props.reviewData.rating !== null && this.props.reviewData.review !== null) {
        this.setState({ display: "Platform" });
      } else {
        this.setState({ display: "Form" });
      }
    }
  }

  handleDisplayAndFetchingState = (obj) => {
    this.setState(obj);
  }

  render() {
    const reviewData = this.props.reviewData || {};

    const enabledPlatforms = (reviewData.platform_ids || []).map(n => parseFloat(n));
    const platforms = ((reviewData.location || {}).platforms || []).filter(n => {
      return (enabledPlatforms.includes(parseFloat(n.id)));
    });
    let { customer, location, rating } = reviewData;
    return (
      <ViewWithLoader
        isLoading={this.state.isFetching}
      >
        <div className={'company-name'}>{(location || {}).name}</div>
        <div className="col-12">
          {
            ((!rating && this.state.display === "Review") || this.state.display === "Review")
            &&
            <ReviewEmojis handleRatingSelection={this.handleRatingSelection}
              customer={customer}
              location={location}
              handleDisplayAndFetchingState={this.handleDisplayAndFetchingState}
              reviewHash={this.props.match.params.review_hash}
              updateReview={this.props.updateReview}
            />
          }
          {
            (!reviewData.review && this.state.display === "Form")
            &&
            <FilterReviewForm
              reviewRating={rating}
              location={location}
              handleDisplayAndFetchingState={this.handleDisplayAndFetchingState}
              reviewHash={this.props.match.params.review_hash}
              updateReview={this.props.updateReview}
            />
          }
          {
            (this.state.display === "Platform")
            &&
            <LeaveAReviewSection
              reviewRating={rating}
              location={location}
              platforms={platforms}
              reviewHash={this.props.match.params.review_hash}
              handleDisplayAndFetchingState={this.handleDisplayAndFetchingState}
            />
          }
        <div className={'powered-by-wrapper'}>
          <div>
            <div>Powered by</div>
            <img src={logo} alt="Studio 3 CRM" />
          </div>
        </div>
        </div>
      </ViewWithLoader>
    );
  }
}


const mapStateToProps = ({
  reviews: { show: { data: reviewData } }
}) => ({
  reviewData
});

const connected = connect(mapStateToProps, {
  fetchReview,
  updateReview
})(ReviewsShow);

export { connected as ReviewsShow };
