import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from "redux-thunk";
import createRootReducer from "../reducers/rootReducer";


export const history = createBrowserHistory();

// eslint-disable-next-line
const isDevelopmentMode = "development" === process.env.NODE_ENV;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && isDevelopmentMode
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const middlewares = [
  thunkMiddleware,
  routerMiddleware(history),
  //...Object.values(todosMiddleware)
];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export const store = createStore(createRootReducer(history), enhancer);
