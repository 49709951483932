import React, {Component} from "react";
import {bool, number, object, string} from "prop-types";


class TagInput extends Component {

  state = {
    value: '',
    tags: []
  };

  static propTypes = {
  };

  static defaultProps = {
    onChange: function() {},
    validate: function() { return true; }
  };

  handleComponentClick(e) {
    const input = [...e.currentTarget.getElementsByTagName('input')][0] || {};
    if (input) {
      input.focus();
    }
  }

  componentDidMount() {
    this.setState({
      tags: Array.isArray(this.props.value) ? this.props.value : [this.props.value || '']
    });
  }

  handleInputKeyDown(e) {
    if (e.key === 'Backspace') {
      let value = String(this.props.format(this.state.value));
      if (value === '') {
        const tags = this.state.tags;
        if (tags.length > 0) {
          const last = tags.pop();
          this.setState({
            tags: tags,
            value: last
          });
          this.props.onChange(tags);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }

  handleInputKeyUp(e, submit) {
    if (submit || e.keyCode === 13) {
      let value = this.props.format(this.state.value)
      const valid = this.props.validate(value);
      if (valid) {
        const newTags = [...this.state.tags, value];
        this.setState({
          tags: newTags,
          value: '',
          valid: true
        });
        this.props.onChange(newTags);
        return;
      }
      this.setState({ valid: false });
      return;
    }


    let value = String(this.props.format(this.state.value)).trim();
    let last = value.slice(value.length - 1);
    if (last === ',') {
      this.setState({
        value: value.replace(/,/g, '')
      });
      setTimeout(() => {
        this.handleInputKeyUp(e, true);
      }, 50)
    }


  }

  handleInputChange(e) {
    let val = e.currentTarget.value;
    this.setState({
      value: val,
      valid: true
    });
  }

  handleTagDeleteClick(e, tag) {
    const tags = this.state.tags.filter(n => n !== tag);
    this.setState({ tags });
    this.props.onChange(tags);
  }

  render() {

    const validClass = (this.state.valid === false) ? 'invalid' : '';
    return (
      <div className={'tag-input'} onClick={e => this.handleComponentClick(e)}>
        <div className={'wrapper'}>
          {this.state.tags.map((n, idx) => (
            <div key={idx} className={'tag'}>
              <div className={'tag-label'}>{n}</div>
              <div className={'tag-delete'} onClick={e => this.handleTagDeleteClick(e, n)}>X</div>
            </div>
          ))}
          <input className={validClass} type={'text'} value={this.state.value} onChange={e => this.handleInputChange(e)} onKeyDown={e => this.handleInputKeyDown(e)} onKeyUp={e => this.handleInputKeyUp(e)} placeholder={this.props.placeholder || '' } />
        </div>
      </div>
    );
  }

}

export { TagInput };