import React, { Component } from 'react'
import ReviewWidget from "./ReviewWidget";
import { MiniLoader } from "../../../common";
import Slider from 'react-slick';



export default class PreviewWidgetContainer extends Component {
    state = {
        positionChangeEffect: null,
    }
    triggerEffect = (id, pos, direction) => {
        if (this.state.positionChangeEffect === null) {
            this.setState({ positionChangeEffect: id });
            (pos !== null && direction === 'right') && this.slider.slickGoTo(pos + 1);
            (pos !== null && direction === 'left') && this.slider.slickGoTo(pos - 1);
        } else {
            this.setState({ positionChangeEffect: null });
        }
    }

    componentDidMount() {
        let locationId = this.props.parentContext.props.match.params.location_id
        this.props.fetchFeaturedReviews(locationId);
    }

    render() {
        let sorted = [];
        const settings = {
            dots: true,
            infinite: true,
            centerMode: true,
            centerPadding: "60px",
            speed: 500,
            variableWidth: true,
            slidesToShow: 1,
            arrows: false,
        };
        if (this.props.featuredReviews.length > 0) {
            sorted = this.props.featuredReviews.sort((a, b) => {
                if (a.pos < b.pos) return -1;
                if (a.pos > b.pos) return 1;
                return 0;
            });
        }
        let lastPositionIndex = sorted.length - 1;
        return (
            <div className="preview-widget-container col-sm-12 col-md-12 col-lg-12">
                {
                    this.props.isLoading ? <MiniLoader />
                        :
                        <div className={this.props.featuredReviews.length === 1 ? `slickContainer-adjusted` : `slickContainer`} >
                            {
                                this.props.featuredReviews.length === 0 ? <h1>No Reviews Featured</h1> :
                                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                                        {sorted.length > 0 && sorted.map((data, i) => (
                                            <div key={Math.random()} className={'slick-with-count-container'}>
                                                <ReviewWidget
                                                    {...this.props}
                                                    fetchFeaturedReviews={this.props.fetchFeaturedReviews}
                                                    changeFeaturedReviewsPosition={this.props.changeFeaturedReviewsPosition}
                                                    featureReview={this.props.featureReview}
                                                    reviewsInPosition={sorted}
                                                    positionChangeEffect={this.state.positionChangeEffect}
                                                    triggerEffect={this.triggerEffect}
                                                    {...data}
                                                    key={Math.random()}
                                                    lastPositionIndex={lastPositionIndex}
                                                    position={i}
                                                    dates={this.props.dates}
                                                    locationId={this.props.locationId}
                                                    test={'test'}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                            }
                        </div>
                }
            </div>
        )
    }
}
