import React, {Component} from 'react';
import { MiniLoader } from "../../../common";

class YelpListing extends Component {

  state = {
    isFetching: true,
  };

  componentDidMount(){
    this.props.fetchYelp(()=>{
      this.setState({
        isFetching: false
      })
    });
  };

  render() {
    let {data} = this.props;
    let stars = [];
    let i = 0;
    for(let x = 1; x < data.rating; x++){
      i++;
      stars.push(<i key={i} className="fa fa-star"></i>);
    }
    if(data.rating % 1 !== 0){
      i++;
      stars.push(<i key={i} className="fa fa-star-half"></i>);
    }
    while(stars.length < 5){
      i++;
      stars.push(<i key={i} className="fa fa-star empty"></i>);
    }
    return this.state.isFetching ? <MiniLoader /> :
      <div className="listing-item">
        <img className="listing-platform" src="https://s3-us-west-1.amazonaws.com/reviewloop-platforms/yelp.png" alt=""/>
        <h3 className="listing-title">{data.name}</h3>
        {data.is_claimed &&
          <span className="listing-claimed"><i className="fa fa-check-circle"></i> Claimed</span>
        }
        <span className="listing-rating">
          {stars}
        </span>
      </div>
  };

}

export default YelpListing;