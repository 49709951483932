import React, { Component, Fragment } from "react";
import { object } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import ProtectedRoute from "./routers/ProtectedRoute";
import { Navbar } from "./common";
import ReactGA from "react-ga";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { LocationsWidgetAdmin } from "./screens/locations/scenes/LocationsWidgetAdmin";

import {
  UsersActivate,
  UsersAuthenticate,
  UsersNew,
  Registration,
  UserSettings,
} from "./screens/users/scenes";
import {
  LocationsIndex,
  LocationsShow,
  LocationsEdit,
  LocationsReviewRequest,
  LocationsCreate,
} from "./screens/locations/scenes";
import { ReviewsShow, OrganicReviewsShow } from "./screens/reviews/scenes";
import Home from "./screens/home";

class App extends Component {
  static propTypes = {
    router: object,
  };

  componentDidMount() {
    const { pathname } = this.props.router.location;
    document.body.className = this.generateClassNames(pathname);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.router.location !== this.props.router.location) {
      const { pathname } = this.props.router.location;
      document.body.className = this.generateClassNames(pathname);
    }
  }

  initializeReactGA = (pathname) => {
    ReactGA.initialize("UA-131214234-1");
    ReactGA.pageview(pathname);
  };

  generateClassNames = (pathname) => {
    let classNames = pathname
      .split("/")
      .filter((route) => route.length)
      .filter((item) => !item.match(/\d/g))
      .join(" ");
    return "route-" + (classNames ? classNames : "home");
  };

  render() {
    const { pathname } = this.props.router.location;
    this.initializeReactGA(pathname);

    return (
      <Fragment>
        <Navbar />
        <div className="app-container container-fluid">
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={UsersAuthenticate} />
          <Route exact path="/register" component={Registration} />
          <ProtectedRoute path="/account" component={UserSettings} />

          <Switch>
            <ProtectedRoute
              exact
              path="/locations/create"
              component={LocationsCreate}
            />
            <ProtectedRoute
              exact
              path="/locations/:location_id"
              component={LocationsShow}
            />
            <ProtectedRoute
              exact
              path="/locations/:location_id/settings"
              component={LocationsEdit}
            />
            <ProtectedRoute
              exact
              path="/locations/:location_id/request"
              component={LocationsReviewRequest}
            />
            <ProtectedRoute
              exact
              path="/locations/:location_id/widget"
              component={LocationsWidgetAdmin}
            />
            <ProtectedRoute
              exact
              path="/locations"
              component={LocationsIndex}
            />
          </Switch>

          <Switch>
            <Route exact path="/reviews/:review_hash" component={ReviewsShow} />
            <Route
              exact
              path="/new-review/:location_hash"
              component={OrganicReviewsShow}
            />
          </Switch>

          {/* <SuperRoute exact path="/users/new" component={UsersNew} /> */}
          <Route exact path="/activate/:id" component={UsersActivate} />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({
  user: {
    account: { data: userData },
  },
  router,
}) => ({
  userData,
  router,
});

export default withRouter(connect(mapStateToProps)(App));
