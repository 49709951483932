import "bootstrap/dist/css/bootstrap.min.css";
import "react-select/dist/react-select.css";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/main.css";
import { StripeProvider } from "react-stripe-elements";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import rollbar from "rollbar";

import { store, history } from "./store/index";
import App from "./App";
import { authLoginAndRedirect } from "./screens/users/actions";
let _rollbarConfig = {
  accessToken: "068a1b8c7ef34f89a0901d8cc71d63db",
  captureUncaught: true,
  payload: {
    environment: process.env.NODE_ENV,
  },
};
const Rollbar = new rollbar(_rollbarConfig);
window.Rollbar = Rollbar;

let token = localStorage.getItem("token");

if (token !== null && token !== "undefined" && token !== "null") {
  store.dispatch(authLoginAndRedirect(token));
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <App />
      </StripeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
