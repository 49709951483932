export const trimName = (name) => {
  if(name){ 
    if(name.length > 0){
      if(name.length > 27){
        let shortenedName = name.substring(0 , 27).trim();
        return `${shortenedName}...`
      }
    }
  }
  return name;
}

export const insertAtCursor = (el, value) => {
  if (el.selectionStart || el.selectionStart == '0') {
    let startPos = el.selectionStart;
    let endPos = el.selectionEnd;
    el.value = el.value.substring(0, startPos)
      + value
      + el.value.substring(endPos, el.value.length);
    setTimeout(() => {
      el.selectionEnd = endPos + String(value).length;
    }, 50);
  } else {
    el.value += value;
  }
}
