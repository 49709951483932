import React, { Component } from 'react';
import { array, func } from "prop-types";

import { connect } from 'react-redux';
import { fetchLocation } from "../redux/actions";
import { createReview } from "../../reviews/redux/actions";

import RequestReview from "../../reviews/components/RequestReview";
import { ViewWithLoader } from "../../../common";
import ErrorBoundry from "../../reviews/components/ErrorBoundry";

class LocationsReviewRequest extends Component {

  static propTypes = {
    fetchLocation: func,
    locationsData: array
  };

  state = {
    isFetching: true,
  };

  mounted = true;

  componentDidMount() {
    this.props.fetchLocation(this.props.match.params.location_id, () => {
      this.mounted && this.setState({ isFetching: false });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const current_period_end = this.props.user.authentication.data.current_period_end;
    return (
      <ViewWithLoader
        isLoading={this.state.isFetching}
      >
        <ErrorBoundry>
          <RequestReview placeholder={this.props.locationData.settings.default_message} reviewsData={this.props.reviewsData} currentPeriodEnd={current_period_end} createReview={this.props.createReview} locationData={this.props.locationData} />
        </ErrorBoundry>
      </ViewWithLoader>
    )
  }
}

const mapStateToProps = ({
  locations: { show: { data: locationData } },
  reviews: { create: { data: reviewsData } },
  user,
}) => ({
  locationData,
  reviewsData,
  user,
});

const connected = connect(mapStateToProps, {
  fetchLocation,
  createReview
})(LocationsReviewRequest);

export { connected as LocationsReviewRequest };