import { store } from "../store";
import { authLogoutAndRedirect } from "../screens/users/actions";

export const dispatchHelper = (type, dispatch = store.dispatch) => {
  return (response, ...fns) => {
    if (response.status >= 400) {
      dispatch({
        type: `${type}_FAIL`,
        payload: response.data.error_message
      });
      if(type === "REGISTER_USER" || type === "CREATING_PASSWORD"){
        fns.forEach(fn => fn());
      }
    } else if (response.status >= 200 && response.status < 400) {
      dispatch({
        type: `${type}_SUCCESS`,
        payload: response.data,
        ...response.middleware
      });
      fns.forEach(fn => fn());
    } else {
      localStorage.setItem(
        "redirectPath",
        store.getState().router.location.pathname
      );
      dispatch(authLogoutAndRedirect());
    }
  };
};
