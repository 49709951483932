import React, {Component} from "react";
import { func, string} from "prop-types";

class SelectField extends Component {

  state = {
    active: false,
  };

  static propTypes = {
    onSelection: func,
    placeholder: string
  };

  static defaultProps = {
    onSelection: function() {},
    placeholder: ''
  };

  handleButtonClick(e) {
    this.setState({ active: !this.state.active });
  }

  handleOptionClick(e, option) {
    this.setState({ active: false });
    this.props.onSelection(option);
  }

  render() {
    const activeClass = (this.state.active) ? 'active' : '';
    return (
      <div className={`select-field ${activeClass}`}>
        <div className={'select-field-button'} onClick={e => this.handleButtonClick(e)}>{this.props.placeholder}</div>
        <div className={'select-field-options'}>
          {(this.props.options || []).map(n => (
            <div className={'select-field-option'} key={n.key} onClick={e => this.handleOptionClick(e, n)}>{n.value}</div>
          ))}
        </div>
      </div>
    );
  }

}

export { SelectField };

