export const AUTHENTICATING_USER = "AUTHENTICATING_USER";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCHING_USER = "FETCHING_USER";
export const UPDATING_USER_PROFILE = "UPDATING_USER_PROFILE";
export const UPDATING_USER_PASSWORD = "UPDATING_USER_PASSWORD";
export const CREATING_USER = "CREATING_USER";
export const CREATING_PASSWORD = "CREATING_PASSWORD";
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const REGISTER_USER = "REGISTER_USER";
export const CHARGE_USER = "CHARGE_USER";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATING_USER_PAYMENT = "UPDATING_USER_PAYMENT";
export const UPDATE_PAYMENT_ERROR_NOTIFICATION = "UPDATE_PAYMENT_ERROR_NOTIFICATION";
export const CANCELLING_USER_SUBSCRIPTION = "CANCELLING_USER_SUBSCRIPTION";

