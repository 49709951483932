import React, { Component } from "react";
import { Button, ViewWithLoader } from "../../../common";
import ReviewWidget from "../components/ReviewWidget";
import { MiniLoader } from "../../../common";

export default class Paginate extends Component {
    state = {
        page: 1,
        isLoading: false,
        endIndex: 1,

    }
    renderData = () => {
        let originalData = this.props.data;
        let newArray = [];
        let endIndex = this.props.itemsPerPage * this.state.page;
        let startIndex = endIndex - this.props.itemsPerPage;
        newArray = originalData.slice(startIndex, endIndex);
        return newArray.map((item, i) => {
            return this.props.dataToRender({ ...item }, i, this.props.dates, this.props.featureReview, this.props.noArrows, this.props.addingOrRemovingWidgetFromFeatured, this.props.triggerWidgetAnimation)
        });
    };

    handlePrevNotePage = () => {
        this.setState({ isLoading: true, page: this.state.page - 1 }, () => { this.setState({ isLoading: false }) });
    };

    handleNextNotePage = () => {
        this.setState({ isLoading: true, page: this.state.page + 1 }, () => { this.setState({ isLoading: false }) });
    };

    render() {
        let buttonDisabledLeft = false;
        let buttonDisabledRight = false;
        let pageLimit = Math.ceil(this.props.data.length / this.props.itemsPerPage);
        if (this.state.page === 1) {
            buttonDisabledLeft = true;
        }
        if (this.state.page === pageLimit) {
            buttonDisabledRight = true;
        }
        return (
            <div className={this.props.wrapperClass || ""}>
                {this.renderData()}
                <div className="reviews-pagination-buttons">
                    <span>Page {this.state.page}/{pageLimit}</span>
                    <Button classes={"mr-2"} key="chevron-left" disabled={buttonDisabledLeft} onClick={() => this.handlePrevNotePage()} inverse>
                        <i className="fas fa-chevron-left" />
                    </Button>
                    <Button key="chevron-right" disabled={buttonDisabledRight} onClick={() => this.handleNextNotePage()} inverse>
                        <i className="fas fa-chevron-right" />
                    </Button>
                </div>
            </div>
        );
    }
}
