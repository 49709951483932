import React from "react";
import { Button } from "../../../common";

const YelpInfo = props => {
    return props.yelpData.map(item => {
        return (
            <div key={item.id} className="selection-info">
                <div className="yelp-selection-info-container">
                    <h2>{item.name}</h2>
                    <p>{item.location.address1}, {item.location.city}, {item.location.zip_code}</p>
                </div>
                <div className="yelp-selection-button-container">
                    <Button classes={"tresio-btn-small"} onClick={() => { props.setYelpId(item.id) }}>Save</Button>
                </div>
            </div>
        )
    })
};

const YelpSelection = props => {
    if (props.yelpData.length >= 2) {
        return (
            <div className="container selection-container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-5 col-xl-12 reviews-title-container">
                        <p className="reviews-font-headline">Multiple pages were found, please select the correct Yelp page:</p>
                        <Button onClick={() => props.clearYelpBusiness()}>Close</Button>
                    </div>
                </div>
                <YelpInfo setYelpId={props.setYelpId} yelpData={props.yelpData} />
            </div>
        )
    } else {
        return null;
    }
}


export { YelpSelection };