import React, { Component } from 'react'
import ReviewWidget from "./ReviewWidget";
import Paginate from "./Paginate";
import { MiniLoader } from "../../../common";

export default class ReviewWidgetContainer extends Component {
    render() {
        if (this.props.isLoading) {
            return <MiniLoader />
        } else {
            if (this.props.data.length === 0 && this.props.isLoading === false) {
                return <p className="no-data-desc">No reviews for your selected date range</p>
            } else {
                return (
                    <div className="widget-container">
                        <Paginate
                            itemsPerPage={9}
                            wrapperClass="paginateMargin row p-0"
                            data={this.props.data}
                            dates={this.props.dates}
                            dataToRender={(props, i, dates, featureReview, noArrows, addingOrRemovingWidgetFromFeatured, triggerWidgetAnimation) => <div className="col-12 col-md-4 mb-3" key={Math.random()}><ReviewWidget triggerWidgetAnimation={triggerWidgetAnimation} addingOrRemovingWidgetFromFeatured={addingOrRemovingWidgetFromFeatured} noArrows={noArrows} featureReview={featureReview} {...props} key={i} dates={dates} /></div>}
                            featureReview={this.props.featureReview}
                            noArrows={this.props.noArrows}
                            triggerWidgetAnimation={this.props.triggerWidgetAnimation}
                            addingOrRemovingWidgetFromFeatured={this.props.addingOrRemovingWidgetFromFeatured}
                        />
                    </div >
                )
            }
        }

    }
}

