import React, { Component } from 'react';
import { Settings } from "../components/Settings";
import { Elements } from 'react-stripe-elements';



class UserSettings extends Component {

  render() {

    return (
      <Elements>
        <Settings />
      </Elements>
    )
  }
}



export { UserSettings };