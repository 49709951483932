import axios from "axios";
import { push } from "react-router-redux";
import { apiBase } from "../../../lib/api";
import { dispatchHelper } from "../../../lib/actionHelpers";
import { authLoginAndRedirect } from "./";

import { CREATING_PASSWORD } from "./types";

export const createPassword = (params, callback) => async dispatch => {
  const dispatching = dispatchHelper(CREATING_PASSWORD);
  const url = `${apiBase}/auth/activate`;
  try {
    const response = await axios.post(url, params);
    dispatching(response, () => {
      dispatch(authLoginAndRedirect(response.data.data.token));
      dispatch(push("/locations"));
    });
  } catch(err) {
    const response = { status: 500, data: {error_message:"Error occured on password reset request"}};
    dispatching(response, callback);
  }
};
