import React, { Component, Fragment } from "react";
import { array, func } from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import {
  fetchLocation,
  fetchLocationReviews,
  fetchYelp,
} from "../redux/actions";

import { resendReview } from "../../reviews/redux/actions";
import ReviewBreakdown from "../components/ReviewBreakdown";
import CustomersTable from "../components/CustomersTable";
import YelpListing from "../components/YelpListing";
import { Sentiment } from "./";

import { startDateMMYYYY, endDateMMYYYY } from "../../../lib/dateHelpers";

import { ViewWithLoader, Button } from "../../../common";

class LocationsShow extends Component {
  static propTypes = {
    fetchLocation: func,
    locationsData: array,
  };

  state = {
    isFetching: true,
    isFetchingReviews: true,
    month: moment().format("MM"),
    year: moment().format("YYYY"),
  };

  mounted = true;

  componentDidMount() {
    this.props.fetchLocation(this.props.match.params.location_id, () => {
      this.mounted && this.setState({ isFetching: false });
    });
    this.fetchLocationReviews();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchLocationReviews = () => {
    this.props.fetchLocationReviews(
      this.props.match.params.location_id,
      {
        startDate: startDateMMYYYY(this.state.month, this.state.year),
        endDate: endDateMMYYYY(this.state.month, this.state.year),
      },
      () => {
        this.mounted && this.setState({ isFetchingReviews: false });
      }
    );
  };

  renderMonthsSelect = () => {
    return (
      <select
        className="form-control"
        defaultValue={this.state.month}
        onChange={(e) => this.setState({ month: e.target.value })}
      >
        {moment.months("MMMM").map((el, i) => {
          return (
            <option value={moment(el, "MMMM").format("MM")} key={i}>
              {el}
            </option>
          );
        })}
      </select>
    );
  };

  renderYearsSelect = () => {
    let years = [
      moment().subtract(3, "year").format("YYYY"),
      moment().subtract(2, "year").format("YYYY"),
      moment().subtract(1, "year").format("YYYY"),
      moment().format("YYYY"),
    ];
    return (
      <select
        className="form-control"
        defaultValue={this.state.year}
        onChange={(e) => this.setState({ year: e.target.value })}
      >
        {years.map((el, i) => {
          return (
            <option key={i} value={el}>
              {el}
            </option>
          );
        })}
      </select>
    );
  };

  handleDateChange = () => {
    this.setState({ isFetchingReviews: true });
    this.fetchLocationReviews();
  };

  handleFetchYelp = (func) => {
    this.props.fetchYelp(this.props.match.params.location_id, func);
  };

  handleResendClick = (e, cardData) => {
    this.props.resendReview(cardData.reviewId, () => {
      this.fetchLocationReviews();
    });
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 meta-nav">
            <div className="is-left">
              <div className="date-display">
                <span>Select Date:</span>
                {this.renderMonthsSelect()}
                {this.renderYearsSelect()}
                <button
                  className="btn"
                  onClick={(e) => this.handleDateChange()}
                >
                  Select
                </button>
              </div>
            </div>
            <div className="is-right">
              <Button
                link
                to={`/locations/${this.props.match.params.location_id}/request`}
              >
                Request Review
              </Button>
              <Button
                classes={"btn-secondary"}
                link
                to={`/locations/${this.props.match.params.location_id}/settings`}
              >
                <i className="fas fa-cog" /> Settings
              </Button>
            </div>
          </div>
        </div>
        <ViewWithLoader isLoading={this.state.isFetchingReviews}>
          <ReviewBreakdown
            locationReviews={this.props.locationReviews}
            currentMonth={this.state.month}
            location={this.props.locationData}
            reviews={this.props.locationReviews}
          />
          {/* <hr className="col-12" />
          <Sentiment locationReviews={this.props.locationReviews} />
          <hr className="col-12" />
          <div className="row">
            {
              this.props.locationData.yelp_integration !== null &&
              <div className="col-12 col-md-4">
                <YelpListing fetchYelp={this.handleFetchYelp} data={this.props.yelpData} />
              </div>
            }
          </div> */}
          <hr className="col-12" />
          <CustomersTable
            reviews={this.props.locationReviews}
            platforms={(this.props.locationData || {}).platforms || []}
            onResendClick={this.handleResendClick}
          />
        </ViewWithLoader>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  locations: {
    show: { data: locationData },
    reviews: { data: locationReviews },
    integrations: {
      yelp: { data: yelpData },
    },
  },
}) => ({
  locationData,
  locationReviews,
  yelpData,
});

const connected = connect(mapStateToProps, {
  fetchLocation,
  fetchLocationReviews,
  fetchYelp,
  resendReview,
})(LocationsShow);

export { connected as LocationsShow };
