import React from "react";
import { Button } from "../../../common";

function Confirmation(props) {
    return (
        props.address && props.address !== 'Please select an option from the dropdown' ?
            <div className="confirmation-container">
                <p className="reviews-font-headline mt-2">Is this the business you're looking for?</p>
                <p className="reviews-font-p bold-me mt-2">{props.address}</p>
                <Button classes={"btn mr-1"} onClick={() => { props.confirmAddress(props.platformId) }}>Yes</Button>
                <Button classes={"btn"} onClick={() => { props.resetState() }}>No</Button>
            </div>
            :
            null
    );
}

export { Confirmation };
