import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import * as actions from "../actions";
import { store } from "../../../store";
import { Button, Card, FormGroup } from "../../../common";
import { handleInputChange } from "../../../lib/utilities";

class UsersAuthenticate extends Component {
  state = { email: "", password: "", displayForgot: false };

  // Below code unnecessarily redirects to last visited, which switches the landing page from locations to last visited.
  // Don't believe this code does anything useful, and componentWillReceiveProps is deprecated. Commenting out for now.
  // componentWillMount() {
  //   this.checkIfAuthenticated();
  // }

  // componentWillReceiveProps() {
  //   this.checkIfAuthenticated();
  // }

  // checkIfAuthenticated() {
  //   if (this.props.user.authentication.data.token) {
  //     const location = this.props.lastVisited;
  //     store.dispatch(push(location));
  //   }
  // }

  handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && this.state.email && this.state.password) {
      this.handleSubmit(e);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.authenticateUser({ email, password });
  };

  handleResetToggle = () => {
    this.setState({
      displayForgot: !this.state.displayForgot
    });
  };

  handleResetPassword = () => {
    this.props.resetPassword({ email: this.state.email }, () => {
      this.handleResetToggle()
    })
  };

  render() {
    const { email, password } = this.state;
    const isDisabled = email === "" || password === "";
    return (
      <Card
        hideHeader={true}
        classes="col-sm-12 col-md-4 offset-md-4"
        errors={this.props.user.authentication.errors}
        padding={5}
      >
        {
          this.props.user.reset.success && <div className="alert alert-success col-12">Check your email for password instructions</div>
        }
        <div className="login-input-container">
          <FormGroup
            label="Email"
            type="email"
            classesInput={"input-gray"}
            value={email}
            onKeyPress={this.handleInputKeyPress}
            onChange={e => handleInputChange(this, e, "email")}
          />
          {
            !this.state.displayForgot &&
            <FormGroup
              label="Password"
              type="password"
              classesInput={"input-gray"}
              value={password}
              onKeyPress={this.handleInputKeyPress}
              onChange={e => handleInputChange(this, e, "password")}
            />
          }

          {
            !this.state.displayForgot ?
              <Fragment>
                <Button
                  onClick={this.handleSubmit}
                  disabled={isDisabled}
                  grey={isDisabled}
                  style={{ marginTop: "10px" }}
                >
                  Login
                </Button>
                <Button
                  onClick={this.handleResetToggle}
                  classes={"btn-basic forgot-password-mobile-responsive"}
                >
                  Forgot Password?
                </Button>
              </Fragment>
              :
              <Fragment>
                <Button
                  onClick={this.handleResetPassword}
                >
                  Reset Password
                </Button>
                <Button
                  onClick={this.handleResetToggle}
                  classes={"btn-basic"}
                >
                  Cancel
                </Button>
              </Fragment>
          }

        </div>
      </Card>
    );
  }
}

UsersAuthenticate.propTypes = {
  user: PropTypes.object,
  authenticateUser: PropTypes.func,
  lastVisited: PropTypes.string
};

const mapStateToProps = ({ user, lastVisited }) => ({ user, lastVisited });

const connected = connect(mapStateToProps, actions)(UsersAuthenticate);

export { connected as UsersAuthenticate };
