import React from "react";
import { bool, node } from "prop-types";
import { Loader } from "../common";
import { MiniLoader } from "../common/MiniLoader";

function withLoader(Component) {
  function Wrapper({ isLoading, children, ...props }) {
    props.classes === undefined && (props.classes = "");
    if (props.classes.includes('mini-loader')) {
      return (
        <Component {...props}>
          {isLoading && <MiniLoader />}
          {!isLoading && children}
        </Component>
      )
    }
    else {
      return (
        <Component {...props}>
          {isLoading && <Loader />}
          {!isLoading && children}
        </Component>
      );
    }
  }

  Wrapper.propTypes = {
    isLoading: bool,
    children: node
  };
  Wrapper.displayName = "withLoader()";

  return Wrapper;
}

export default withLoader;
