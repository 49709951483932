import React from "react";
import { func, object } from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { store } from "../store";


const ProtectedRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      store.getState().user.authentication.data.token ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

ProtectedRoute.propTypes = {
  component: func,
  location: object
};

export default ProtectedRoute;
