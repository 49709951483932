import { createReducer } from "../../../lib/utilities";

export const initialState = {
  index: {
    data: []
  },
  show: {
    data: {
      id: null,
      customer: {
        id: null,
        first_name: "",
        last_name: "",
        email: null,
        phone_number: "",
        review_id: null,
        location_id: null,
        created_at: "",
        updated_at: ""
      },
      location: {
        name: null
      },
      review: null,
      rating: null,
    }
  },
  create: {
    data: []
  }
};

export default createReducer(initialState, {
  CREATING_REVIEW_SUCCESS: (state, payload) => ({
    ...state,
    create: {
      ...state.create,
      data: payload.data
    }
  }),
  FETCHING_REVIEW_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload.data
    }
  }),
  FETCHING_REVIEW_LOCATION_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload.data
    }
  }),
  UPDATING_REVIEW_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload.data
    }
  }),
  UPDATING_ORGANIC_REVIEW_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      organicReviewSuccess: payload.data
    }
  }),
  RESENDING_REVIEW_SUCCESS: (state, payload) => {
    return { ...state };
  },
});
