import React, {Component} from 'react';
import {connect} from 'react-redux';

import axios from "axios";

import { rootUrl } from "../../../lib/api";
import { requestHeaders, getToken } from "../../../lib/authHelpers";
import {FormGroup, Button} from "../../../common";


class LocationsCreate extends Component {

  state = {
    message: "",
    location_name: "",
    creatingLocation: false,
    createdLocation: false,
    location_id: null,
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.location_name !== ""){

      const token = getToken();
      const url = `${rootUrl}/locations`;
      const headers = requestHeaders(token);

      this.setState({
        creatingLocation: true,
      });

      try {
        const response = await axios.post(url, {
          location_name: this.state.location_name
        }, headers);
        this.setState({
          createdLocation: true,
          creatingLocation: false,
          location_id: response.data.data.id
        });

        localStorage.setItem("token", response.data.data.token);

        //redirect to new location
        window.location.replace('/locations/'+response.data.data.location.id+"/settings");

      } catch (err) {
        this.setState({
          message: err.response.data.error_message,
          creatingLocation: false,
        });
      }

    } else {
      this.setState({
        message: "Location name cannot be blank"
      })
    }
  };

  handleInputChange = (e, type) => {
    this.setState({[type]: e.target.value});
  };

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="form-page">
            <h3>Create Location</h3>

            { this.state.message !== "" && <div className="alert alert-warning">{this.state.message}</div> }

            <FormGroup
              classes=""
              type="text"
              label="Business Name"
              value={this.state.location_name}
              onChange={e => this.handleInputChange(e, "location_name")}
            />

            <Button disabled={this.state.creatingLocation} onClick={e => this.onSubmit(e)}>
              {
                (this.state.creatingLocation ? "Creating..." : "Create Location")
              }
            </Button>

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  user: {authentication: {data: authData}},
}) => ({
  authData,
});

const connected = connect(mapStateToProps)(LocationsCreate);

export {connected as LocationsCreate};