import rating1 from "../images/rating-1.svg";
import rating2 from "../images/rating-2.svg";
import rating3 from "../images/rating-3.svg";
import rating4 from "../images/rating-4.svg";
import rating5 from "../images/rating-5.svg";
import ratingStar from "../images/icon-star.svg";

const RatingsEmoji = {
  1: ratingStar,
  2: ratingStar,
  3: ratingStar,
  4: ratingStar,
  5: ratingStar
};

export {RatingsEmoji};