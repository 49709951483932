const stableVersion = "v1";
export const apiBase = (function () {
  const { origin } = window.location;
  let url = "https://api.reviewloop.app";
  url = origin.includes("localhost")
    ? "http://localhost:1220"
      : origin.includes("netlify.app")
        ? 'https://reviewsloop-staging.herokuapp.com'
        : url;
  return url;
})();

export const rootUrl = `${apiBase}/${stableVersion}`;

export const LAMBDA_MC_SUBSCRIBE = "https://h46rtfqzn3.te-api.us-west-2.amazonaws.com/prod";