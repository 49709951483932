import React, { Component, Fragment } from 'react';
import Tab0 from "../../../images/home/tab-0.png";
import Tab1 from "../../../images/home/tab-1.png";
import Tab2 from "../../../images/home/tab-2.png";
import Tab3 from "../../../images/home/tab-3.png";

export default class Tabs extends Component {
  state = {
    activeTab: 0
  };

  handleTabChange = (index) => {
    this.setState({
      activeTab: index
    })
  };

  render() {

    let tabs = [
      {
        label: "Monitor multiple business profiles",
        img: Tab0,
        miscLabel: ""
      },
      {
        label: "Unlimited review requests",
        img: Tab3,
        miscLabel: ""
      },
      {
        label: "Feature reviews on your website",
        img: Tab2,
        miscLabel: "New Feature!"
      },
      {
        label: "Google My Business integration",
        img: Tab1,
        miscLabel: "Coming Soon"
      },
    ];

    return (
      <Fragment>
        <div className="col-12 col-md-6 home-tabs-list">
          <ul>
            {
              tabs.map((el, i) => {
                return (
                  <li className={(this.state.activeTab === i ? "active" : "")} onClick={() => this.handleTabChange(i)} key={i}>
                    {el.label}
                    {
                      (el.miscLabel !== "" && <span>{el.miscLabel}</span>)
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="col-12 col-md-6 home-tabs-image align-self-center">
          <img alt="img" src={tabs[this.state.activeTab].img} />
        </div>
      </Fragment>
    )
  }
}