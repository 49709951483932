import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { handleInputChange } from "../../../lib/utilities";
import { FormGroup, Button } from "../../../common";
// import Recaptcha from 'react-recaptcha'



export default class RegistrationUserInfo extends Component {

    state={
        passwordMatchError : false,
    }

    passwordsMatch = () => {
        let { password, passwordConfirm } = this.props;
        let theyMatch = false;
        if(password === passwordConfirm){
            theyMatch = true;
        }
        return theyMatch;
    }

    handleSubmit = () => {
        let passwordsMatch  = this.passwordsMatch();
        if(passwordsMatch){
            this.props.nextStep("forward");
        } else {
            this.setState({
                passwordMatchError: true
            })
        }
    }

    render() {
        let { firstName, lastName, email, password, passwordConfirm, emailExists } = this.props;
        let isDisabled = firstName === "" || lastName === "" || email === "" || password === "" || passwordConfirm === "" || emailExists === true;
        return (
            this.props.formStep === 0 ?
                <div className="user-info-container">
                    <div className="info-group">
                        <FormGroup
                            label="First Name"
                            type="text"
                            classes={"registration_input"}
                            classesInput={"input-gray"}
                            value={this.props.firstName}
                            onChange={e => handleInputChange(this.props.thisReference, e, "first_name")}
                        />
                        <FormGroup
                            label="Last Name"
                            type="text"
                            classes={"registration_input"}
                            classesInput={"input-gray"}
                            value={this.props.lastName}
                            onChange={e => handleInputChange(this.props.thisReference, e, "last_name")}
                        />
                    </div>
                    <FormGroup
                        label="Email"
                        type="email"
                        value={this.props.email}
                        classesInput={"input-gray"}
                        onChange={e => this.props.liftEmail(e)}
                    />
                    {this.props.emailExists === true &&
                        <div data-testid="alert" className="alert alert-warning w-100">
                            An account with this email already exists.
                        </div> 
                    }
                    <FormGroup
                        label="Password"
                        type="password"
                        value={this.props.password}
                        classesInput={"input-gray"}
                        onChange={e => {handleInputChange(this.props.thisReference, e, "password"); this.setState({passwordMatchError: false})}}
                    />
                    <FormGroup
                        label="Confirm Password"
                        type="password"
                        value={this.props.passwordConfirm}
                        classesInput={"input-gray"}
                        onChange={e => {handleInputChange(this.props.thisReference, e, "password_confirm"); this.setState({passwordMatchError: false})}}
                    />
                    {
                        this.state.passwordMatchError && 
                         <div data-testid="alert" className="alert alert-danger w-100">
                            Passwords do not match
                         </div>
                    }
                    <Button disabled={isDisabled} classes={"btn-purple"} onClick={() => this.handleSubmit()}>Next</Button>
                </div>
                : null

        )
    }
}