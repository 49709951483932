import React, { Component } from 'react'
import { handleInputChange } from "../../../lib/utilities";
import { FormGroup, Button, ReuseableDropdown } from "../../../common";
import { states } from "../../../lib/stateFixture";


import { CardElement } from 'react-stripe-elements';
import PaymentErr from "./PaymentErr";
// import PropTypes from "prop-types";

const style = {
    base: {
        color: '#32325d',
        lineHeight: '20px',
        padding: "10px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        '::placeholder': {
            color: '#aab7c4'
        }
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
    }
};

export default class CardInfo extends Component {

    state = {
        error: "",
        disabled: true,
        paymentErrorMessage: this.props.paymentErrorMessage,
    }


    handleDropdownChange = (t, key, value) => {
        t.setState({ [key]: value})
    };

    handleChange = (change) => {
        change.error ? this.setState({ error: change.error.message }) : this.setState({ error: "", disabled: true, paymentErrorMessage: "" });
        change.complete === true && this.setState({ disabled: false })
        this.props.clearSourceError();
    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.paymentErrorMessage !== this.props.paymentErrorMessage && this.setState({ paymentErrorMessage: this.props.paymentErrorMessage })
        if(this.state.error !== prevState.error){
            this.props.clearSourceError();
        }
    }

    render() {
        let { nameOnCard, addressLine1, addressCity, addressState } = this.props;
        let isDisabled = nameOnCard === "" || addressLine1 === "" || addressCity === "" || addressState === "" || this.state.disabled === true;
        return (
            this.props.formStep === 1 ?
                <div className="user-info-container">

                  <div className="alert alert-info">
                    Don't worry! You won't be charged until your trial ends.
                  </div>

                    <FormGroup
                        label="Name On Card"
                        type="text"
                        classesInput={"input-gray"}
                        value={this.props.nameOnCard}
                        onChange={e => handleInputChange(this.props.thisReference, e, "nameOnCard")}
                    />
                    <FormGroup
                        label="Billing Address"
                        type="text"
                        classesInput={"input-gray"}
                        value={this.props.addressLine1}
                        onChange={e => handleInputChange(this.props.thisReference, e, "address_line1")}
                    />
                    <div className="info-group">
                        <FormGroup
                            label="City"
                            type="text"
                            classes={"input-location-style"}
                            classesInput={`input-gray input-location-style ${this.props.view === "signup" ? 'city-width' : 'update-width'}`}
                            value={this.props.addressCity}
                            onChange={e => handleInputChange(this.props.thisReference, e, "address_city")}
                        />

                        <ReuseableDropdown 
                            defaultValue={'Select'}
                            customContainerClass={'state-selection-container'}
                            customInputContainerClass={`state-selection ${this.props.view === "signup" ? "grey-bg" : "white-bg"  }`}
                            customInputClass={"white-bg"}
                            data= {states}
                            label={'State'}
                            accessor={'abbreviation'}
                            secondaryAccessor={'name'}
                            onSelect={(val) => this.handleDropdownChange(this.props.thisReference,  "address_state", val)}
                        /> 

                        {/* <FormGroup
                            label="State"
                            type="text"
                            classes={"input-location-style "}
                            classesInput={"input-gray input-location-style"}
                            value={this.props.addressState}
                            onChange={e => handleInputChange(this.props.thisReference, e, "address_state")}
                        /> */}
                    </div>
                    <div id="card-element">
                        <CardElement hidePostalCode={false} onChange={this.handleChange} style={style} />
                    </div>
                    <PaymentErr paymentErrorMessage={this.state.paymentErrorMessage} inputError={this.state.error} />
                    <PaymentErr paymentErrorMessage={this.props.stripeSrcErrorMessage} inputError={this.props.stripeSrcError} />
                    {
                        this.props.updateInfo === false && <Button disabled={null} classes={"btn-purple"} onClick={() => this.props.nextStep("back")}>Back</Button>
                    }
                    <Button disabled={isDisabled} classes={"btn-purple ml-2"} onClick={() => this.props.submit()}>Finish</Button>

                </div> : null
        )
    }
}

CardInfo.defaultProps = {
    view: 'default'
}
// CardInfo.propTypes = {
//     isLoading: PropTypes.bool,
//     formStep: PropTypes.int,
//     thisReference: PropTypes.obj,
//     addressState: PropTypes.string,
//     addressCity: PropTypes.string,
//     zipcode: PropTypes.string,
//     addressLine1: PropTypes.string,
//     submit: PropTypes.func,
//     nameOnCard: PropTypes.string,
//     paymentErrorMessage: PropTypes.string,
//     inputError: PropTypes.string,
// };
