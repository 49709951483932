import React from "react";
import { string } from "prop-types";
import { Button } from "./";
import { trimName } from "../lib/textHelpers";
LocationLink.propTypes = {
  linkTo: string.isRequired,
  name: string.isRequired
};




function LocationLink(props) {
  return (
    <div className="col-sm-12 col-md-4">
      <Button classes="location-link" link to={props.linkTo}>
        <h2 className="name">{trimName(props.name)}</h2>
        <span className={"rating" + (props.location.ratingAverage <= 3 ? " warning" : "")}>
          <i className="fa fa-star"></i>
          {props.location.ratingAverage}
        </span>
      </Button>
    </div>
  );
}

export { LocationLink };
