import React, { Component } from 'react'
import moment from "moment";
import PropTypes from 'prop-types'
import { Button } from "../../../common";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const initialStartDate = moment().subtract('1', 'months').startOf('month').toDate();
const initialEndtDate = moment().subtract('1', 'months').endOf('month').toDate();
export default class DatePicker extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    state = {
        focusedInput: null,
        from: undefined,
        to: undefined,
        visible: false,

    }

    getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        if (this.state.to !== undefined || this.state.from !== undefined) {
            this.setState({ visible: false });
        }
        this.setState({ to: range.to, from: range.from }, () => {
            this.state.to && this.state.from !== undefined && this.props.liftAndSetCalendarState({ from: this.state.from, to: this.state.to })
        });
    }

    handleResetClick = () => {
        this.setState(this.getInitialState());
    }

    showHideCalendar = () => {
        this.setState({ visible: !this.state.visible })
    }

    componentDidMount() {
        this.setState({
            from: initialStartDate,
            to: initialEndtDate,
        })
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <div className="calendar-container">
                <span onClick={() => { this.showHideCalendar(); this.handleResetClick() }} className="calendar-dates">
                    {from && to && `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`}
                    {(from === undefined || to === undefined) && <span>Select A Date</span>}
                </span>
                {
                    this.state.visible && <DayPicker
                        className="Selectable"
                        numberOfMonths={this.props.numberOfMonths}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                    />
                }
            </div >
        )
    }
}
