import _ from "lodash";
import React, { Component } from "react";
import { string, arrayOf, func } from "prop-types";
import ReactTooltip from "react-tooltip";

class Table extends Component {
  static propTypes = {
    headers: arrayOf(string).isRequired,
    fetchMetaData: func
  };

  static defaultProps = {
    fetchMetaData: () => {}
  };

  state = { enableTooltip: false };

  handleMouseEnter = subitem => {
    if (!subitem.meta) return;
    this.setState({ enableTooltip: true });
  };

  handleMouseLeave = subitem => {
    if (!subitem.meta) return;
    this.setState({ enableTooltip: false });
  };

  titleCase = str => {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  };

  parseFBConversionLabel = str => {
    return str.replace(".", " ").replace("_", " ");
  };


  renderMeta = (subitem, key, index) => {
    if(subitem.meta){
      if(key === "lead type"){
        return (
          <span
            className={`is-lead-${subitem.meta &&
            subitem.meta.is_lead}`}
          />
        )
      } else if(this.state.enableTooltip){
        return (
          <ReactTooltip
            id={"meta-"+index}
            place="right"
            type="dark"
            effect="float"
          >
            {subitem.meta.map((item, index) =>
              <div key={index}><strong>{this.titleCase(this.parseFBConversionLabel(item.type))}:</strong> {item.value}</div>
            )}
          </ReactTooltip>
        )
      }
    } else {
      return null
    }
  };

  render() {
    const { headers, data, fetchMetaData } = this.props;

    return (
      <table className="responsive-table">
        <thead className="responsive-thead">
          <tr className="responsive-tr">
            {headers.map(header => {
              return (
                <th scope="col" className="responsive-th" key={header}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody className="responsive-tbody">
          {data.map((row, index) => {
            if (!Object.keys(row)[0].includes("__")) {
              return (
                <tr
                  className="responsive-tr"
                  key={index}
                  onClick={fetchMetaData.bind(this, {
                    id: row.id
                  })}
                >
                  {_.map(row, (subitem, key, list) => {
                    if (key === "id") return;
                    return (
                      <td
                        data-label={key}
                        key={key}
                        className="responsive-td"
                        onMouseEnter={() => this.handleMouseEnter(subitem)}
                        onMouseLeave={() => this.handleMouseLeave(subitem)}
                        data-tip
                        data-for={"meta-"+index}
                      >
                        {this.renderMeta(subitem, key, index)}
                        {subitem.value}
                      </td>
                    );
                  })}
                </tr>
              );
            }
            return (
              <tr className="responsive-tr--subitem" key={index}>
                {_.map(row, (subitem, key) => {
                  return (
                    <td
                      data-label={key.slice(2)}
                      key={key}
                      className="responsive-td"
                    >
                      {subitem.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export { Table };
