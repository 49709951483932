import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import moment, { invalid } from "moment";
import CSVData from './CSVData';
import { Button } from "../../../common";
import AddReview from './AddReview';
import CSVSection from './CSVSection';
import CustomMessage from './CustomMessage';
import IncorrectData from './IncorrectData';
import { validateInput } from "../../../lib/customerInputValidation";
class RequestReview extends Component {

  state = {
    //load prop data
    hasError: false,
    customMessage: '',
    incorrectDataFormatError: false,
    location_id: null,
    reviewsSent: false,
    reviewsSending: false,
    fileData: [],
    platforms: [],
    customerInfo: [{
      first_name: '',
      last_name: '',
      phone_number: '',
      valid: false,
      editing: false,
      id: 0,
      platforms: []
    }],
    offset: 0,
    paginationPage: 1,
    paginationPageFileData: 1,
  }

  resetState = () => {
    this.setState({
      hasError: false,
      incorrectDataFormatError: false,
      location_id: this.props.locationData.id,
      fileData: [],
      reviewsSent: false,
      reviewsSending: false,
      platforms: [],
      customerInfo: [{
        first_name: '',
        last_name: '',
        phone_number: '',
        valid: false,
        editing: false,
        id: 0,
        platforms: this.getPlatforms()
      }],
      offset: 0,
      paginationPage: 1,
      paginationPageFileData: 1,
    })
    this.setPlatformState();
  };

  componentDidMount() {
    let locationData = this.props.locationData.settings.default_message;
    this.setState({
      customMessage: locationData,
      location_id: this.props.locationData.id
    });

    this.setPlatformState();
  }

  componentDidUpdate = () => {
    if (((this.props.locationData || {}).platforms || []).length !== this.state.platforms.length) {
    }
  }

  componentWillUnmount() {
    clearTimeout(this.successTimer); //We should always destroy these kind of handlers
  }

  displayCSVError = () => {
    this.setState({ incorrectDataFormatError: !this.state.incorrectDataFormatError })
  }

  getPlatforms = () => {
    const platforms = (this.props.locationData || {}).platforms || [];
    return platforms.filter(n => (n.pivot || {}).active).map(o => ({
      id: o.id,
      name: o.name,
      icon_url: o.icon_url,
      enabled: true
    }));
  }

  setPlatformState = () => {
    const platforms = this.getPlatforms();
    let newInfo = (this.state.customerInfo || []).map(n => {
      return {
        ...n,
        platforms: platforms
      }
    });
    this.setState({
      customerInfo: newInfo,
      platforms: platforms
    });
  }

  liftState = (data) => {
    let tempValid = [];
    let tempInvalid = [];
    data.forEach(customer => {
      if (customer.valid === true) {
        tempValid.push(customer);
      } else {
        tempInvalid.push(customer);
      }
    })
    tempInvalid.forEach((customer, i) => {
      customer.id = i;
    })
    tempValid.forEach((customer, i) => {
      customer.id = i;
    })
    tempValid = tempValid.map(n => ({
      ...n,
      platforms: this.getPlatforms()
    }));
    this.setState({ fileData: tempInvalid, customerInfo: tempValid })
  }


  handleCreateReview = () => {
    let result = this.state.customerInfo.reduce((unique, o) => {
      // Check for duplicate phone numbers
      if (!unique.some(obj => obj.phone_number === o.phone_number)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      customerInfo: result,
      reviewsSending: true
    }, () => {
      this.props.createReview(
        {
          customerInfo: this.state.customerInfo.map(n => ({
            ...n,
            platform_ids: (n.platforms || []).filter(o => o.enabled).map(o => o.id)
          })),
          customMessage: this.state.customMessage,
          location_id: this.props.locationData.id
        }, () => {
          this.setState({
            reviewsSent: true,
            reviewsSending: false,
            customerInfo: [{
              first_name: '',
              last_name: '',
              phone_number: '',
              valid: false,
              editing: false,
              id: 0,
              platforms: []
            }],
          });
          this.handleSuccessTimer();
        }
      );
    });

  };

  handleSuccessTimer = () => {
    this.successTimer = setTimeout(()=>{
      this.resetState();
    }, 4000);
  };

  getSubscriptionStatus = () => {
    let subscriptionEnd = this.props.currentPeriodEnd;
    let now = moment().unix();
    if (now < subscriptionEnd) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  addIndividualReview = () => {
    let lastPage = Math.ceil(this.state.customerInfo.length / 10);
    if (this.state.paginationPage !== lastPage) {
      this.setState({ paginationPage: lastPage })
    }

    let obj = {
      first_name: '',
      last_name: '',
      phone_number: '',
      valid: false,
      editing: false,
      id: [this.state.customerInfo.length - 1],
      platforms: this.getPlatforms()
    }
    let originalData = this.state.customerInfo;
    let newArray = [];
    newArray = originalData.slice();
    newArray.push(obj);
    newArray.forEach((item, i) => item.id = i)
    if (this.state.customerInfo.length % 10 === 0) {
      this.setState({ customerInfo: newArray, paginationPage: this.state.paginationPage + 1 });
    } else {
      this.setState({ customerInfo: newArray });
    }
  }

  saveReview = (editedReview, type) => {
    this.removeReview(editedReview.id, type);
    let originalData = this.state.customerInfo;
    editedReview.valid = true;
    let newArray = [];
    newArray = originalData.slice();
    // Validate the edited review
    let phone = editedReview.phone_number.replace(/[^0-9]/g, '');
    let firstname = editedReview.first_name;
    let lastName = editedReview.last_name;
    firstname.length === 0 && (editedReview.valid = false);
    lastName.length === 0 && (editedReview.valid = false);
    phone.length !== 10 && (editedReview.valid = false);
    let isNum = /^\d+$/.test(phone);
    isNum === false && (editedReview.valid = false);
    newArray.unshift(editedReview);
    newArray.forEach((customer, i) => {
      customer.id = i;
    });
    let editedArray = newArray.map(item => {
      if (item.id === editedReview.id) {
        item = editedReview
      }
      return item;
    });
    this.setState({ customerInfo: editedArray });
  }

  handleMessageChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  handleGlobalPlatformChange = (e, platform) => {
    const newPlatforms = this.state.platforms.map(n => ({
      ...n,
      enabled: (n.id !== platform.id)
        ? n.enabled
        : !n.enabled
    }));
    const newCustomerInfo = (this.state.customerInfo || []).map(n => ({
      ...n,
      platforms: (n.platforms || []).map(o => ({
        ...o,
        enabled: (o.id !== platform.id)
          ? o.enabled
          : !platform.enabled
      }))
    }))
    this.setState({
      platforms: newPlatforms,
      customerInfo: newCustomerInfo
    });
  }

  handlePlatformChange = (e, platform, id, type) => {
    let newCustomerInfo = (this.state.customerInfo || []).map(n => {
      if (n.id === id) {
        return {
          ...n,
          platforms: (n.platforms || []).map(o => {
            if (o.id === platform.id) {
              return {
                ...o,
                enabled: !o.enabled
              }
            }
            return o;
          })
        }
      }
      return n;
    });

    this.setState({ customerInfo: newCustomerInfo });
  }

  handleInputChange = (e, type, id, datatype) => {
    let originalData;
    datatype === 'fileData' && (originalData = this.state.fileData);
    datatype === 'customerInfo' && (originalData = this.state.customerInfo);
    let newArray = [];
    newArray = originalData.slice();
    let customer = originalData.find(customer => customer.id === id);
    customer[type] = e.target.value
    newArray[id] = customer;
    newArray = newArray.map(n => {
      if (n) {
        n.valid = !validateInput([n]);
      }
      return n;
    });
    this.setState({ [datatype]: newArray });
  };

  removeReview = (id, type) => {
    let originalData = [];
    let newArray = [];
    if (type === 'fileData') {
      originalData = this.state.fileData;
    } else {
      originalData = this.state.customerInfo;
    }
    newArray = originalData.slice();
    let findReview = newArray.filter(item => item.id !== id);
    findReview.forEach((customer, i) => {
      customer.id = i;
    });
    type === 'fileData' ? this.setState({ fileData: findReview }) : this.setState({ customerInfo: findReview });
  }

  handleNextNotePage = (type) => {
    if (type === 'fileData') {
      this.setState({ paginationPageFileData: this.state.paginationPageFileData + 1 });
    } else {
      this.setState({ paginationPage: this.state.paginationPage + 1 });

    }
  };
  handlePrevNotePage = (type) => {
    if (type === 'fileData') {
      this.setState({ paginationPageFileData: this.state.paginationPageFileData - 1 });
    } else {
      this.setState({ paginationPage: this.state.paginationPage - 1 });
    }
  };
  render() {

    // Assign ID to customerInfo
    let validInfoCount = 0;
    let invalidInfoCount = 0;
    let buttonDisabled = validateInput(this.state.customerInfo);
    let buttonDisabledText = "Send Requests";
    if(this.state.reviewsSending === true){
      buttonDisabledText = "Sending Requests...";
      buttonDisabled = true;
    }

    this.state.customerInfo.forEach(customer => customer.valid === true && (validInfoCount += 1));
    this.state.fileData.forEach(customer => customer.valid !== true && (invalidInfoCount += 1));

    const subscriptionStatus = this.getSubscriptionStatus();
    if (this.state.incorrectDataFormatError) {
      return (
        <IncorrectData resetState={this.resetState} incorrectDataFormatError={this.state.incorrectDataFormatError} />
      )
    } else {
      return (
        <div className="col-12 col-md-12 col-sm-12">
          {
            subscriptionStatus === "Inactive" ?
              (
                <div className="col-12 col-md-8 offset-md-2">
                  <div className="row">
                    <div className="subscription-expired-container col-12">
                      <p>Your subscription has expired. Please visit your account settings to renew your subscription.</p>
                      <NavLink className={"back-to-location"} to={`/account/billing`}>&laquo; My Account </NavLink>
                    </div>
                  </div>
                </div>
              ) :
              (
                <div>
                  <h1 className="mbn-25">Request Reviews</h1>
                  <div className="success-count"><span><i className="fas fa-check-circle green"></i>{validInfoCount} Total Requests</span></div>
                  {
                    this.state.reviewsSent === true &&
                    <div className="alert alert-success col-12">
                      <span>{this.state.customerInfo.length} Review(s) Sent!</span>
                    </div>
                  }
                  <AddReview
                    addIndividualReview={this.addIndividualReview}
                    incorrectDataFormatError={this.state.incorrectDataFormatError}
                    handleNextNotePage={this.handleNextNotePage}
                    handlePrevNotePage={this.handlePrevNotePage}
                    customerInfo={this.state.customerInfo}
                    handleInputChange={this.handleInputChange}
                    onPlatformChange={this.handlePlatformChange}
                    onGlobalPlatformChange={this.handleGlobalPlatformChange}
                    removeReview={this.removeReview}
                    page={this.state.paginationPage}
                    platforms={this.state.platforms}
                    liftState={this.liftState}
                    type={"customerInfo"}
                  />
                  <CustomMessage placeholder={this.props.placeholder} customMessage={this.state.customMessage} handleMessageChange={this.handleMessageChange} />
                  <Button classes={"mt-2 mb-2"} disabled={buttonDisabled} disabledText={buttonDisabledText} onClick={this.handleCreateReview}>Send Requests</Button>
                  <hr/>
                  {this.state.fileData.length >= 1 && <div className="success-count"><span><i className="fas fa-exclamation-triangle red"></i>{invalidInfoCount} requests with errors</span></div>}
                  <CSVSection
                    liftState={this.liftState}
                    customerInfo={this.state.fileData}
                    handleInputChange={this.handleInputChange}
                    page={this.state.paginationPageFileData}
                    type={"fileData"}
                    removeReview={this.removeReview}
                    handleNextNotePage={this.handleNextNotePage}
                    onPlatformChange={this.handlePlatformChange}
                    handlePrevNotePage={this.handlePrevNotePage}
                    saveReview={this.saveReview}
                    displayCSVError={this.displayCSVError}
                  />
                  <CSVData fileData={this.state.fileData} />
                </div>
              )
          }
        </div>
      )
    }
  }
}

export default RequestReview;