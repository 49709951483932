import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  PieChart,
  Pie,
  Label,
  Cell,
  Legend,
  ResponsiveContainer
} from "recharts";


const COLORS = ['#003287', '#13E4C0', '#0098BD'];

export default class SentimentPieGraph extends Component {
  static propTypes = {
    totalSentiments: PropTypes.array.isRequired,
  }

  state = {
    src: "",
    // pieData:[]
  }
  // All commented code can be deleted. Kept for personal future reference.
  // Anger 1
  // Joy 2
  // Sadness 5
  // initializePieGraphData = () => {
  //   let pieData = [{ name: "joy", value: 0, }, { name: "sadness", value: 0, }, { name: "anger", value: 0, }];
  //   this.props.totalSentiments.forEach(item => {
  //     item.sentiment.key === "joy" && (pieData[0].value += 1);
  //     item.sentiment.key === "sadness" && (pieData[1].value += 1);
  //     item.sentiment.key === "anger" && (pieData[2].value += 1);

  //   });
  //   this.setState({ pieData });
  // }

  // componentDidUpdate(prevProps) {
  //   this.props.totalSentiments.length !== prevProps.totalSentiments.length && this.initializePieGraphData();
  // }

  render() {
    let pieData = [{ name: "joy", value: 0, }, { name: "sadness", value: 0, }, { name: "anger", value: 0, }];
    this.props.totalSentiments.forEach(item => {
      item.sentiment.key === "joy" && (pieData[0].value += 1);
      item.sentiment.key === "sadness" && (pieData[1].value += 1);
      item.sentiment.key === "anger" && (pieData[2].value += 1);
    });
    if (pieData[0].value === 0 && pieData[1].value === 0 && pieData[2].value === 0) {
      return null;
    } else {
      return (
        <div className="col-12 sentiment-container">
          <h3>Sentiment Analysis</h3>
          <ResponsiveContainer width={200} height={200}>
            <PieChart >
              <Pie
                data={pieData}
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {
                  pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Legend />
              <Label value="Pages of my website" offset={0} position="insideBottom" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      )
    }

  }
}
