export const FETCHING_LOCATIONS = "FETCHING_LOCATIONS";
export const FETCHING_LOCATION = "FETCHING_LOCATION";
export const FETCHING_LOCATION_REVIEWS = "FETCHING_LOCATION_REVIEWS";
export const FETCHING_PLATFORMS = "FETCHING_PLATFORMS";
export const UPDATING_LOCATION_PLATFORMS = "UPDATING_LOCATION_PLATFORMS";
export const UPDATING_LOCATION_SETTINGS = "UPDATING_LOCATION_SETTINGS";
export const DELETING_LOCATION_PLATFORM = "DELETING_LOCATION_PLATFORM";
export const SEARCHING_YELP = "SEARCHING_YELP";
export const FETCHING_YELP = "FETCHING_YELP";
export const CREATING_LOCATION_TOKEN = "CREATING_LOCATION_TOKEN";
export const DELETING_LOCATION_TOKEN = "DELETING_LOCATION_TOKEN";
export const FEATURE_REVIEW = "FEATURE_REVIEW";
export const FETCH_FEATURED_REVIEWS = "FETCH_FEATURED_REVIEWS";
export const CHANGE_FEATURED_REVIEWS_POSITION = "CHANGE_FEATURED_REVIEWS_POSITION";
export const AUTO_FEATURE_FIVE_STAR_REVIEWS = "AUTO_FEATURE_FIVE_STAR_REVIEWS";

