import React, { Component, Fragment } from "react";

export class TextCount extends Component {
  textCount = () => {
    let length = 0;
    if (this.props.message) {
      length = this.props.message.length;
    }
    return (
      <div className="text-count">
        We recommend staying below 137 characters.{" "}
        <span className={length > 137 ? "error-red" : "undefined"}>
          {length}/137
        </span>
      </div>
    );
  };
  render() {
    return <Fragment>{this.props.showTextCount && this.textCount()}</Fragment>;
  }
}

TextCount.defaultProps = {
  showTextCount: true,
  message: ""
};
