import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RatingsEmoji } from "../../../common";

export default class ReviewEmojis extends Component {
    static propTypes = {
        customer: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    }

    static defaultProps = {
      customer: {},
    }

    handleRatingSelection = (e, rating) => {
        e.preventDefault();
        this.props.handleDisplayAndFetchingState({ isFetching: true });
        this.props.updateReview(this.props.reviewHash, { rating }, () => {
            this.props.handleDisplayAndFetchingState({ isFetching: false, display: "Form" });
        });
    }
    render() {
        const customer = this.props.customer;
        const location = customer.location || {};
        const company = (this.props.location || {}).name;
        return (
            <div className="rating-container">
                <h2>Hello, {customer.first_name}!</h2>
                <p>{company} would love to get your feedback!</p>
                <ul className="ratings-list">
                    {Object.keys(RatingsEmoji).map((el, i) => {
                        return (
                            <li key={i}>
                                <a onClick={(e) => this.handleRatingSelection(e, i + 1)} href="#">
                                    <img alt="ratings_emoji" src={RatingsEmoji[i + 1]} />
                                    <span>{i + 1}</span>
                                </a>
                            </li>
                        )
                    })}
                </ul>
              <p className="disclaimer">(Rate your overall experience)</p>
            </div>
        )
    }
}
