import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Button } from "../../../common";
import CustomerInput from "./CustomerInput";
export default class AddReview extends Component {
    static propTypes = {
        addIndividualReview: PropTypes.func,
        incorrectDataFormatError: PropTypes.bool,
        customerInfo: PropTypes.array,
    }


    state = {
        highlightId: null,
    }

    highlight = (e, id) => {
        this.setState({ highlightId: id })
    }

    renderInputs = () => {
        let originalData = this.props.customerInfo;
        let newArray = [];
        let endIndex = 10 * this.props.page;
        let startIndex = endIndex - 10;
        newArray = originalData.slice(startIndex, endIndex);
        return newArray.map(item => {
            let highlight = (this.state.highlightId === item.id);
             return (
                <CustomerInput
                    firstName={item.first_name}
                    lastName={item.last_name}
                    id={item.id}
                    editing={item.editing}
                    phoneNumber={item.phone_number}
                    highLight={highlight}
                    highlightFunc={this.highlight}
                    onPlatformChange={this.props.onPlatformChange}
                    handleInputChange={this.props.handleInputChange}
                    type={this.props.type}
                    removeReview={this.props.removeReview}
                    saveReview={this.props.saveReview}
                    customerInfo={this.props.customerInfo}
                    addIndividualReview={this.props.addIndividualReview}
                    platforms={item.platforms}
                    key={item.id}
                />
            )
        });
    }

    render() {
        let buttonDisabledLeft = false;
        let buttonDisabledRight = false;
        let pageLimit = Math.ceil(this.props.customerInfo.length / 10);
        if (this.props.page === 1) {
            buttonDisabledLeft = true;
        }
        if (this.props.page === pageLimit) {
            buttonDisabledRight = true;
        }
        return (
            <div className="add-review-container">
                <div className="input-headers">
                    <div className="input-type-customer">First Name</div>
                    <div className="input-type-customer">Last Name</div>
                    <div className="input-type-customer">Phone Number</div>
                    <div className={'social-buttons'}>
                        {this.props.platforms.map((n, idx) => (
                          <img key={idx} className={`social-button ${n.enabled ? 'enabled' : ''}`} src={n.icon_url || ''} onClick={e => this.props.onGlobalPlatformChange(e, n)} />
                        ))}
                    </div>
                    <div className="last"></div>
                </div>
                {this.renderInputs()}
                {
                    this.props.customerInfo.length >= 5 && (
                        <div className="reviews-pagination-buttons-request">
                            <span>Page {this.props.page}</span>
                            < Button classes={"mr-2"} key="chevron-left" disabled={buttonDisabledLeft} onClick={() => this.props.handlePrevNotePage(this.props.type)} inverse>
                                <i className="fas fa-chevron-left" />
                            </Button>
                            <Button key="chevron-right" disabled={buttonDisabledRight} onClick={() => this.props.handleNextNotePage(this.props.type)} inverse>
                                <i className="fas fa-chevron-right" />
                            </Button>
                        </div>
                    )
                }
            </div>
        )
    }
}


