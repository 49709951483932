import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import Hero from "./components/hero";
import Tabs from "./components/tabs";
import Pricing from "./components/Pricing";
import IconStar from "../../images/home/icon-star.svg";
import IconAnalyze from "../../images/home/icon-analyze.svg";
import IconImprove from "../../images/home/icon-improve.svg";
import FeatDoctor from "../../images/home/feat-doctor.png";
import SocialIcons from "../../images/home/social-icons.png";
import Parallax from "../../common/Parallax";

export default class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <Hero />
        <div className="container-fluid is-white">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <img className="social-icons" src={SocialIcons} alt="" />
              <h2>The easiest way to get quality customer feedback</h2>
              <p>
                We enable businesses to measure and analyze their customer
                experience. Using our platform, businesses can gather reviews
                and encourage customers to share them.
              </p>
              <Pricing btnLabel="Start your free trial" />
            </div>
          </div>
        </div>
        <Parallax speed={2}>
          <span className="f-circle is-left"></span>
        </Parallax>
        <Parallax speed={6}>
          <span className="f-circle is-right is-big is-blurred"></span>
        </Parallax>
        <div className="container-fluid has-circle-bg home-steps is-white">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <h2 className="mb-5">How it works</h2>
            </div>
            <div className="col-12 col-md-4">
              <div className="icon">
                <img src={IconStar} alt="" />
              </div>
              <h3>Get Quality Feedback</h3>
              <p>
                Our platform facilitates the gathering of customers reviews. A
                one-click review gathering process.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="icon">
                <img src={IconAnalyze} alt="" />
              </div>
              <h3>Analyze your results</h3>
              <p>
                Our easy to read dashboard stores all reviews. We notify you
                instantly when you have an unhappy customer.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="icon">
                <img src={IconImprove} alt="" />
              </div>
              <h3>Improve your business</h3>
              <p>
                Make business decisions based on your reviews to further improve
                your customer experience.
              </p>
            </div>
          </div>
          <div className="container-fluid home-doc">
            <div className="row">
              <div className="col-12 col-md-4 offset-md-1">
                <img src={FeatDoctor} alt="" />
              </div>
              <div className="col-12 align-self-center col-md-6">
                <h2>Loved by doctors</h2>
                <p>
                  With the popularity of health platforms such as RealSelf and
                  Healthgrades, doctors are finding it more important to have
                  positive reviews on those platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid home-features">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center mb-5">
              <h2>Features</h2>
            </div>
            <Tabs />
          </div>
        </div>
        <Parallax speed={7}>
          <span className="f-circle is-right"></span>
        </Parallax>
        <Parallax speed={6}>
          <span className="f-circle is-left is-big is-blurred"></span>
        </Parallax>
        <div className="container-fluid has-circle-bg home-steps is-grey home-pricing">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <h2 className="mb-4">Get Started with ReviewLoop</h2>
              <Pricing btnLabel="Start your free trial" />
            </div>
          </div>
          .
        </div>
      </div>
    );
  }
}
