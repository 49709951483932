import React from "react";
import { func, string } from "prop-types";

PanelHeader.propTypes = {
  title: string,
  renderLeft: func
};

PanelHeader.defaultProps = {
  renderLeft: () => {}
};

export default function PanelHeader(props) {
  if (!props.title) return null;
  return (
    <div className="panel-header">
      <h3 className="panel-title">{props.title}</h3>
      <div className="panel-controls">{props.renderLeft()}</div>
    </div>
  );
}
