import React from "react";
import { bool, func, node, object, string } from "prop-types";
import { NavLink } from "react-router-dom";

Button.propTypes = {
  disabled: bool,
  onClick: func.isRequired,
  children: node.isRequired,
  classes: string,
  disabledText: string,
  to: string,
  link: bool,
  exact: bool,
  style: object,
  inverse: bool
};

Button.defaultProps = {
  inverse: false,
  onClick: () => {},
  classes: ""
};

function Button(props) {
  let disabledText = props.disabledText ? props.disabledText : props.children;
  if (props.link) {
    return (
      <NavLink
        className={`btn${props.inverse ? " btn-inverse" : ""} ${props.classes}`}
        exact={props.exact}
        to={props.to}
        style={props.style}>
        {props.children}
      </NavLink>
    );
  }
  return (
    <button
      className={`btn ${props.inverse ? "btn-inverse" : ""} ${
        props.disabled ? "btn-disabled" : ""
      } ${props.classes}`}
      onClick={props.disabled ? undefined : props.onClick}
      style={props.style}>
      {props.disabled ? disabledText : props.children}
    </button>
  );
}

export { Button };
