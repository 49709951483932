import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';

import user from "../screens/users/reducer";
import locations from "../screens/locations/redux/reducer";
import reviews from "../screens/reviews/redux/reducer";

export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  locations,
  reviews,
  lastVisited: (state = "/", action) => {
    switch (action.type) {
      case "@@router/LOCATION_CHANGE":
        return window.location.pathname === "/login"
          ? state
          : window.location.pathname;
      default:
        return state;
    }
  }
});
