import React from "react";
import { bool, func, node, object, string } from "prop-types";
import { NavLink } from "react-router-dom";

FAButton.propTypes = {
    disabled: bool,
    onClick: func.isRequired,
    children: node.isRequired,
    classes: string,
    to: string,
    link: bool,
    exact: bool,
    style: object,
    inverse: bool
};

FAButton.defaultProps = {
    inverse: false,
    onClick: () => { },
    classes: ""
};

function FAButton(props) {
    if (props.link) {
        return (
            <NavLink
                className={`btn-fa${props.inverse ? " btn-fa-inverse" : ""} ${props.classes}`}
                exact={props.exact}
                to={props.to}
                style={props.style}>
                {props.children}
            </NavLink>
        );
    }

    return (
        <button
            className={`btn-fa ${props.inverse ? "btn-fa-inverse" : ""} ${
                props.disabled ? "btn-fa-disabled" : ""
                } ${props.classes}`}
            onClick={props.disabled ? undefined : props.onClick}
            style={props.style}>
            {props.children}
        </button>
    );
}

export { FAButton };
