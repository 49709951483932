import React, { Component, Fragment } from 'react';
import { FormGroup, Button } from "../../../common";

let DescriptionText = (props) => {
  return (
    props.reviewRating <= 3 ?
      <div>
        <h2>How can we improve?</h2>
        <p>We always aim for the best customer experience.</p>
      </div>
      :
      <div>
        <h2>Fantastic!</h2>
        <p>Tell us about your awesome experience!</p>
      </div>
  )
};

const Emoji = props => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);

class FilterReviewForm extends Component {
  state = {
    review: ""
  };

  handleGoBackClick = (e) => {
    this.props.handleDisplayAndFetchingState({ isFetching: false, display: "Review" });
  }

  handleFilterReview = (e, review) => {
    e.preventDefault();
    /*
    * Set correct status based on review rating
    * */
    let newStatus = (this.props.reviewRating >= 4 ? "Reviewed" : "Filtered");
    this.props.handleDisplayAndFetchingState({ isFetching: true });
    this.props.updateReview(this.props.reviewHash, { review, status: newStatus }, () => {
      this.props.handleDisplayAndFetchingState({ isFetching: false, display: "Platform" });
    });
    localStorage.setItem('reviewloop_review', this.state.review)
  };

  handleInputChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  render() {
    let buttonIsDisabled = this.state.review.length === 0;
    let skipDisabled = this.state.review.length >= 1;
    // If review is 4 or above make button enabled.
    this.props.reviewRating >= 4 && (buttonIsDisabled = false);
    const activeClass = (skipDisabled) ? 'btn-primary' : '';
    return (
      <form className={this.props.classNameProp}>
        <DescriptionText reviewRating={this.props.reviewRating} />
        <FormGroup
          label=""
          type="textarea"
          placeholder={"Type your review"}
          value={this.state.review}
          onChange={e => this.handleInputChange(e, "review")}
        />
        {
          this.props.reviewRating >= 4 ?
            <div className={'button-wrapper mt-4'}>
              <Button classes={`ml-2 mr-2 ${activeClass}`}disabled={buttonIsDisabled} onClick={(e) => this.handleFilterReview(e, this.state.review)}>
                Send Feedback
              </Button>
              <Button disabled={skipDisabled} classes={"ml-1 mr-1"} onClick={(e) => this.props.handleDisplayAndFetchingState({ display: 'Platform' })}>
                Skip <Emoji symbol="😢" label="tear" />
              </Button>
            </div>
            :
            <Button disabled={buttonIsDisabled} onClick={(e) => this.handleFilterReview(e, this.state.review)}>
              Send
            </Button>
        }
        <div className={'back-button'} onClick={e => this.handleGoBackClick(e)}>Go Back</div>
      </form>
    )
  }
  static defaultProps = {
    classNameProp: "rating-container",
  }
}

export default FilterReviewForm;
