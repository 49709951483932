import React, { Component } from "react";
import moment from "moment";
import { NavLink, Route } from "react-router-dom";
import { connect } from "react-redux";
import CardInfo from "../scenes/CardInfo";
import { injectStripe } from "react-stripe-elements";

import { ViewWithLoader, FormGroup, Button } from "../../../common";

import {
  fetchUser,
  updateUserProfile,
  updateUserPassword,
  updateUserPayment,
  cancelUserSubscription,
} from "../../../screens/users/actions";

import { updatePaymentError } from "../actions/stripe_actions";

class Settings extends Component {
  state = {
    isFetching: true,
    profileUpdating: false,
    passwordUpdating: false,
    paymentMessage: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirm: "",
    password_message: "",
    address_state: "",
    address_city: "",
    address_line1: "",
    nameOnCard: "",
  };

  componentDidMount() {
    this.props.fetchUser(() => {
      let { userData } = this.props;
      this.setState({
        isFetching: false,
        first_name: userData.first_name,
        last_name: userData.last_name,
      });
    });
  }

  handleInputChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  getSubscriptionStatus = () => {
    let { userData } = this.props;
    let subscriptionEnd = moment.unix(userData.current_period_end);
    if (moment().diff(subscriptionEnd.format("YYYY-MM-DD")) < 0) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  getSubscriptionEndDate = (current_period_end) => {
    let { userData } = this.props;
    return moment.unix(current_period_end).format("MMMM Do, YYYY");
  };

  updateUserInfo = (e) => {
    e.preventDefault();
    if (this.state.first_name !== "" || this.state.last_name !== "") {
      this.setState(
        {
          profileUpdating: true,
        },
        () => {
          this.props.updateUserProfile(
            {
              first_name: this.state.first_name,
              last_name: this.state.last_name,
            },
            () => {
              this.setState({
                profileUpdating: false,
              });
            }
          );
        }
      );
    }
  };

  updateUserPassword = (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.password_confirm) {
      this.setState({
        password_message: "Passwords don't match",
      });
    } else if (
      this.state.password === "" ||
      this.state.password_confirm === ""
    ) {
      this.setState({
        password_message: "Password can't be blank",
      });
    } else {
      this.setState(
        {
          passwordUpdating: true,
        },
        () => {
          this.props.updateUserPassword(
            {
              password: this.state.password,
            },
            () => {
              this.setState({
                passwordUpdating: false,
                password_message: "Password successfully updated",
              });
            }
          );
        }
      );
    }
  };

  onCancelSubscription = (e, id) => {
    e.preventDefault();
    this.props.cancelUserSubscription(id);
  };

  onCardUpdate = async () => {
    let { userData } = this.props;
    //this.props.updateErrorNotification();
    const ownerInfo = {
      type: "card",
      owner: {
        name: this.state.first_name,
        address: {
          line1: this.state.address_line1,
          city: this.state.address_city,
          postal_code: this.state.zipcode,
          state: this.state.address_state,
        },
        email: userData.email,
      },
    };

    const { source, error } = await this.props.stripe.createSource(ownerInfo);
    if (source) {
      this.props.updateUserPayment(
        {
          stripe_source_id: source.id,
        },
        () => {
          this.setState({
            paymentMessage: "Card payment updated",
          });
        }
      );
    }
    if (error) {
      this.setState({
        paymentMessage: "There was an error with your payment method request",
      });
    }
  };

  render() {
    let { userData } = this.props;
    let { stripe } = userData;
    let activeSubscriptions = stripe.subscriptions.data.filter(
      (el) => el.status === "active" || el.status === "trialing"
    );
    let stripeSource = stripe.sources.data.find(
      (x) => x.id === stripe.default_source
    );

    let subStatus = "inactive";
    if (activeSubscriptions.length) {
      subStatus = activeSubscriptions[0].status;
      subStatus === "trialing" && (subStatus = "Trial Mode");
    }

    return (
      <ViewWithLoader isLoading={this.state.isFetching}>
        <div className="col-12">
          <ul className="settings-nav">
            <li>
              <NavLink exact className="pointer" to="/account">
                Account
              </NavLink>
            </li>
            <li>
              <NavLink className="pointer" to="/account/billing">
                Billing
              </NavLink>
            </li>
          </ul>

          <div className="col-12 settings-status-container">
            <h3>
              Subscription Status:{" "}
              <span className={subStatus.toLowerCase()}>{subStatus}</span>
            </h3>
          </div>

          {/*
           Profile Tab
           */}

          <Route
            exact
            path="/account"
            render={(props) => (
              <div className="form-page">
                <h3>Profile</h3>

                <FormGroup
                  classes=""
                  type="text"
                  disabled={true}
                  label="Email Address"
                  value={this.props.userData.email}
                  onChange={(e) => true}
                />

                <FormGroup
                  classes=""
                  type="text"
                  label="First Name"
                  value={this.state.first_name}
                  onChange={(e) => this.handleInputChange(e, "first_name")}
                />
                <FormGroup
                  classes=""
                  type="text"
                  label="Last Name"
                  value={this.state.last_name}
                  onChange={(e) => this.handleInputChange(e, "last_name")}
                />
                <Button
                  disabled={this.state.profileUpdating}
                  onClick={(e) => this.updateUserInfo(e)}
                >
                  {this.state.profileUpdating
                    ? "Updating..."
                    : "Update Profile"}
                </Button>
                <hr />
                <h3>Security</h3>
                {this.state.password_message !== "" && (
                  <div className="alert alert-warning">
                    {this.state.password_message}
                  </div>
                )}
                <FormGroup
                  classes=""
                  type="password"
                  label="New Password"
                  placeholder="Enter new password"
                  value={this.state.password}
                  onChange={(e) => this.handleInputChange(e, "password")}
                />
                <FormGroup
                  classes=""
                  type="password"
                  label="Confirm Password"
                  placeholder="Confirm new password"
                  value={this.state.password_confirm}
                  onChange={(e) =>
                    this.handleInputChange(e, "password_confirm")
                  }
                />
                <Button
                  disabled={this.state.passwordUpdating}
                  onClick={(e) => this.updateUserPassword(e)}
                >
                  {this.state.passwordUpdating
                    ? "Updating..."
                    : "Update Password"}
                </Button>
              </div>
            )}
          ></Route>

          {/*
           Billing Tab
           */}

          <Route
            path="/account/billing"
            render={(props) => (
              <div className="form-page">
                {activeSubscriptions.map((el, i) => {
                  return (
                    <div className="active-subscription" key={i}>
                      <h3>Subscription Details</h3>
                      {el.cancel_at_period_end ? (
                        <p>
                          Your subscription will end on{" "}
                          {this.getSubscriptionEndDate(el.current_period_end)}.
                          It will not auto-renew.
                        </p>
                      ) : (
                        <div>
                          <p>
                            Your subscription will{" "}
                            {subStatus === "trialing" ? "start" : "renew"} on:{" "}
                            {this.getSubscriptionEndDate(el.current_period_end)}
                          </p>
                          <p>
                            Monthly Price: $
                            {parseFloat(
                              activeSubscriptions[0].plan.amount / 100
                            ).toFixed(2)}
                          </p>
                          <button
                            className="btn btn-primary btn-small"
                            onClick={(e) => this.onCancelSubscription(e, el.id)}
                          >
                            Cancel Subscription
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}

                {subStatus !== "inactive" && (
                  <div>
                    <hr />
                    <h3>Current Payment Method</h3>
                    <FormGroup
                      classes=""
                      type="text"
                      disabled={true}
                      label="Credit Card Number"
                      value={"•••• •••• •••• " + stripeSource.card.last4}
                      onChange={(e) => console.log("test")}
                    />
                    <hr />
                  </div>
                )}

                {subStatus !== "inactive" ? (
                  <h3>Update Payment Method</h3>
                ) : (
                  <div>
                    <h3>Activate your subscription ($75/month)</h3>
                  </div>
                )}

                {this.state.paymentMessage !== "" && (
                  <div className="alert alert-warning">
                    {this.state.paymentMessage}
                  </div>
                )}
                <CardInfo
                  formStep={1}
                  thisReference={this}
                  addressState={this.state.address_state}
                  addressCity={this.state.address_city}
                  addressLine1={this.state.address_line1}
                  submit={this.onCardUpdate}
                  submitText="Update Payment"
                  view={"update"}
                  updateInfo={true}
                  nameOnCard={this.state.nameOnCard}
                />
              </div>
            )}
          ></Route>
        </div>
      </ViewWithLoader>
    );
  }
}

const mapStateToProps = ({
  user: {
    account: { data: userData },
  },
}) => ({
  userData,
});

const connected = injectStripe(
  connect(mapStateToProps, {
    fetchUser,
    updateUserProfile,
    updateUserPassword,
    updatePaymentError,
    updateUserPayment,
    cancelUserSubscription,
  })(Settings)
);

export { connected as Settings };
