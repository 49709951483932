import React, { Component } from "react";
import PropTypes from "prop-types";
import SentimentPieGraph from "../components/SentimentPieGraph";

import moment from "moment";

class Sentiment extends Component {
  static propTypes = {
    locationReviews: PropTypes.array.isRequired,
  };

  state = {
    startMonth: moment().subtract(1, "months").format("MM"),
    startYear: moment().format("YYYY"),
    endMonth: moment().format("MM"),
    endYear: moment().format("YYYY"),
  };

  // Anger id:1
  // Joy id:2
  // Sadness id:5

  render() {
    let sentiments = this.props.locationReviews.filter(
      (item) => item.sentiment !== null
    );
    let totalSentiments = sentiments.filter(
      (item) =>
        item.sentiment.id === 1 ||
        item.sentiment.id === 2 ||
        item.sentiment.id === 5
    );
    return <SentimentPieGraph totalSentiments={totalSentiments} />;
  }
}

export { Sentiment };
