import React from "react";
import { bool, func, object, string } from "prop-types";
import { ViewWithLoader } from "./";

PanelBody.propTypes = {
  className: string,
  isLoading: bool,
  errors: string,
  render: func,
  style: object
};

PanelBody.defaultProps = {
  render: () => null
};

function PanelBody(props) {
  return (
    <ViewWithLoader
      isLoading={props.isLoading}
      customStyle={props.style}
      classes={props.className}
      errors={props.errors}
    >
      {props.render()}
    </ViewWithLoader>
  );
}

export default PanelBody;
