import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as actions from "../actions";
import { Button, Card, FormGroup } from "../../../common";
import { handleInputChange } from "../../../lib/utilities";

class UsersActivate extends Component {
  state = {
    password: "",
    password_confirmation: "",
    error: null,
  };

  handleSubmit = () => {
    const { password } = this.state;
    this.props.createPassword({
      password,
      password_reset: this.props.match.params.id
    }, () => this.setState({error: "Your reset token has expired. Please request a new password."}))
  };

  render() {
    const { password, password_confirmation } = this.state;
    const areDifferentPasswords = password !== password_confirmation;
    const isDisabled =
      password === "" || password_confirmation === "" || areDifferentPasswords;
    return (
      <Card
        title="Create a Password"
        classes="col-sm-12 col-md-6 offset-md-3"
        padding={4}
      >
        {areDifferentPasswords &&
          password_confirmation.length > 0 && (
            <div className="alert alert-warning">Passwords Must Match</div>
          )}
        <FormGroup
          label="Password"
          type="password"
          classesInput={"input-gray"}
          value={password}
          onChange={e => handleInputChange(this, e, "password")}
        />
        <FormGroup
          label="Password Confirmation"
          type="password"
          classesInput={"input-gray"}
          value={password_confirmation}
          onChange={e => handleInputChange(this, e, "password_confirmation")}
        />
        {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
        <Button
          onClick={this.handleSubmit}
          disabled={isDisabled}
          grey={isDisabled}
          style={{ marginTop: "10px" }}
        >
          Create Password
        </Button>
      </Card>
    );
  }
}

UsersActivate.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  createPassword: PropTypes.func,
};

const mapStateToProps = ({ user }) => ({ user });

const connected = connect(mapStateToProps, actions)(UsersActivate);

export { connected as UsersActivate };
