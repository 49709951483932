import React, { Component } from 'react'
import StarRatingComponent from 'react-star-rating-component';

const Item = (props) => {
    return (
        <span
            key={props.id}
            className={props.parentContext.state.highlightId === props.id ? 'highlight dropdown-selection' : 'dropdown-selection'}
            onMouseOver={(e) => { props.parentContext.highLight(e, props.id) }}
            onClick={(e) => { props.parentContext.onDropDownItemClick(e, props.id) }}
        >
            <StarRatingComponent
                name="rate2"
                editing={false}
                renderStarIcon={() => <i className="fa fa-star "></i>}
                renderStarIconHalf={() => <i className="fa fa-star-half"></i>}
                emptyStarColor={'#d3d3d3'}
                starColor={"#9964F2"}
                value={props.id}
                onStarClick={(e) => props.parentContext.onDropDownItemClick(e, props.id)}
            />
        </span>
    )

}

const DropdownContent = (props, content) => {
    const items = [];
    for (let i = props.startCount || 0; i > 0; i--) {
        // items.push(<Item id={i} parentContext={props.parentContext} />)
        items.push(props.content(props.parentContext, i))
    };
    return (
        <div className="dropdown-list">
            {items}
        </div>
    )
}


export class Dropdown extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    state = {
        selected: '',
        highlightId: '',
        defaultValue: 'Filter By Rating',
        optionsVisible: false,
    }

    showHideDropdown = (e) => {
        this.setState({ optionsVisible: !this.state.optionsVisible })
    }

    highLight = (e, id) => {
        if (this.state.highlightId !== id) {
            this.setState({ highlightId: id })
        }
    }

    onDropDownItemClick = (e, id) => {
        this.setState({ selected: id })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selected !== prevState.selected) {
            this.props.filterByRating(this.state.selected);
        }
    }

    render() {
        let parentContext = this;
        return (
            <div className="dropdown-container" onClick={this.showHideDropdown}>
                <div className="selected">
                    <span>
                        {
                            this.state.selected ? <StarRatingComponent
                                name="rate2"
                                editing={false}
                                renderStarIcon={() => <i className="fa fa-star "></i>}
                                renderStarIconHalf={() => <i className="fa fa-star-half"></i>}
                                emptyStarColor={'#d3d3d3'}
                                starColor={"#9964F2"}
                                value={this.state.selected}
                            /> :
                                this.state.defaultValue
                        }
                    </span>
                    <span>
                        <i className="fa fa-angle-down"></i>
                    </span>
                </div>
                {
                    this.state.optionsVisible ?
                        <DropdownContent
                            startCount={5}
                            count={null}
                            content={(parentContext, i) =>
                                <Item
                                    key={Math.random()}
                                    parentContext={parentContext}
                                    id={i}
                                    onDropDownItemClick={this.onDropDownItemClick}
                                />
                            }
                            parentContext={parentContext} />
                        : null
                }
            </div>
        )
    }
}
