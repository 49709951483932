import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as actions from "../actions";
import { Button, Card, FormGroup } from "../../../common";
import { handleInputChange } from "../../../lib/utilities";

class UsersNew extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: ""
  };

  handleCreateUser = () => {
    const { first_name, last_name, email, } = this.state;
    this.props.createUser({
      first_name,
      last_name,
      email
    })
  };

  render() {
    const { first_name, last_name, email } = this.state;
    const isDisabled =
      first_name === "" ||
      last_name === "" ||
      email === "";
    return (
      <Card
        title="Create User"
        classes="col-sm-12 col-md-6 offset-md-3"
        errors={this.props.user.new.errors}
        success={this.props.user.new.data.message}
        padding={5}
      >
        <FormGroup
          label="First Name"
          type="text"
          value={this.state.first_name}
          onChange={e => handleInputChange(this, e, "first_name")}
        />
        <FormGroup
          label="Last Name"
          type="text"
          value={this.state.last_name}
          onChange={e => handleInputChange(this, e, "last_name")}
        />
        <FormGroup
          label="Email"
          type="email"
          value={this.state.email}
          onChange={e => handleInputChange(this, e, "email")}
        />
        <Button
          onClick={this.handleCreateUser}
          disabled={isDisabled}
          grey={isDisabled}
          style={{ marginTop: "10px" }}
        >
          Create User
        </Button>
      </Card>
    );
  }
}

UsersNew.propTypes = {
  user: PropTypes.object,
  createUser: PropTypes.func
}

const mapStateToProps = ({ user }) => ({ user });

const connected = connect(mapStateToProps, actions)(UsersNew);

export { connected as UsersNew };
