import React, {Component} from 'react';
import FeatHero from "../../../images/home/feat-hero.png";
import { NavLink } from "react-router-dom";

export default class Hero extends Component {
  render(){
    return (
      <div className="home-hero container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 mobile-responsive-flex">
            <h1>
              <span className="d-block">Listen to customers.</span>
              <span className="d-block">Improve your business.</span>
              Repeat.
            </h1>
            <NavLink className="btn" to="/register">Start your 7-day free trial</NavLink>
          </div>
          <div className="col-12 col-md-6 home-hero_image">
            <img src={FeatHero} alt=""/>
          </div>
          <div className="c c-1"></div>
          <div className="c c-2"></div>
          <div className="c c-3"></div>
        </div>
      </div>
    )
  }
}