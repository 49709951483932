import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class ContentWithHeader extends Component {
  static propTypes = {
    addedClasses: PropTypes.string
  }
  render() {
    return (
      <div className={`content-with-header-container mt-3 mb-5 row ${this.props.addedClasses}`}>
        <div className={"col-12"}>
          <div className={`mainTitleSpace ${this.props.subTitleClasses}`}>
            {this.props.renderMainTitleSpace && <h2>{this.props.renderMainTitleSpace}</h2>}
          </div>
          {
            this.props.renderSubTitleSpace || this.props.renderSubtitleAction ?
              <div className={`subtitleSpaceContainer ${this.props.subTitleClasses}`}>
                <div className="subtitleSpaceLeft">
                  {this.props.renderSubTitleSpace()}
                </div>
                <div className="subtitleSpaceRight">
                  {this.props.renderSubtitleAction && this.props.renderSubtitleAction()}
                </div>
              </div> : null
          }
          <div className={this.props.contentSpaceClasses}>
            {this.props.renderContent()}
          </div>
          {/* <button onClick={() => { }}>Button</button> */}
        </div>
      </div>
    )
  }
}
