import React from "react";
import { func, string, bool } from "prop-types";

FormGroup.propTypes = {
  label: string,
  type: string,
  value: string.isRequired,
  onChange: func.isRequired,
  onKeyUp: func,
  onKeyPress: func,
  classes: string,
  placeholder: string,
  disabled: bool
};

FormGroup.defaultProps = {
  type: "text",
  onKeyPress: () => ({}),
};

function FormGroup(props) {
  return (
    <div className={`form-group ${props.classes}`}>
      {props.label && <label>{props.label}</label>}
      {
        (props.type === "textarea") ?
          <textarea
            className={`form-control ${props.classesTextArea}`} type={props.type}
            value={props.value}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            onKeyPress={props.onKeyPress}
            placeholder={props.placeholder}
            disabled={props.disabled}
          /> :
          <input
            className={`form-control ${props.classesInput}`} type={props.type}
            value={props.value}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            onKeyPress={props.onKeyPress}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
      }
    </div>
  );
}

export { FormGroup };
