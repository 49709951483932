import React from "react";
import PropTypes from "prop-types";
import withLoader from "../hoc/withLoader";
import withErrors from "../hoc/withErrors";
import withSuccess from "../hoc/withSuccess";

const Card = props => {
  return (
    <div className={props.classes}>
      <div className="card">
        {!props.hideHeader && (
          <div className="card-header">
            <h3 className="card-title">{props.title}</h3>
            <div className="card-controls">{props.buttonLeft()}</div>
          </div>
        )}

        <div className={`card-block p-${props.padding} ${props.customPadding}`}>
          {props.subtitle && (
            <h3 className="text-center mt-3 mb-4 subtitle">
              <span>{props.subtitle}</span>
            </h3>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLeft: PropTypes.func,
  hideHeader: PropTypes.bool,
  padding: PropTypes.number,
  customPadding: PropTypes.string
};

Card.defaultProps = {
  buttonLeft() {
    return <div />;
  },
  hideHeader: false,
  padding: 1,
  customPadding: ""
};

const CardWithErrors = withLoader(withSuccess(withErrors(Card)));

export { CardWithErrors as Card };
