import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
// import PropTypes from "prop-types";
import RegistrationUserInfo from "./RegistrationUserInfo";
import CardInfo from "./CardInfo";
import { ViewWithLoader } from "../../../common";
import axios from "axios";
import { rootUrl } from "../../../lib/api";
import _ from "lodash";


const debouncedSearchTerm = _.debounce( async (params, that) => {
    const url = `${rootUrl}/public/validateEmail`;
    const response = await axios.post(url, {email:params});
    if(response.data){
        that.setState({emailExists: response.data.data})
    }
}, 1000);
class RegistrationForm extends Component {
    state = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        address_state: "",
        address_city: "",
        address_line1: "",
        nameOnCard: "",
        formStep: 0,
        accountCreationLoading: false,
        emailExists: false,
        stripeSrcError: false,
        stripeSrcErrorMessage: '',
    }
    
    validateEmail = (params) => {
        if(params){
            debouncedSearchTerm(params, this);
        }
    };

    liftEmail = (e) => {
        this.setState({email:e.target.value}, ()=>{
            this.validateEmail(this.state.email);
        })
    }

    nextStep = (direction) => {
        direction === "forward" && this.setState({ formStep: 1 });
        direction === "back" && this.setState({ formStep: 0 })
    }

    componentWillUnmount() {
        this.setState({ accountCreationLoading: false })
    }

    submit = async () => {
        this.props.updatePaymentError();
        const ownerInfo = {
            type: 'card',
            owner: {
                name: this.state.first_name,
                address: {
                    line1: this.state.address_line1,
                    city: this.state.address_city,
                    postal_code: this.state.zipcode,
                    state: this.state.address_state,
                },
                email: this.state.email
            },
        };
        try{
            const { source, error } = await this.props.stripe.createSource(ownerInfo)
            if (source) {
                const { first_name, last_name, email, password } = this.state;
                const params = {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    password: password,
                    sourceId: source.id,
                }
                this.setState({ accountCreationLoading: true })
                this.props.registerUser(params, () => this.setState({ accountCreationLoading: false }));
            }
            if(error){
                if(error.message){
                    this.setState({stripeSrcError: true, stripeSrcErrorMessage: error.message})
                }
            }
        }
        catch(error){
            this.setState({stripeSrcError: true, stripeSrcErrorMessage: error.message})
        }  
    }

    clearSourceError = () => {
        this.setState({stripeSrcError: false, stripeSrcErrorMessage: '' });
    }

    render() {
        return (
            <ViewWithLoader
                classes="account-creation-loader"
                isLoading={this.state.accountCreationLoading}
            >
                <div className="checkout" >
                    <RegistrationUserInfo
                        thisReference={this}
                        firstName={this.state.first_name}
                        lastName={this.state.last_name}
                        email={this.state.email}
                        password={this.state.password}
                        passwordConfirm={this.state.password_confirm}
                        formStep={this.state.formStep}
                        nextStep={this.nextStep}
                        liftEmail={this.liftEmail}
                        validateEmail={this.validateEmail}
                        emailExists={this.state.emailExists}
                    />
                    <CardInfo
                        nextStep={this.nextStep}
                        clearSourceError={this.clearSourceError}
                        stripeSrcError={this.state.stripeSrcError}
                        stripeSrcErrorMessage={this.state.stripeSrcErrorMessage}
                        view={'signup'}
                        isLoading={this.state.isLoading}
                        formStep={this.state.formStep}
                        thisReference={this}
                        addressState={this.state.address_state}
                        addressCity={this.state.address_city}
                        addressLine1={this.state.address_line1}
                        submit={this.submit}
                        nameOnCard={this.state.nameOnCard}
                        paymentErrorMessage={this.props.paymentErrorMessage}
                        inputError={this.props.paymentErrorMessage}
                        submitText="Finish"
                        updateInfo={false}
                    />
                </div >
            </ViewWithLoader>
        )
    }
}

// RegistrationForm.propTypes = {
//     registerUser: PropTypes.func,
//     paymentErrorMessage: PropTypes.func,
//     updateErrorNotification: PropTypes.func
// }

export default injectStripe(RegistrationForm);