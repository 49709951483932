import axios from "axios";
import qs from 'qs';

import { rootUrl } from "../../../lib/api";
import { requestHeaders, getToken } from "../../../lib/authHelpers";
import { dispatchHelper } from "../../../lib/actionHelpers";
import { authLogoutAndRedirect } from "../../users/actions";

import {
  FETCHING_LOCATIONS,
  FETCHING_LOCATION,
  FETCHING_LOCATION_REVIEWS,
  FETCHING_PLATFORMS,
  UPDATING_LOCATION_PLATFORMS,
  UPDATING_LOCATION_SETTINGS,
  DELETING_LOCATION_PLATFORM,
  SEARCHING_YELP,
  FETCHING_YELP,
  CREATING_LOCATION_TOKEN,
  DELETING_LOCATION_TOKEN,
  FEATURE_REVIEW,
  FETCH_FEATURED_REVIEWS,
  CHANGE_FEATURED_REVIEWS_POSITION,
  AUTO_FEATURE_FIVE_STAR_REVIEWS,
} from './types';

export const autoFeatureFiveStarReviews = (location_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(AUTO_FEATURE_FIVE_STAR_REVIEWS, dispatch);
  const token = getToken();
  const params = { id: location_id }
  const postParams = JSON.stringify(params);
  const url = `${rootUrl}/locations/${location_id}/autoAddReview`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const changeFeaturedReviewsPosition = (location_id, callback, direction, reviews, id) => async dispatch => {
  let indexOfReview = reviews.findIndex(i => i.id === id);
  let futureIndex;
  if (direction === 'left') {
    futureIndex = indexOfReview - 1
  } else {
    futureIndex = indexOfReview + 1
  }
  const move = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  let positionedReviews = move(reviews, indexOfReview, futureIndex);
  positionedReviews.forEach((review, i) => {
    review.pos = i;
  });

  let reviewIdAndPosition = positionedReviews.reduce((all, item, index) => {
    all.push({ id: item.id, pos: item.pos });
    return all;
  }, [])

  const dispatching = dispatchHelper(CHANGE_FEATURED_REVIEWS_POSITION, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/reviewPosition`;
  const postParams = reviewIdAndPosition;
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};


export const fetchFeaturedReviews = (location_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCH_FEATURED_REVIEWS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/featuredReviews`;
  // const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.get(url, headers);
  dispatching(response, callback);
};


export const featureReview = (review_hash, callback, startDate, endDate, reviews) => async dispatch => {
  const dispatching = dispatchHelper(FEATURE_REVIEW, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/featured/${review_hash}?startDate=${startDate}&endDate=${endDate}`;
  const headers = requestHeaders(token);
  const response = await axios.post(url, reviews, headers);
  dispatching(response, callback);
};

export const fetchLocations = (callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_LOCATIONS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchLocation = (location_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_LOCATION, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchLocationReviews = (location_id, queryParams, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_LOCATION_REVIEWS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/reviews`;
  const headers = requestHeaders(token);
  const startDate = queryParams.startDate;
  const endDate = queryParams.endDate;
  const params = qs.stringify({ startDate, endDate });
  try {
    const response = await axios.get(url + `?${params}`, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchPlatforms = (callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_PLATFORMS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/platforms`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updatePlatforms = (location_id, params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(UPDATING_LOCATION_PLATFORMS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/platforms`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};

export const updateLocationSettings = (location_id, params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(UPDATING_LOCATION_SETTINGS, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/settings`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};

export const deleteLocationPlatform = (location_id, platform_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(DELETING_LOCATION_PLATFORM, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/platforms/${platform_id}`;
  const headers = requestHeaders(token);
  const response = await axios.delete(url, headers);
  dispatching(response, callback);
};

export const searchYelp = (query, city, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(SEARCHING_YELP, dispatch);
  const token = getToken();
  const url = `${rootUrl}/yelp/search?q=${query}&c=${city}`;
  const headers = requestHeaders(token);
  const response = await axios.get(url, headers);
  dispatching(response, callback);
};

export const fetchYelp = (location_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_YELP, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/yelp`;
  const headers = requestHeaders(token);
  const response = await axios.get(url, headers);
  dispatching(response, callback);
};

export const createLocationToken = (location_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(CREATING_LOCATION_TOKEN, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/${location_id}/token`;
  const headers = requestHeaders(token);
  const response = await axios.post(url, "", headers);
  dispatching(response, callback);
};

export const deleteLocationToken = (token_id, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(DELETING_LOCATION_TOKEN, dispatch);
  const token = getToken();
  const url = `${rootUrl}/locations/tokens/${token_id}`;
  const headers = requestHeaders(token);
  const response = await axios.delete(url, headers);
  dispatching(response, callback);
};

