import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Button} from "../../../common";

export class AdvancedSettings extends Component {
  static propTypes = {
    //prop: PropTypes
  }

  state= {
    visible : false,
  }

  handleCreateLocationToken = () => {
    this.props.createLocationToken(this.props.locationId)
  };

  componentDidUpdate(prevProps, prevState){
    if(this.state.visible !== prevState.visible){
      if(this.state.visible === true){
        window.scrollTo(0,document.body.scrollHeight);
      }
    }
  }

  handleDeleteToken = (token) => {
    this.props.deleteLocationToken(token)
  }

  render() {
    if (this.state.visible) {
      return (
        <div className="mb-4" ref={el => { this.el = el; }}>
        <Button classes="center-me mb-5" onClick={() => this.setState({visible : !this.state.visible}) }>{this.state.visible ? "Close" : "Show"} Advanced Settings <span className="ml-2 center-me"><i className="fas fa-2x fa-angle-down"></i></span></Button>
           <h3>API Tokens</h3>
            {this.props.locationData.tokens.map((el, i) => {
              return (
                <div className="input-group" key={el.id}>
                  <input
                    className="form-control"
                    type="text"
                    disabled=""
                    defaultValue={el.token}
                  />
                  <div className="input-group-append">
                    <Button classes="btn-delete" onClick={e => this.handleDeleteToken(el.id)}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </div>
                </div>
              )
            })}
            <Button classes={"mb-5"} onClick={e => this.handleCreateLocationToken(e)}>
              Generate Token
            </Button>
        </div>
      )
    } else {
      return(
        <Button classes="center-me mb-5" onClick={() => this.setState({visible : !this.state.visible}) }>{this.state.visible ? "Close" : "Show"} Advanced Settings <span className="ml-2"><i className="fas fa-2x fa-angle-up"></i></span></Button>
      )
    }
  }
}
