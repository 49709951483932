import React from 'react';

import { rootUrl } from "../../../lib/api";

const Platforms = (props) => {
  let platforms = props.platforms.slice().sort((a, b) => {
    return a.id > b.id
  });
  return (
    <ul className="platforms-list">
      {platforms.map((el, i) => {
        const href = `${rootUrl}/public/t/${props.hash}/${el.id}`;
        return (
          <li key={i}>
            <a target="_blank" href={href} >
              <img src={el.icon_url} alt="" />
              {el.name}
            </a>
          </li>
        )
      })}
    </ul>
  )
};

export default Platforms;