import React, { Component, Fragment } from "react";
import { func, object } from "prop-types";
import { NavLink, Route } from "react-router-dom";
import { connect } from "react-redux";
import { AppBanner } from "./";
import { trimName } from "../lib/textHelpers";

import { authLogoutAndRedirect } from "../screens/users/actions";
import logo from "../images/logo-white.svg";

export default class Navbar extends Component {
  static propTypes = {
    user: object,
    router: object,
    dispatch: func
  };

  handleLogout = e => {
    e.preventDefault();
    this.props.dispatch(authLogoutAndRedirect());
  };

  renderNav = () => {
    return (
      <ul className="mobile-responsive-nav">
        {
          this.props.user.authentication.data.token === null ?
            <Fragment>
              <li>
                <NavLink className="pointer" to="/login">Login</NavLink>
              </li>
              <li>
                <NavLink className="pointer" to="/register">Register</NavLink>
              </li>
            </Fragment>
            :
            <Fragment>
              <li key="locations">
                <NavLink className="pointer" to="/locations">Locations</NavLink>
              </li>
              <li key="user">
                <NavLink className="pointer user-link" to="/account">Account</NavLink>
                <ul>
                  <li>
                    <a className="pointer" onClick={this.handleLogout}>Logout</a>
                  </li>
                </ul>
              </li>
              <li key="settings">

              </li>
            </Fragment>
        }
      </ul>
    )
  };

  renderLogin = () => (
    <li>
      <NavLink to="/login">Login</NavLink>
    </li>
  );

  isOnDashboardScreen = () => {
    let url = window.location.pathname;
    let dashboardPath = `/locations/${this.props.locationData.id}`;
    if(url === dashboardPath){
      return true;
    }
    return false;
  }

  render() {
    let locationName = this.props.locationData.name;
    let checkDashboard = this.isOnDashboardScreen();
    return (
      <header className="site-header container-fluid">
        <AppBanner label="New Feature" text="Showcase your best reviews on your website! Visit your Location Settings to learn more." />
        <div className="navbar row">
          <div className="col-6">
            <NavLink className="header-branding" to="/">
              <img src={logo} alt="Studio 3 CRM" />
            </NavLink>
            <Route path="/locations/:location_id" render={props => (
              <NavLink className="header-current-location" to={`/locations/${this.props.locationData.id}`}>
              {
                !checkDashboard ? 
                  <Fragment>
                    {locationName && <span><i className="fas fa-home fa-sm mr-2"></i></span>}
                    {locationName && `Back to ${trimName(locationName)} Dashboard`}
                  </Fragment> : null
              }
              
              </NavLink>
            )}>
            </Route>
          </div>
          <nav className="col-6 mobile-response-nav">
            {this.renderNav()}
          </nav>
        </div>
      </header >
    );
  }
}

const mapStateToProps = ({
  user,
  router,
  locations: { show: { data: locationData } }
}) => ({
  user,
  router,
  locationData,
});

const connected = connect(mapStateToProps)(Navbar);
export { connected as Navbar };
