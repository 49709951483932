import React, { Component } from 'react'
import { FormGroup, FAButton, Button } from "../../../common";

export default class CustomerInput extends Component {
    render() {
        let customer = {
            first_name: this.props.firstName,
            last_name: this.props.lastName,
            phone_number: this.props.phoneNumber,
            valid: false,
            editing: false,
            id: this.props.id
        }
        let invalidDataPhone = false;
        let invalidFirstName = false;
        let invalidLastName = false;
        let valid = true;
        // Phone check
        let phone = this.props.phoneNumber.replace(/[^0-9]/g, '');
        phone.length !== 10 && (invalidDataPhone = true);
        let isNum = /^\d+$/.test(phone);
        isNum === false && (invalidDataPhone = true);
        // First name check
        this.props.firstName.length === 0 && (invalidFirstName = true);
        // Last name check
        this.props.lastName.length === 0 && (invalidLastName = true);
        invalidLastName === true || invalidFirstName === true || invalidDataPhone === true && (valid = false);
        let buttonDisabled = invalidLastName || invalidFirstName || invalidDataPhone;
        let buttonDisableSpecialInstance = false;
        (this.props.customerInfo.length <= 1 && this.props.type === 'customerInfo') && (buttonDisableSpecialInstance = true)
        let index = [this.props.customerInfo.length - 1];
        let last = index[0];
        return (
            <div className='review-addition-form'>
                <div className={"input-container " + (this.props.highLight ? 'input-highlight' : 'input-highlight-no')}>
                    {(this.props.id === last && this.props.type === 'customerInfo') && <FAButton classes={"fa-buttons addition-button"} onClick={this.props.addIndividualReview}><i className="fa fa-user-plus"></i></FAButton>}
                    <FormGroup
                        classes='new-form'
                        classesInput={(invalidFirstName && this.props.type === 'fileData') && 'error-red'}
                        // label="First Name"
                        placeholder={'Enter First Name'}
                        value={this.props.firstName}
                        onFocus={e => this.props.highlightFunc(e, this.props.id)}
                        onChange={e => this.props.handleInputChange(e, "first_name", this.props.id, this.props.type)}
                    />
                    <FormGroup
                        classes='new-form'
                        classesInput={(invalidLastName && this.props.type === 'fileData') && 'error-red'}
                        // label="Last Name"
                        placeholder={'Enter Last Name'}
                        value={this.props.lastName}
                        onFocus={e => this.props.highlightFunc(e, this.props.id)}
                        onChange={e => this.props.handleInputChange(e, "last_name", this.props.id, this.props.type)}
                    />
                    <FormGroup
                        classes='new-form'
                        classesInput={invalidDataPhone && 'error-red'}
                        placeholder={'Enter Phone Number'}
                        value={this.props.phoneNumber}
                        onFocus={e => this.props.highlightFunc(e, this.props.id)}
                        onChange={e => this.props.handleInputChange(e, "phone_number", this.props.id, this.props.type)}
                    />
                    <div className={'social-buttons'}>
                        {(this.props.platforms || []).map((n, idx) => (
                          <img key={idx} className={`social-button ${n.enabled ? 'enabled' : ''}`} src={n.icon_url || ''} onClick={e => this.props.onPlatformChange(e, n, this.props.id, this.props.type)} />
                        ))}
                    </div>
                    <div className="button-container-csv-data">
                        {
                            (this.props.type === "fileData" && buttonDisabled === false) && <Button classes={"confirm-info"} disabled={buttonDisabled} onClick={() => { this.props.saveReview(customer, this.props.type) }}>Confirm</Button>
                        }
                        {
                            (this.props.type === "fileData" || this.props.type === "customerInfo") && <button className={buttonDisableSpecialInstance ? "fa-buttons test-button" : "fa-buttons test-button"} onClick={buttonDisableSpecialInstance ? undefined : () => this.props.removeReview(this.props.id, this.props.type)}>  <i className="fas fa-trash-alt"></i> </button>
                        }
                    </div>
                </div >
            </div >
        )

    }
}

