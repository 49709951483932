import React, { Component } from 'react'
import StarRatingComponent from 'react-star-rating-component';
import ReviewWidgetFuntionality from "./ReviewWidgetFunctionality"
export default class ReviewWidget extends Component {
  render() {
    let rounded = (Math.round(this.props.rating * 2) / 2).toFixed(1)
    let roundedNumber = parseInt(rounded);
    return (
      <div className={(this.props.positionChangeEffect === this.props.id) ? "review-widget-container position-effect" : "review-widget-container"}>
        <div className="review-widget-head">
          <div className="review-person-name">
            <span>{`${this.props.customer.first_name} ${this.props.customer.last_name}`}</span>
          </div>
          <div className="star-container">
            <StarRatingComponent
              name="rate2"
              editing={false}
              renderStarIcon={() => <i className="fa fa-star "></i>}
              renderStarIconHalf={() => <i className="fa fa-star-half"></i>}
              emptyStarColor={'#d3d3d3'}
              starColor={"#9964F2"}
              value={roundedNumber}
            />
          </div>
        </div>
        <div className="review-widget-review">
          {this.props.review}
        </div>
        <ReviewWidgetFuntionality {...this.props} />
      </div>
    )
  }
}
