import React from 'react';
import { RatingsEmoji } from "../../../common";

const ReviewBreakdown = (props) => {
  const {location, reviews} = props;
  let reviewBucket = {
    "Pending": 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };
  reviews.forEach((el, i) => {
    if(el.rating === null) {
      reviewBucket["Pending"] += 1;
      return
    }
    reviewBucket[el.rating] += 1;
  });
  return (
    <div className="review-breakdown row">
      <div className="col-12 col-md-4">
        <h3>Overall Rating</h3>
        <div className="overall-rating">
          <span className="rating">{location.ratingAverage}</span>
          <span className="ratings-total">{location.ratingCount} total reviews</span>
        </div>
      </div>
      <div className="col-12 col-md-8">
        <h3>Monthly Breakdown</h3>
        <div className="progress-list">
          {
            Object.keys(reviewBucket).map((key, index) => {
              let progressWidth = (reviewBucket[key] / reviews.length)*100;
              return (
                <div key={index} className="progress-item">
                  <div className="progress-label">{key !== "Pending" ? <img alt="ratings_emoji" src={RatingsEmoji[index + 1]} /> : "Pending" } {(index < 5 && <span>{index+1}</span>)}</div>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{'width': `${progressWidth}%`}}></div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
};

export default ReviewBreakdown;