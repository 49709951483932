import React, { Component } from 'react'
import { MiniLoader } from "../../../common";
// (this.props.addingOrRemovingWidgetFromFeatured === this.props.id) ? <MiniLoader /> : <i className={'fa fa-heart'}></i>

export default class ReviewWidgetFunctionality extends Component {
    render() {
        return (
            <div className="review-widget-functionality">
                <div className="add-widget-container" onClick={
                    () => {
                        this.props.triggerWidgetAnimation(this.props.id);
                        this.props.featureReview(this.props.hash, this.props.triggerWidgetAnimation, this.props.dates.startDate, this.props.dates.endDate, this.props.reviewsInPosition);
                    }
                }>
                    <span className={this.props.featured === 1 ? 'red' : 'grey'}>
                        {
                            (this.props.addingOrRemovingWidgetFromFeatured === this.props.id) ? <MiniLoader /> : <div id="heart"></div>
                        }
                    </span>
                    {this.props.featured === 1 ? 'Remove' : 'Add To Widget'}
                </div>
                <div className="widget-buttons-container">
                    {
                        this.props.noArrows !== true ?
                            <span onClick={
                                () => {
                                    this.props.triggerEffect(this.props.id, this.props.position, 'left');
                                    this.props.changeFeaturedReviewsPosition(this.props.locationId, () => { this.props.triggerEffect(this.props.id, this.props.position, 'left'); }, 'left', this.props.reviewsInPosition, this.props.id);
                                }
                            }>
                                {this.props.pos !== 0 ? <i className="fas fa-arrow-left"></i> : null}
                            </span> : null
                    }
                    {
                        this.props.noArrows !== true ?
                            <span onClick={
                                () => {
                                    this.props.triggerEffect(this.props.id, this.props.position, 'right');
                                    this.props.changeFeaturedReviewsPosition(this.props.locationId, () => { this.props.triggerEffect(this.props.id, this.props.position, 'right'); }, 'right', this.props.reviewsInPosition, this.props.id)
                                }
                            }>
                                {this.props.pos !== this.props.lastPositionIndex ? <i className="fas fa-arrow-right"></i> : null} </span> : null
                    }
                </div>
            </div>
        )
    }
}
