export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer
      ? reducer(state, action.payload)
      : state;
  };
}

export const handleInputChange = (t, e, key) => {
  t.setState({ [key]: e.target.value })
};

export const filterResults = (items, target) => {
  const results = items.filter(item => {
    return item.name
      .toLowerCase()
      .includes(target.toLowerCase());
  })

  return results;
};

const _pipe = (f, g) => (...args) => g(f(...args));

export const pipe = (...fns) => fns.reduce(_pipe);


export let parseYelpUrl = (url) => {
  let yelpUrl = url.split('/biz/')[1];
  if (yelpUrl.substring(0, (yelpUrl.indexOf("?")))) {
    let parsedUrl = yelpUrl.substring(0, (yelpUrl.indexOf("?")));
    return parsedUrl;
  }
  else {
    let parsedUrl = yelpUrl.substring(0, (yelpUrl.indexOf("&")));
    return parsedUrl;
  }
}