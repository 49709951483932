import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { FormGroup, Button, ReuseableDropdown } from "../../../common";
import { fetchReviewLocation, updateOrganicReview } from "../redux/actions";
import { ViewWithLoader } from "../../../common";
import logo from "../../../images/logo-white.svg";

class OrganicReviewsShow extends Component {

  state = {
    isFetching: true,
    submitting: false,
    display: "Review",
    first_name: '',
    last_name: '',
    phone_number: ''
  };

  static defaultProps = {
    reviewData: {},
  }

  componentDidMount() {
    this.props.fetchReviewLocation(this.props.match.params.location_hash, () => {
      this.setState({ isFetching: false });
    });
  }

  componentDidUpdate() {
    if (this.props.organicReviewSuccess && this.props.organicReviewSuccess.hash) {
      const hash = this.props.organicReviewSuccess.hash;
      window.location = `/reviews/${hash}`;
    }
  }

  validate() {
    const phone = String(this.state.phone_number || '');
    const valid = this.state.first_name &&
      this.state.last_name &&
      (phone.length > 9);
    return valid;
  }

  handleInputChange(e, id) {
    this.setState({
      [id]: e.target.value
    })
  }

  handleInputKeyUp(e) {
    if (e.keyCode === 13) {
      this.handleNextClick();
    }
  }

  handleNextClick(e) {
    if (this.validate()) {
      this.props.updateOrganicReview(this.props.match.params.location_hash, {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_number: this.state.phone_number
      });
      this.setState({
        submitting: true
      });
    }
  }

  render() {
    const company = (this.props.locationData || {}).name;
    const valid = this.validate() && !this.state.submitting;
    const welcomeText = (this.state.submitting) ? 'Awesome!' : 'Welcome!';
    const waitText = (this.state.submitting) ? 'Please wait...' : "Let's get to know you!";
    return (
      <div>
        <div className={'organic-review-wrapper'}>
          <h2>{welcomeText}<br/><br/>{waitText}</h2>
          <div className={'form-wrapper'}>
            <div className={'grid-2'}>
              <FormGroup classes="" placeholder="First Name" type="text" value={this.state.first_name} onKeyUp={e => this.handleInputKeyUp(e)} onChange={e => this.handleInputChange(e, 'first_name')} />
              <FormGroup classes="" placeholder="Last Name" type="text" value={this.state.last_name} onKeyUp={e => this.handleInputKeyUp(e)} onChange={e => this.handleInputChange(e, 'last_name')} />
            </div>
            <FormGroup classes="" placeholder="Phone Number" type="text" value={this.state.phone_number} onKeyUp={e => this.handleInputKeyUp(e)} onChange={e => this.handleInputChange(e, 'phone_number')} />
          </div>
          <div className={'buttons'}>
            <Button disabled={!valid} classes={(valid ? 'btn-primary' : '')} onClick={e => this.handleNextClick(e)}>Next</Button>
          </div>
        </div>
        <div className={'powered-by-wrapper'}>
          <div>
            <div>Powered by</div>
            <img src={logo} alt="Studio 3 CRM" />
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({
  reviews: { show: { data: locationData, organicReviewSuccess } }
}) => ({
  locationData,
  organicReviewSuccess
});

const connected = connect(mapStateToProps, {
  fetchReviewLocation,
  updateOrganicReview
})(OrganicReviewsShow);

export { connected as OrganicReviewsShow };
