import React from "react";

const MiniLoader = (props) => {
    return (
        <div className={`spinner ${props.classes}`}>
        </div>
    )
}
MiniLoader.defaultProps = {
    classes: ''
}

export { MiniLoader };
