import React, { Component } from 'react'
import { Button } from "../../../common";

export default class ErrorBoundry extends Component {
    state = { hasError: false };
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-boundry-container">
                    <div className="error-boundry-content">
                        <h1>😱 Oops, something went wrong!</h1>
                        <Button onClick={() => window.location.reload()}>Try Again</Button>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}
