import React, { Component } from "react";
import GooglePlacesAutoComplete from "./GooglePlacesAutoComplete";
import { Confirmation } from "./Confirmation";
import { AutoCompletePopUp } from "./AutoCompletePopUp";
import { YelpSelection } from "./YelpSelection";
import  YelpTutorial  from "./YelpTutorial";

import axios from "axios";

const initialState = {
  isLoading: false,
  address: "",
  googlePlacesAddress: "",
  name: "",
  ltLng: null,
  placeId: null,
  yelpId: null,
  showInput: true
};

export default class YelpAndGoogleReviews extends Component {
  state = {
    isLoading: false,
    address: "",
    googlePlacesAddress: "",
    name: "",
    ltLng: null,
    placeId: null,
    showInput: true,
    googleReviewLink: "",
    yelpId: null,
    yelpBusinessOptions: [],
    yelpTutorialVisable: false,
  };

  fetchIndividualYelpBusinessOption = async (searchTerm, coords) => {
    return new Promise(async (resolve, reject) => {
      if (searchTerm && coords) {
        let url = `//lambda.studio3marketing.com/.netlify/functions/getYelp?searchTerm=${searchTerm}&lat=${coords.lat}&lng=${coords.lng}`;
        const parameters = { params: { null: null } };
        try {
          let response = await axios.get(url,
            Object.assign({}, parameters, {
              Accept: "application/json",
              "Content-Type": "application/json"
            })
          );
          resolve(((response.data || {}).data || {}).businesses || []);
        } catch (err) {
          reject(err);
        }
        return;
      }
      resolve({});
    });
  }

  fetchYelpBusinessOptions = async (searchTerm, coords) => {
    const combined = (await Promise.all([
      this.fetchIndividualYelpBusinessOption(searchTerm, coords),
      this.fetchIndividualYelpBusinessOption(this.state.googlePlacesAddress, coords)
    ]));
    let merged = [];
    for (let i = 0; i < combined.length; ++i) {
      for (let j = 0; j < combined[i].length; ++j) {
        const item = combined[i][j];
        let existing = merged.find(n => n.id === item.id);
        if (!existing) {
          merged.push(item);
        }

      }
    }
    this.setState({ yelpBusinessOptions: merged });
  };

  componentWillUnmount() {
    var scripts = document.getElementById("the_maps_script");
    scripts && scripts.remove(scripts);
  }

  showInput = () => {
    this.setState({ showInput: true });
  };

  confirmAddress = platformId => {
    if (platformId === "1") {
      let googleReviewLink = `https://search.google.com/local/writereview?placeid=${
        this.state.placeId
      }`;
      this.props.setYelpAndGoogleReview(googleReviewLink);
    } else {
      this.fetchYelpBusinessOptions(this.state.name, this.state.ltLng);
    }
  };

  resetState = () => {
    this.setState(initialState);
  };

  handleAddressSelection = (address, ltLng, placeId, isValid) => {
    if (isValid === true) {
      const name =
        address.substring(0, address.indexOf(":")) ||
        address.substring(0, address.indexOf(","));
      this.setState({
        address: address,
        ltLng: ltLng,
        placeId: placeId,
        name: name
      });
    } else {
      this.setState({ address: address });
    }
  };

  clearYelpBusiness = () => {
    this.setState({ yelpBusinessOptions: [] });
  };

  showHideTutorial = () => {
    this.setState({yelpTutorialVisable: !this.state.yelpTutorialVisable})
  }

  setYelpId = id => {
    let yelpLink = `https://www.yelp.com/writeareview/biz/${id}`
    this.props.setYelpAndGoogleReview(yelpLink)
    this.setState({ showInput: false });
  };

  handleGooglePlacesAutoCompleteChange = (address) => {
    this.setState({
      googlePlacesAddress: address
    });
  }

  render() {
    if (this.state.yelpBusinessOptions.length > 0) {
      return (
        <YelpSelection
          clearYelpBusiness={this.clearYelpBusiness}
          setYelpId={this.setYelpId}
          yelpData={this.state.yelpBusinessOptions}
          setYelpAndGoogleReview={this.props.setYelpAndGoogleReview}
        />
      );
    }
    let snippet = `To add ${
      this.props.platformId === "3" ? "Yelp" : "Google"
    } reviews, select your business from the dropdown
    below.`;
    return (
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1 z-index-2 yelp-google-container">
          { 
               this.props.platformId === "3" && <button className="btn yelp-manual-connect" onClick={() => this.showHideTutorial()}>Trouble connecting Yelp?</button>
          }
          <div className="autocomplete-container">
            {this.state.showInput && (
              <p className="reviews-font-headline">{snippet}</p>
            )}
            <GooglePlacesAutoComplete
              showInput={this.state.showInput}
              onChange={this.handleGooglePlacesAutoCompleteChange}
              handleAddressSelection={this.handleAddressSelection}
              apiLoaded={true}
            />
            <Confirmation
              resetState={this.resetState}
              confirmAddress={this.confirmAddress}
              address={this.state.address}
              yelpBusinessOptions={this.state.yelpBusinessOptions}
              platformId={this.props.platformId}
            />
            <AutoCompletePopUp address={this.state.address} />
          </div>
        </div>
        <YelpTutorial visable={this.state.yelpTutorialVisable} setYelpId={this.setYelpId} showHideTutorial={this.showHideTutorial}/>
      </div>
    );
  }
}
