import React, {Component} from 'react';
import { NavLink } from "react-router-dom";

export default class Hero extends Component {
  render(){
    return (
      <div className="pricing-table">
        <div className="top">
          <p className="price">$75<span>/mo</span></p>
        </div>
        <div className="bottom">
          <ul>
            <li><i className="fa fa-check-circle"></i> Unlimited Reviews</li>
            <li><i className="fa fa-check-circle"></i> Unlimited Business Profiles</li>
            <li><i className="fa fa-check-circle"></i> 7-day free trial, cancel anytime</li>
          </ul>
          <NavLink className="btn" to="/register">{this.props.btnLabel}</NavLink>
        </div>
      </div>
    )
  }
}