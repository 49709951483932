import React, { Component } from "react";

import moment from "moment";
import { connect } from "react-redux";

import { Toggle } from "../../../common";

import ContentWithHeader from "../../../containers/ContentWithHeader";
import WidgetActionsBar from "../../reviews/components/WidgetActionsBar";

import {
  fetchLocation,
  fetchLocationReviews,
  featureReview,
  fetchFeaturedReviews,
  changeFeaturedReviewsPosition,
} from "../../../screens/locations/redux/actions";
import PreviewWidgetContainer from "../../reviews/components/PreviewWidgetContainer";
import ReviewWidgetContainer from "../../reviews/components/ReviewWidgetContainer";
import CodeSnippet from "../../reviews/components/CodeSnippet";

import { startDateMMYYYY, endDateMMYYYY } from "../../../lib/dateHelpers";

class LocationsWidgetAdmin extends Component {
  state = {
    isFetchingLocation: true,
    isFetchingReviews: true,
    addingOrRemovingWidgetFromFeatured: null,
    startMonth: moment().subtract(1, "months").format("MM"),
    startYear: moment().format("YYYY"),
    endMonth: moment().subtract(1, "months").format("MM"),
    endYear: moment().format("YYYY"),
    showEmptyReviews: false,
    filterByRating: null,
    contentBeingShown: "customWidgetAdmin",
  };

  triggerWidgetAnimation = (id) => {
    if (this.state.addingOrRemovingWidgetFromFeatured !== null) {
      this.setState({ addingOrRemovingWidgetFromFeatured: null });
    } else {
      this.setState({ addingOrRemovingWidgetFromFeatured: id });
    }
  };

  liftAndSetCalendarState = (state) => {
    let startMonth = moment(state.from).format("MM");
    let startYear = moment(state.from).format("YYYY");
    let endMonth = moment(state.to).format("MM");
    let endYear = moment(state.to).format("YYYY");
    this.setState({ startMonth, startYear, endMonth, endYear }, () => {
      this.setState({ isFetchingReviews: true });
      this.getLocationReviews();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startMonth !== this.state.startMonth &&
      prevState.endMonth !== this.state.endMonth
    ) {
      this.getLocationReviews();
    }
  }

  switchContentView = (val) => {
    this.setState({
      contentBeingShown: val,
    });
  };

  getLocationReviews = () => {
    if (
      this.state.startMonth === "Invalid date" ||
      this.state.endMonth === "Invalid date"
    ) {
      return null;
    } else if (
      this.state.startYear === "Invalid date" ||
      this.state.endYear === "Invalid date"
    ) {
      return null;
    } else {
      this.props.fetchLocationReviews(
        this.props.match.params.location_id,
        {
          startDate: startDateMMYYYY(
            this.state.startMonth,
            this.state.startYear
          ),
          endDate: endDateMMYYYY(this.state.endMonth, this.state.endYear),
        },
        () => this.setState({ isFetchingReviews: false })
      );
    }
  };

  componentDidMount() {
    this.props.fetchLocation(this.props.match.params.location_id, () =>
      this.setState({ isFetchingLocation: false })
    );
    this.getLocationReviews();
  }

  filterReviews = (e) => {
    this.setState({ showEmptyReviews: !this.state.showEmptyReviews });
  };

  filterByRating = (id) => {
    this.setState({ filterByRating: id });
  };

  manipulateReviewsShowBasedOnFilters = () => {
    let data = [];
    this.props.locationReviews.length !== 0 &&
      (data = this.props.locationReviews.filter((item) => {
        if (this.state.showEmptyReviews === true) {
          if (this.state.filterByRating !== null) {
            if (item.rating === this.state.filterByRating) {
              return item;
            }
          } else {
            return item;
          }
        } else {
          if (item.rating === this.state.filterByRating) {
            if (item.review !== null) {
              if (item.review.length !== 0) {
                return item.rating === this.state.filterByRating;
              }
            }
          }
          if (this.state.filterByRating === null) {
            if (item.review !== null) {
              return item.review.length !== 0;
            }
          }
        }
      }));
    return data;
  };
  render() {
    let parentContext = this;
    const data = this.manipulateReviewsShowBasedOnFilters();
    let dates = {
      startDate: startDateMMYYYY(this.state.startMonth, this.state.startYear),
      endDate: endDateMMYYYY(this.state.endMonth, this.state.endYear),
    };
    let tabShown = this.state.contentBeingShown;
    return (
      <div className="row">
        <div className="col-12">
          <ul id="nav-no-padding" className="settings-nav">
            <li>
              {" "}
              <a
                className={`pointer ${
                  tabShown === "customWidgetAdmin" && "active"
                }`}
                onClick={() => this.switchContentView("customWidgetAdmin")}
              >
                Widget
              </a>
            </li>
            <li>
              <a
                className={`pointer ${
                  tabShown === "customWidgetScript" && "active"
                }`}
                onClick={() => this.switchContentView("customWidgetScript")}
              >
                Website Embed
              </a>
            </li>
          </ul>
        </div>
        {this.state.contentBeingShown === "customWidgetScript" ? (
          <ContentWithHeader
            renderMainTitleSpace={""}
            addedClasses={""}
            subTitleClasses={""}
            contentSpaceClasses={"code-area-container row"}
            renderContent={() => (
              <CodeSnippet
                locationName={this.props.locationData.name}
                locationEmail={this.props.locationData.email}
                locId={this.props.locationData.id}
              />
            )}
            onClick={() => {}}
          />
        ) : (
          <div className="col-12">
            <ContentWithHeader
              renderMainTitleSpace={""}
              addedClasses={""}
              subTitleClasses={""}
              contentSpaceClasses={"slider-width"}
              renderContent={() => (
                <PreviewWidgetContainer
                  isLoading={this.state.isFetchingReviews}
                  addingOrRemovingWidgetFromFeatured={
                    this.state.addingOrRemovingWidgetFromFeatured
                  }
                  triggerWidgetAnimation={this.triggerWidgetAnimation}
                  dates={dates}
                  parentContext={parentContext}
                  featureReview={this.props.featureReview}
                  locationId={this.props.locationData.id}
                  fetchFeaturedReviews={this.props.fetchFeaturedReviews}
                  featuredReviews={this.props.featuredReviews}
                  changeFeaturedReviewsPosition={
                    this.props.changeFeaturedReviewsPosition
                  }
                />
              )}
              onClick={() => {}}
            />
            <ContentWithHeader
              renderMainTitleSpace={"Add Reviews to Widget"}
              addedClasses={""}
              subTitleClasses={""}
              contentSpaceClasses={"widget-content"}
              renderSubTitleSpace={() => (
                <WidgetActionsBar
                  filterByRating={this.filterByRating}
                  liftAndSetCalendarState={this.liftAndSetCalendarState}
                />
              )}
              renderSubtitleAction={() => (
                <Toggle
                  toggle={parentContext.state.showEmptyReviews}
                  onChange={parentContext.filterReviews}
                  label={"Show empty reviews"}
                />
              )}
              renderContent={() => (
                <ReviewWidgetContainer
                  isLoading={this.state.isFetchingReviews}
                  data={data}
                  dates={dates}
                  featureReview={this.props.featureReview}
                  noArrows={true}
                  addingOrRemovingWidgetFromFeatured={
                    this.state.addingOrRemovingWidgetFromFeatured
                  }
                  triggerWidgetAnimation={this.triggerWidgetAnimation}
                />
              )}
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  locations: {
    show: { data: locationData },
    reviews: { data: locationReviews },
    featuredReviews: { data: featuredReviews },
  },
}) => ({
  locationData,
  locationReviews,
  featuredReviews,
});

const connected = connect(mapStateToProps, {
  fetchLocation,
  fetchLocationReviews,
  featureReview,
  fetchFeaturedReviews,
  changeFeaturedReviewsPosition,
})(LocationsWidgetAdmin);

export { connected as LocationsWidgetAdmin };
