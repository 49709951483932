import React, { Component } from "react";
import { FormGroup, Button, SelectField } from "../../../common";
import { TextCount } from "./TextCount";
import { insertAtCursor } from "../../../lib/textHelpers";

export default class CustomMessage extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  handleTemplateSelection(option) {
    if (this.wrapper.current) {
      let input = this.wrapper.current.querySelector('textarea');
      if (input) {
        input.focus();
        insertAtCursor(input, option.key);
      }
    }
  }

  render() {

    const options = [
      {key: '{{first_name}}', value: 'First Name'},
      {key: '{{last_name}}', value: 'Last Name'},
      {key: '{{full_name}}', value: 'Full Name'},
    ]

    return (
      <div className="custom-message-container" ref={this.wrapper}>
        <div className={'custom-message-header'}>
          <p>Custom Message (All Requests Above Will Use This Message)</p>
          <SelectField placeholder={'Insert Placeholder'} options={options} onSelection={data => this.handleTemplateSelection(data)}/>
        </div>
        <FormGroup
          classes="custom-input"
          classesTextArea={"input-mod"}
          type="textarea"
          value={this.props.customMessage}
          onChange={e => this.props.handleMessageChange(e, "customMessage")}
        />
        <TextCount message={this.props.customMessage} showTextCount={true} />
      </div>
    );
  }
}

CustomMessage.defaultProps = {
  customMessage: ""
};
