import { store } from "../store";
import { authLogoutAndRedirect } from "../screens/users/actions";

export function getToken() {
  return localStorage.getItem("token");
}

export function checkHTTP(response) {
  if (response.status >= 200 && response.status <= 400) {
    return response;
  } else if (response.status >= 500) {
    response.error = "Something went wrong.";
    return response;
  } else {
    localStorage.setItem(
      "redirectPath",
      store.getState().router.location.pathname,
    );
    store.dispatch(authLogoutAndRedirect());
  }
}
export const requestHeaders = token => ({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
});
