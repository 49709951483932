import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

import { authRegistrationSuccessRedirect } from "../actions/authenticate_actions";
import { Elements } from 'react-stripe-elements';
import { registerUser } from "../actions";
import { updatePaymentError } from "../actions/stripe_actions";
import { NavLink } from "react-router-dom";

import RegistrationForm from "./RegistrationForm";

class Registration extends Component {

    componentDidUpdate(prevProps, prevState) {
        const { token } = this.props.user.authentication.data;
        (token !== null && prevProps.user.authentication.data.token !== token) && this.props.authRegistrationSuccessRedirect(token)
    }

    render() {
        return (
            this.props.user.authentication.data.token === null ?
                <div className="registrationContainer">
                    <Elements>
                        <RegistrationForm
                            registerUser={this.props.registerUser}
                            updatePaymentError={this.props.updatePaymentError}
                            paymentErrorMessage={this.props.user.paymentError}
                        />
                    </Elements>
                </div>
                : <div>
                    <div className="visitPagesContainer">
                        <NavLink className="visitPageButtons pointer btn bg-white" to="/locations">My Locations</NavLink>
                        <NavLink className="visitPageButtons pointer btn bg-white" to="/account">My Account</NavLink>
                    </div>
                </div>
        )
    }
}

// Registration.propTypes = {
//     registerUser: PropTypes.objfuncect,
//     authLoginAndRedirect: PropTypes.func,
//     updatePaymentError: PropTypes.func
// }

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = {
    registerUser,
    authRegistrationSuccessRedirect,
    updatePaymentError,
};
const connected = connect(mapStateToProps, mapDispatchToProps)(Registration);

export { connected as Registration };
