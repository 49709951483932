import jwtDecode from "jwt-decode";
import { createReducer } from "../../lib/utilities";

const initialState = {
  index: {
    data: []
  },
  account: {
    data: {
      role: {},
      stripe: {
        sources: {
          data: []
        },
        subscriptions: {
          data: []
        }
      }
    }
  },
  new: {
    data: {}
  },
  activation: {
    data: {}
  },
  authentication: {
    data: {
      first_name: "",
      last_name: "",
      role: {
        id: null,
      },
      token: null
    }
  },
  reset: {
    success: false
  },
  paymentError: "",
};

export default createReducer(initialState, {
  UPDATE_PAYMENT_ERROR_NOTIFICATION: (state, payload) => ({
    ...state,
    paymentError: payload
  }),
  REGISTER_USER_FAIL: (state, payload) => ({
    ...state,
    paymentError: payload
  }),
  UPDATE_PAYMENT_SUCCESS: (state, payload) => ({
    ...state,
    new: {
      ...state.new,
      data: payload
    }
  }),
  FETCHING_USERS_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload
    }
  }),
  FETCHING_USER_SUCCESS: (state, payload) => ({
    ...state,
    account: {
      ...state.index,
      data: payload.data
    }
  }),
  UPDATING_USER_PROFILE_SUCCESS: (state, payload) => ({
    ...state,
    account: {
      ...state.index,
      data: payload.data
    }
  }),
  CANCELLING_USER_SUBSCRIPTION_SUCCESS: (state, payload) => ({
    ...state,
    account: {
      ...state.index,
      data: payload.data
    }
  }),
  UPDATING_USER_PAYMENT_SUCCESS: (state, payload) => ({
    ...state,
    account: {
      ...state.index,
      data: payload.data
    }
  }),
  CREATING_USER_SUCCESS: state => ({
    ...state,
    new: {
      ...state.new,
      data: {
        message: "An invitation has been sent"
      }
    }
  }),
  CREATING_PASSWORD_SUCCESS: state => ({
    ...state,
    activation: {
      ...state.activation,
    }
  }),
  RESETTING_PASSWORD_SUCCESS: state => ({
    ...state,
    reset: {
      ...state.reset,
      success: true
    }
  }),
  REGISTER_USER_SUCCESS: (state, payload) => {
    localStorage.setItem("token", payload.data.token);
    return {
      ...state,
      authentication: {
        ...state.authentication,
        data: {
          ...payload.data,
          role: {
            id: payload.data.id
          }
        }
      }
    };
  },
  AUTHENTICATING_USER_SUCCESS: (state, payload) => {
    localStorage.setItem("token", payload.data.token);
    return {
      ...state,
      authentication: {
        ...state.authentication,
        data: payload.data
      }
    };
  },
  AUTHENTICATING_USER_FAIL: (state, payload) => ({
    ...state,
    authentication: {
      ...state.authentication,
      errors: payload
    }
  }),
  AUTHENTICATION_REDIRECT: (state, payload) => {
    let jwt = jwtDecode(payload);

    return {
      ...state,
      authentication: {
        ...state.authentication,
        data: {
          ...state.authentication.data,
          token: payload,
          first_name: jwt.first_name,
          last_name: jwt.last_name,
          user_id: jwt.id,
          current_period_end: jwt.current_period_end,
          role: {
            id: parseInt(jwt.role_id, 10)
          }
        }
      }
    }
  },
  LOG_OUT: () => initialState
});
