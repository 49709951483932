import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReviewInputInvalid from "./ReviewInputInvalid";
import { Button } from "../../../common";


export default class CSVData extends Component {

    static propTypes = {
        resetState: PropTypes.func,
        incorrectDataFormatError: PropTypes.bool,
        fileData: PropTypes.array,
        handleCreateReview: PropTypes.func,
        resetAlert: PropTypes.func,
        saveReview: PropTypes.func,
        removeReview: PropTypes.func,
    }

    state = {
        addOrEdit: false,
    }

    renderBatchValid = () => {
        let validCount = 0;
        let invalidCount = 0;
        this.props.fileData.forEach(item => {
            item.valid === true && (validCount += 1)
            item.valid === false && (invalidCount += 1)
        });
        return (
            <div className="entries-count-container">
                {
                    validCount !== 0 && (
                        <div className="entries">
                            <p>{validCount} entries added successfully.</p>
                            <Button classes={"mt-2 mb-2"} disabled={this.props.readyForSubmit} onClick={this.props.handleCreateReview}>
                                {
                                    this.props.fileData.length <= 1 ? <span>Request Review</span> : <span>Request Reviews</span>
                                }
                            </Button>
                        </div>
                    )
                }
                {
                    invalidCount !== 0 && (
                        <div className="entries failed-bg">
                            <p>Unable to add {invalidCount} entries. Please review below.</p>
                        </div>
                    )
                }
            </div>
        )
    }

    renderBatchInvalid = () => {
        return this.props.fileData.map(item =>
            item.valid === false &&
            <ReviewInputInvalid
                key={item.phone_number + Math.random()}
                handleInputChange={this.props.handleInputChange}
                firstName={item.first_name}
                lastName={item.last_name}
                phoneNumber={item.phone_number}
                removeReview={this.props.removeReview}
                action={this.props.removeReview}
                resetAlert={this.props.resetAlert}
                valid={item.valid}
                id={item.id}
                editing={item.editing}
                saveAndClearStateOnEdit={this.props.saveAndClearStateOnEdit}
            />
        )
    }

    render() {
        return (
            <div className="row">
                {
                    this.props.incorrectDataFormatError === true ?
                        <div className="csv-format-error-container">
                            <h1>Incorrect CSV File Format. Please check your CSV file</h1>
                            <p className="csv-text-not-big">
                                CSV files must have the following column names: <span>first_name</span>, <span>last_name</span>, <span>phone_number</span>
                            </p>
                            <p>Example:</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="column-name" >first_name</td>
                                        <td className="column-name" >last_name</td>
                                        <td className="column-name" >phone_number</td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Smith</td>
                                        <td>1234567890</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button onClick={this.props.resetState} classes="test-button mt-4">Retry</Button>
                        </div>
                        : null
                }
                {
                    this.props.incorrectDataFormatError === false ? <div className="review-request-container">
                        <div className="csv-data-container">
                            {
                                this.renderBatchValid()
                            }
                            <div className="inputs-container">
                                {
                                    this.renderBatchInvalid()
                                }
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}
