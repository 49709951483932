import React, { Component, Fragment } from "react";
import propTypes from "prop-types";
import _ from "lodash";

const Item = props => {
  return (
    <span
      key={props.id}
      className={
        props.highlightId === props.id
          ? "highlight dropdown-selection"
          : "dropdown-selection"
      }
      onMouseOver={e => {
        props.highLight(e, props.id);
      }}
      onClick={e => {
        props.onDropDownItemClick(e, props);
      }}
    >
      {props.data[props.secondaryAccessor]}
    </span>
  );
};

const DropdownContent = props => {
  if (props.data) {
    if (props.data.length > 0) {
      let dataForRender = [];
      if (props.inputValue) {
        if (props.inputValue.length > 1) {
          // dataForRender = props.data.filter(
          //   item => item[props.secondaryAccessor] == props.inputValue
          // );
          dataForRender = props.data.filter(item => {
              return item[props.secondaryAccessor].toLowerCase().includes(props.inputValue.toLowerCase())
          });
          return (
            <div className={`dropdown-list`}>
              {dataForRender.map((item, i) => props.render(item, i))}
            </div>
          );
        }
      }
      return (
        <div className={`dropdown-list`}>
          {props.data.map((item, i) => props.render(item, i))}
        </div>
      );
    }
  }
  return null;
};

class ReuseableDropdown extends Component {
  state = {
    highlightId: "",
    defaultValue: "",
    optionsVisible: false,
    inputValue: ""
  };

  showHideDropdown = e => {
    this.setState({ optionsVisible: !this.state.optionsVisible });
  };

  highLight = (e, id) => {
    if (this.state.highlightId !== id) {
      this.setState({ highlightId: id });
    }
  };

  _handleKeyDown = (e, data, id) => {
    if (e.key === "Enter") {
      if (data) {
        if (data.length > 0) {
          // let selected = data.filter(
          //   item => item[this.props.accessor] == this.state.inputValue
          // );
          let selected = data.filter(item => {
            return item[this.props.secondaryAccessor].toLowerCase().includes(this.state.inputValue.toLowerCase())
          });
          this.onDropDownItemClick(e, { data: { ...selected[0] } });
        }
      }
    }
  };

  onDropDownItemClick = (e, item) => {
    this.setState({ inputValue: item.data[this.props.accessor] }, () => {
      this.setState({ optionsVisible: false });
      this.props.onSelect(item.data[this.props.accessor]);
    });
  };

  addHighlightID = (data, i) => {
    data.forEach(item => {
      item._highlightID = i;
    });
  };

  handleInputChange = e => {
    let value = e.target.value;
    if (value.length > 0) {
      let newValue = value.toUpperCase();
      if(this.state.optionsVisible === false){
        this.setState({ inputValue: newValue, optionsVisible: true });
      }
      this.setState({ inputValue: newValue });
    } else {
      this.setState({ inputValue: e.target.value });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      if (this.props.data.length > 0) {
        this.addHighlightID(this.props.data);
      }
    }
  }

  render() {
    let data = this.props.data;
    return (
      <div className={`${this.props.customContainerClass}`}>
        <label>{this.props.label}</label>
        <div
          className={`${
            this.props.customInputContainerClass
          } reusable-dropdown-container`}
          onClick={this.showHideDropdown}
        >
          <div className="selected">
            <input
              type="text"
              placeholder={this.props.defaultValue}
              className={`searchbar-dropdown ${this.props.customInputClass}`}
              onKeyDown={e =>
                this._handleKeyDown(e, data, this.state.highlightId)
              }
              // style={this.props.styles}
              value={this.state.inputValue}
              onChange={e => this.handleInputChange(e)}
            />
            <span>
              <i className="fa fa-angle-down" />
            </span>
          </div>
          {this.state.optionsVisible ? (
            <DropdownContent
              render={(data, i) => (
                <Item
                  key={Math.random()}
                  data={data}
                  id={i}
                  highlightId={this.state.highlightId}
                  highLight={this.highLight}
                  onDropDownItemClick={this.onDropDownItemClick}
                  accessor={this.props.accessor}
                  secondaryAccessor={this.props.secondaryAccessor}
                />
              )}
              data={data}
              accessor={this.props.accessor}
              inputValue={this.state.inputValue}
              secondaryAccessor={this.props.secondaryAccessor}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export { ReuseableDropdown };

ReuseableDropdown.propTypes = {
  data: propTypes.array,
  customInputContainerClass: propTypes.string,
  defaultValue: propTypes.string,
  accessor: propTypes.string
};

ReuseableDropdown.defaultProps = {
  data: [],
  defaultValue: "",
  accessor: "name",
  customContainerClass: "",
  customInputContainerClass: "",
  customInputClass: "",
  label: ""
};
