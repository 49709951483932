import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export const formatDateObject = date => {
  let dateCopy = { ...date };
  return moment(dateCopy, moment.ISO_8601).format(DATE_FORMAT);
};

export const getTwelveMonthsAgo = dateString => {
  return moment(dateString, DATE_FORMAT)
    .subtract(1, "years")
    .startOf("month")
    .format(DATE_FORMAT);
};

export const getPreviousMonthStart = dateString => {
  return moment(dateString, moment.ISO_8601)
    .subtract(1, "months")
    .format(DATE_FORMAT);
};

export const getPreviousMonthEnd = dateString => {
  return moment(dateString, moment.ISO_8601)
    .subtract(1, "months")
    .endOf("month")
    .format(DATE_FORMAT);
};

export const startDateMMYYYY = (month, year) => {
  const date = moment(`${month}-${year}`, "MM-YYYY").startOf("month");
  return formatDateObject(date);
};

export const endDateMMYYYY = (month, year) => {
  const date = moment(`${month}-${year}`, "MM-YYYY").endOf("month");
  return formatDateObject(date);
};

export const getMonthFromDate = date =>
  moment(date, "YYYY-MM-DD")
    .startOf("month")
    .format("MMMM");

export const getYearFromDate = date =>
  moment(date, "YYYY-MM-DD")
    .startOf("month")
    .format("YYYY");

const getStartOfMonth = moment().startOf("month");
const getEndOfMonth = moment().endOf("month");
export const startOfMonth = formatDateObject(getStartOfMonth);
export const endOfMonth = formatDateObject(getEndOfMonth);
