import { createReducer } from "../../../lib/utilities";

export const initialState = {
  index: {
    data: []
  },
  show: {
    data: {
      id: null,
      reviews: [],
      platforms: [],
      settings: {
        default_message: "",
      },
      tokens: [],
      yelp_integration: null
    }
  },
  reviews: {
    data: []
  },
  featuredReviews: {
    data: []
  },
  platforms: {
    data: []
  },
  integrations: {
    yelp: {
      search: [],
      data: {}
    }
  }
};

export default createReducer(initialState, {
  AUTO_FEATURE_FIVE_STAR_REVIEWS_SUCCESS: (state, payload) => {
    return ({
      ...state,
      show: {
        ...state.show,
        data: {
          ...state.show.data,
          settings: {
            ...state.show.data.settings,
            auto_add_reviews: payload.data
          }
        }
      }
    })
  },
  CHANGE_FEATURED_REVIEWS_POSITION_SUCCESS: (state, payload) => {
    return ({
      ...state,
      featuredReviews: {
        data: payload.data
      },
    })
  },
  FETCH_FEATURED_REVIEWS_SUCCESS: (state, payload) => {
    return ({
      ...state,
      featuredReviews: {
        data: payload.data
      },
    })
  },
  FEATURE_REVIEW_SUCCESS: (state, payload) => {
    return ({
      ...state,
      reviews: {
        data: payload.data[1]
      },
      featuredReviews: {
        data: payload.data[0]
      },
    })
  },
  FETCHING_LOCATIONS_SUCCESS: (state, payload) => ({
    ...state,
    index: {
      ...state.index,
      data: payload.data
    }
  }),
  FETCHING_LOCATION_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: payload.data
    }
  }),
  FETCHING_LOCATION_REVIEWS_SUCCESS: (state, payload) => ({
    ...state,
    reviews: {
      ...state.reviews,
      data: payload.data
    }
  }),
  FETCHING_PLATFORMS_SUCCESS: (state, payload) => ({
    ...state,
    platforms: {
      ...state.show,
      data: payload.data
    }
  }),
  UPDATING_LOCATION_PLATFORMS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        platforms: payload.data
      }
    }
  }),

  UPDATING_LOCATION_SETTINGS_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        settings: payload.data
      }
    }
  }),
  DELETING_LOCATION_PLATFORM_SUCCESS: (state, payload) => ({
    ...state,
    show: {
      ...state.show,
      data: {
        ...state.show.data,
        platforms: payload.data
      }
    }
  }),
  SEARCHING_YELP_SUCCESS: (state, payload) => ({
    ...state,
    integrations: {
      ...state.integrations,
      yelp: {
        ...state.integrations.yelp,
        search: payload.data
      }
    }
  }),
  FETCHING_YELP_SUCCESS: (state, payload) => ({
    ...state,
    integrations: {
      ...state.integrations,
      yelp: {
        ...state.integrations.yelp,
        data: payload.data
      }
    }
  }),
  CREATING_LOCATION_TOKEN_SUCCESS: (state, payload) => {
    return ({
      ...state,
      show: {
        ...state.show,
        data: {
          ...state.show.data,
          tokens: [...state.show.data.tokens, payload.data]
        }
      }
    })
  },
  DELETING_LOCATION_TOKEN_SUCCESS: (state, payload) => {
    return ({
      ...state,
      show: {
        ...state.show,
        data: {
          ...state.show.data,
          tokens: payload.data
        }
      }
    })
  },
});
