import React, { Component, Fragment } from "react";
import { FormGroup, Button, MiniLoader, SelectField } from "../../../common";
import { TextCount } from "../../reviews/components/TextCount";
import { insertAtCursor } from "../../../lib/textHelpers";

export default class DefaultMessage extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  handleTemplateSelection(option) {
    if (this.wrapper.current) {
      let input = this.wrapper.current.querySelector('textarea');
      if (input) {
        input.focus();
        insertAtCursor(input, option.key);
      }
    }
  }

  render() {
    const options = [
      {key: '{{first_name}}', value: 'First Name'},
      {key: '{{last_name}}', value: 'Last Name'},
      {key: '{{full_name}}', value: 'Full Name'},
    ]

    if (this.props.loading) {
      return (
        <div className="default-message-loader-container">
          <MiniLoader />
        </div>
      );
    }
    return (
      <Fragment>
        <div ref={this.wrapper}>
          <div className={'default-message-header'}>
            <h3>Default Message</h3>
            <SelectField placeholder={'Insert Placeholder'} options={options} onSelection={data => this.handleTemplateSelection(data)}/>
          </div>
          <FormGroup
            classes=""
            type="textarea"
            value={this.props.defaultMessage}
            onChange={e => this.props.handleInputChange(e, "default_message")}
          />
          <TextCount message={this.props.defaultMessage} />
          <Button onClick={e => this.props.handleUpdateSettings(e)}>
            Update Message
          </Button>
        </div>
      </Fragment>
    );
  }
}

DefaultMessage.defaultProps = {
  defaultMessage: "",
  loading: false,
  handleInputChange: () => {},
  handleUpdateSettings: () => {}
};
