import React, { Component } from 'react'

export default class CSVDescription extends Component {
    render() {
        return (
            <div className="request-review-info-container">
                <div className="csv-input-container">
                    <div className="file-upload">
                        <h4>Need to send more? Upload a CSV.</h4>
                        <div className="file-select">
                            <i className="fa fa-cloud-upload-alt"></i>
                            <div className="file-select-button" id="fileName">Drag your file here or click to browse</div>
                            <div className="file-select-name" id="noFile">{this.props.fileName}</div>
                            <input type="file" onChange={this.props.handleFile} name="fileData" id="chooseFile" />
                        </div>
                    </div>
                </div>
                <div className="sample-csv">
                    <p>Sample CSV</p>
                    <table>
                        <tbody>
                            <tr>
                                <td className="column-name" >first_name</td>
                                <td className="column-name" >last_name</td>
                                <td className="column-name" >phone_number</td>
                            </tr>
                            <tr>
                                <td>James</td>
                                <td>Smith</td>
                                <td>(888) 777-9999</td>
                            </tr>

                            <tr>
                                <td>Tyler</td>
                                <td>Waston</td>
                                <td>(888) 444-3333</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
