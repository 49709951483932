import { push } from 'connected-react-router'
import axios from "axios";
import { apiBase } from "../../../lib/api";
import { dispatchHelper } from "../../../lib/actionHelpers";
import { fetchLocations } from "../../locations/redux/actions"
import { store } from "../../../store";
import { AUTHENTICATING_USER, RESETTING_PASSWORD } from "./types";

export const authenticateUser = params => async dispatch => {
  const dispatching = dispatchHelper(AUTHENTICATING_USER);
  const url = `${apiBase}/auth/login`;
  try {
    let response = await axios.post(url, params);
    dispatching(response, () => {
        try {
          let fetch = fetchLocations(()=> {
          let state = store.getState();
          let locations = state.locations.index.data;
          if(locations){
            if(locations.length === 1){
              let locationId = locations[0].id;
              dispatch(push(`/locations/${locationId}`))
            } else {
              dispatch(push("/locations"))
            }
          } else {
            dispatch(push("/locations"))
          }
         });
         fetch();
        }
        catch(err){
          dispatch(push("/locations"))
        }
      dispatch(push("/locations"))
  });
  } catch (err) {
    let response = err.response;
    dispatching(response);
  }
};
export const resetPassword = (params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(RESETTING_PASSWORD);
  const url = `${apiBase}/auth/reset`;
  const response = await axios.post(url, params);
  try {
    dispatching(response, callback);
  } catch (err) {
    let response = err.response;
    dispatching(response);
  }
};

export const authLogoutAndRedirect = () => dispatch => {
  localStorage.removeItem("token");
  dispatch({ type: "LOG_OUT" });
  dispatch(push("/login"));
};

export const authLoginAndRedirect = token => dispatch => {
  localStorage.setItem("token", token);
  dispatch({ type: "AUTHENTICATION_REDIRECT", payload: token });
  dispatch(push(window.location.pathname));
};


export const authRegistrationSuccessRedirect = token => dispatch => {
  dispatch({ type: "AUTHENTICATION_REDIRECT", payload: token });
  dispatch(push("/locations"));
}