import axios from "axios";
import { rootUrl } from "../../../../lib/api";
import { requestHeaders, getToken } from "../../../../lib/authHelpers";
import { dispatchHelper } from "../../../../lib/actionHelpers";
import { authLogoutAndRedirect } from "../../../users/actions";

import {
  CREATING_REVIEW,
  FETCHING_REVIEW,
  UPDATING_REVIEW,
  RESENDING_REVIEW,
  FETCHING_REVIEW_LOCATION,
  UPDATING_ORGANIC_REVIEW,
} from '../types';

export const createReview = (params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(CREATING_REVIEW, dispatch);
  const token = getToken();
  const url = `${rootUrl}/reviews/sms`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};


export const fetchReview = (review_hash, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_REVIEW, dispatch);
  const token = getToken();
  const url = `${rootUrl}/public/reviews/${review_hash}`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchReviewLocation = (location_hash, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_REVIEW_LOCATION, dispatch);
  const token = getToken();
  const url = `${rootUrl}/public/location/${location_hash}/new-customer`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const updateReview = (review_hash, params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(UPDATING_REVIEW, dispatch);
  const token = getToken();
  const url = `${rootUrl}/public/reviews/${review_hash}`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};

export const updateOrganicReview = (location_hash, params, callback = () => { }) => async dispatch => {
  const dispatching = dispatchHelper(UPDATING_ORGANIC_REVIEW, dispatch);
  const token = getToken();
  const url = `${rootUrl}/public/location/${location_hash}/review`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  const response = await axios.post(url, postParams, headers);
  dispatching(response, callback);
};


export const resendReview = (reviewId, callback = () => { }) => async dispatch => {
  const url = `${rootUrl}/reviews/${reviewId}/resend`;
  const dispatching = dispatchHelper(RESENDING_REVIEW, dispatch);
  const response = await axios.post(url, JSON.stringify({}), requestHeaders(getToken()));
  dispatching(response, callback);
}