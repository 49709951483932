import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from "../../locations/components/DatePicker";
import { Dropdown } from "../../../common";

export default class WidgetActionsBar extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }
    render() {
        return (
            <div>
                <div className="widget-actions">
                    <DatePicker liftAndSetCalendarState={this.props.liftAndSetCalendarState} />
                    <Dropdown filterByRating={this.props.filterByRating} />
                </div>
            </div>
        )
    }
}
