import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchLocation,
  fetchPlatforms,
  updatePlatforms,
  updateLocationSettings,
  deleteLocationPlatform,
  createLocationToken,
  deleteLocationToken,
  autoFeatureFiveStarReviews,
} from "../../../screens/locations/redux/actions";

import {
  ViewWithLoader,
  FormGroup,
  Button,
  FormGroupSelect,
  Toggle,
  MiniLoader,
  TagInput,
} from "../../../common";

import { AdvancedSettings } from "../components/AdvancedSettings";
import DefaultMessage from "../components/DefaultMessage";

import YelpAndGoogleReviews from "../../reviews/components/YelpAndGoogleReviews";
import { QRCode } from "react-qrcode-logo";

class LocationsEdit extends Component {
  state = {
    isFetching: true,
    isFetchingPlatforms: true,
    platforms: [],
    default_message: "",
    new_platform_id: null,
    new_platform_link: "",
    featureReview: false,
    updateQueue: [],
    deletionQueue: [],
    notificationEmail: [],
    defaultMessageLoader: false,
  };

  componentDidMount() {
    this.props.fetchLocation(this.props.match.params.location_id, () => {
      this.props.fetchPlatforms(() => {
        this.setState({
          isFetchingPlatforms: false,
        });
      });
      this.setState({
        isFetching: false,
        platforms: this.props.locationData.platforms,
        notificationEmail: this.props.locationData.settings.notification_email,
        default_message: this.props.locationData.settings.default_message,
      });
    });
  }

  handleInputChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  handlePlatformChange = (e, i) => {
    let platforms = this.props.locationData.platforms.slice();
    platforms[i].pivot.link = e.target.value;
    this.setState({
      platforms: platforms,
    });
  };

  handlePlatformActiveChange = (e, platform) => {
    e.preventDefault();
    const active = !platform.pivot.active;
    platform.pivot.active = active;
    const newPlatform = {
      ...platform,
      pivot: {
        ...platform.pivot,
        active,
      },
    };
    this.props.updatePlatforms(
      this.props.match.params.location_id,
      newPlatform,
      (data) => {}
    );
  };

  updateQueue = (id, type) => {
    let updateQueue = this.state.updateQueue;
    let deletionQueue = this.state.deletionQueue;
    type === "update" ? updateQueue.push(id) : deletionQueue.push(id);
    this.setState({ updateQueue, deletionQueue });
  };

  removeFromQueue = (id, type) => {
    let filteredUpdateQueue = this.state.updateQueue.filter(
      (item) => item !== id
    );
    let filteredDeletionQueue = this.state.deletionQueue.filter(
      (item) => item !== id
    );
    type === "update"
      ? this.setState({ updateQueue: filteredUpdateQueue })
      : this.setState({ deletionQueue: filteredDeletionQueue });
  };

  handleTogglePlatform = (e, el) => {
    if (_.isArray(this.state.platforms)) {
      return;
      if (this.state.platforms.length > 0) {
        this.state.platforms.forEach((platform) => {
          this.updateQueue(platform.id, "update");
          e.preventDefault();
          this.props.updatePlatforms(
            this.props.match.params.location_id,
            platform,
            () => {
              this.removeFromQueue(platform.id, "update");
            }
          );
        });
      }
    }
  };

  handleUpdatePlatforms = (e, el) => {
    if (_.isArray(this.state.platforms)) {
      if (this.state.platforms.length > 0) {
        this.state.platforms.forEach((platform) => {
          this.updateQueue(platform.id, "update");
          e.preventDefault();
          this.props.updatePlatforms(
            this.props.match.params.location_id,
            platform,
            () => {
              this.removeFromQueue(platform.id, "update");
            }
          );
        });
      }
    }
  };

  handleUpdateSettings = (e) => {
    const isMessageUpdate = e;
    if (isMessageUpdate) {
      e.preventDefault();
      this.setState({ defaultMessageLoader: true });
    }
    this.props.updateLocationSettings(
      this.props.match.params.location_id,
      {
        default_message: this.state.default_message,
        notification_email: this.state.notificationEmail,
      },
      () => {
        if (isMessageUpdate) {
          this.setState({ defaultMessageLoader: false });
        }
      }
    );
  };

  handleAddPlatform = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      this.state.new_platform_id !== null &&
      this.state.new_platform_id !== "default" &&
      this.state.new_platform_text !== ""
    ) {
      this.props.updatePlatforms(
        this.props.match.params.location_id,
        { id: this.state.new_platform_id, link: this.state.new_platform_link },
        () => {
          this.setState({
            new_platform_id: null,
            new_platform_link: "",
          });
        }
      );
    }
  };

  handleDeletePlatform = (e, platform_id) => {
    this.updateQueue(platform_id, "delete");
    this.props.deleteLocationPlatform(
      this.props.match.params.location_id,
      platform_id,
      () => this.removeFromQueue(platform_id, "delete")
    );
  };

  autoFeatureFiveStarReview = () => {
    this.setState({ featureReview: !this.state.featureReview });
    let location_id = this.props.locationData.id;
    this.props.autoFeatureFiveStarReviews(location_id);
  };

  componentDidUpdate(prevProps, prevState) {
    let { auto_add_reviews } = this.props.locationData.settings;
    if (auto_add_reviews !== prevProps.locationData.settings.auto_add_reviews) {
      this.setState({ featureReview: auto_add_reviews });
    }
  }

  validatePlatformLink = (link = "") => {
    if (link) {
      if (link.length === 0) {
        return false;
      }
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      let isValid = !!pattern.test(link);
      return isValid;
    }
  };

  setYelpAndGoogleReview = (new_platform_link) => {
    this.setState({ new_platform_link }, () => {
      this.handleAddPlatform();
    });
  };

  handleNotificationEmailsChange(data) {
    this.setState({ notificationEmail: data });
    setTimeout(() => {
      this.handleUpdateSettings();
    }, 50);
  }

  validateNotificationEmail(data) {
    return String(data).match(/^\S+@\S+$/) !== null;
  }

  handleShareableLinkClick() {
    let el = [...document.getElementsByClassName("shareable-link")][0];
    if (el) {
      this.copyToClipboard(el);
      el.select();
    }
  }

  copyToClipboard(elem) {
    var targetId = "_hiddenCopyText_";
    var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    var origSelectionStart, origSelectionEnd;
    if (isInput) {
      target = elem;
      origSelectionStart = elem.selectionStart;
      origSelectionEnd = elem.selectionEnd;
    } else {
      target = document.getElementById(targetId);
      if (!target) {
        var target = document.createElement("textarea");
        target.style.position = "absolute";
        target.style.left = "-9999px";
        target.style.top = "0";
        target.id = targetId;
        document.body.appendChild(target);
      }
      target.textContent = elem.textContent;
    }
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);
    var succeed;
    try {
      succeed = document.execCommand("copy");
    } catch (e) {
      succeed = false;
    }
    if (currentFocus && typeof currentFocus.focus === "function") {
      currentFocus.focus();
    }
    if (isInput) {
      elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
      target.textContent = "";
    }
    return succeed;
  }

  render() {
    const allowedPlatforms = this.props.platforms.filter(
      (o1) =>
        this.props.locationData.platforms.filter((o2) => o2.id === o1.id)
          .length === 0
    );
    const locationHash = this.props.locationData.hash;
    const sharableLinkUrl = `${window.location.origin}/new-review/${locationHash}`;

    return (
      <ViewWithLoader isLoading={this.state.isFetching}>
        <div className="col-12 col-md-8 offset-md-2">
          <div className="mt-2 mb-2 settings-for-review-widget">
            <h3>Manage Review Widget</h3>
            <Button
              classes={"btn-secondary"}
              link
              to={`/locations/${this.props.match.params.location_id}/widget`}
            >
              <i className="fas fa-cog" /> Review Widget Settings
            </Button>
            <Toggle
              toggle={this.state.featureReview}
              onChange={() => this.autoFeatureFiveStarReview()}
              label={"Automatically feature 5 star reviews"}
            />
          </div>
          <hr />
          <DefaultMessage
            loading={this.state.defaultMessageLoader}
            defaultMessage={this.state.default_message}
            handleInputChange={this.handleInputChange}
            handleUpdateSettings={this.handleUpdateSettings}
          />
          <hr />
          <h3>Notification Emails</h3>
          <div>
            <p>
              Type an email address and press enter to save, multiple email
              addresses can be saved.
            </p>
            <TagInput
              placeholder="Email Address"
              value={this.state.notificationEmail || []}
              onChange={(data) => this.handleNotificationEmailsChange(data)}
              format={(str) => String(str).trim().toLowerCase()}
              validate={this.validateNotificationEmail}
            />
          </div>
          <hr />
          <h3>Shareable Link</h3>
          <div
            className={"shareable-link-wrapper"}
            onClick={(e) => this.handleShareableLinkClick(e)}
          >
            <input
              className={"shareable-link"}
              defaultValue={sharableLinkUrl}
            />
          </div>
          <QRCode
            value={sharableLinkUrl}
            size={250}
            bgColor="#FFFFFF"
            fgColor="#000000"
            eyeRadius={10}
            logoImage="https://www.reviewloop.app/favicon.png"
            logoWidth={60}
            logoHeight={60}
            removeQrCodeBehindLogo={true}
          />
          <hr />
          <h3>Platforms</h3>
          {this.props.locationData.platforms.map((el, i) => {
            return (
              <div className="platform-row" key={i}>
                <div className="platform-name-wrapper">
                  <img
                    key={i}
                    title={el.name}
                    className={`social-icon ${
                      el.pivot.active ? "enabled" : ""
                    }`}
                    src={el.icon_url || ""}
                  />
                  <Toggle
                    toggle={el.pivot.active || false}
                    onChange={(e) => this.handlePlatformActiveChange(e, el)}
                    label={""}
                  />
                </div>
                <input
                  className="form-control custom-height-width"
                  type="text"
                  value={
                    this.state.updateQueue.includes(el.id)
                      ? "Saving..."
                      : el.pivot.link
                  }
                  onChange={(e) => this.handlePlatformChange(e, i)}
                />
                <div className="platform-controls">
                  {this.state.updateQueue.includes(el.id) ? (
                    <MiniLoader classes="update-margin" />
                  ) : (
                    <Button
                      classes="btn-update"
                      onClick={(e) => this.handleUpdatePlatforms(e, el)}
                    >
                      Update
                    </Button>
                  )}
                </div>
                <div className="input-group-append">
                  {this.state.deletionQueue.includes(el.id) ? (
                    <MiniLoader classes="trash-margin" />
                  ) : (
                    <Button
                      classes="btn-delete"
                      onClick={(e) => this.handleDeletePlatform(e, el.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  )}
                </div>
                {!this.validatePlatformLink(el.pivot.link) && (
                  <div className="alert alert-warning mt-2">
                    The link you have entered isn't a valid link. Please enter a
                    valid link.
                  </div>
                )}
              </div>
            );
          })}
          <hr />
          <h3>Add Platform</h3>
          <FormGroupSelect
            label="Select a platform"
            onChange={(e) => this.handleInputChange(e, "new_platform_id")}
            options={allowedPlatforms}
          />
          {this.state.new_platform_id === "1" ||
          this.state.new_platform_id === "3" ? (
            <YelpAndGoogleReviews
              platformId={this.state.new_platform_id}
              setYelpAndGoogleReview={this.setYelpAndGoogleReview}
            />
          ) : (
            <FormGroup
              classes=""
              type="text"
              placeholder="Review link"
              value={this.state.new_platform_link}
              onChange={(e) => this.handleInputChange(e, "new_platform_link")}
            />
          )}
          {!this.validatePlatformLink(this.state.new_platform_link) &&
            this.state.new_platform_link.length > 0 && (
              <div className="alert alert-danger mt-2">
                The link you have entered isn't a valid link. Please enter a
                valid link.
              </div>
            )}
          {this.state.new_platform_id !== "1" &&
            this.state.new_platform_id !== "3" && (
              <Button
                disabled={
                  !this.validatePlatformLink(this.state.new_platform_link)
                }
                onClick={(e) => this.handleAddPlatform(e)}
              >
                Add
              </Button>
            )}
          <hr />
          <AdvancedSettings
            deleteLocationToken={this.props.deleteLocationToken}
            locationId={this.props.match.params.location_id}
            createLocationToken={this.props.createLocationToken}
            locationData={this.props.locationData}
          />
        </div>
      </ViewWithLoader>
    );
  }
}

const mapStateToProps = ({
  locations: {
    show: { data: locationData },
    platforms: { data: platforms },
    integrations: { yelp: yelpData },
  },
}) => ({
  locationData,
  platforms,
  yelpData,
});

const connected = connect(mapStateToProps, {
  autoFeatureFiveStarReviews,
  fetchLocation,
  fetchPlatforms,
  updatePlatforms,
  updateLocationSettings,
  deleteLocationPlatform,
  createLocationToken,
  deleteLocationToken,
})(LocationsEdit);

export { connected as LocationsEdit };
