import React, { Component } from 'react'
import { Button } from "../../../common";

export default class IncorrectData extends Component {
    state = {}
    render() {
        return (
            this.props.incorrectDataFormatError === true ?
                <div className="csv-format-error-container">
                    <h1>Incorrect CSV File Format. Please check your CSV file</h1>
                    <p className="csv-text-not-big">
                        CSV files must have the following column names: <span>first_name</span>, <span>last_name</span>, <span>phone_number</span>
                    </p>
                  <div className="sample-csv">
                    <p>Sample CSV</p>
                    <table>
                      <tbody>
                      <tr>
                        <td className="column-name" >first_name</td>
                        <td className="column-name" >last_name</td>
                        <td className="column-name" >phone_number</td>
                      </tr>
                      <tr>
                        <td>James</td>
                        <td>Smith</td>
                        <td>(888) 777-9999</td>
                      </tr>

                      <tr>
                        <td>Tyler</td>
                        <td>Waston</td>
                        <td>(888) 444-3333</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                    <Button onClick={this.props.resetState} classes="test-button mt-4">Retry</Button>
                </div>
                : null
        )
    }
}
