import React, { Component } from 'react';
import { array, func } from "prop-types";
import { NavLink } from "react-router-dom";

import emptyLocations from "../../../images/empty-locations.svg";

import { connect } from 'react-redux';
import { fetchLocations } from "../redux/actions";

import { ViewWithLoader, LocationLink } from "../../../common";

class LocationsIndex extends Component {

  static propTypes = {
    fetchLocations: func,
    locationsData: array
  };

  state = {
    isFetching: true
  };

  componentDidMount() {
    this.props.fetchLocations(() => this.setState({ isFetching: false }));
  }

  renderLocation = () => {
    /*
    * If has locations
    * */
    if(this.props.locationsData.length){
      return this.props.locationsData.map((data, i) => {
        return (
          <LocationLink
            key={data.id}
            id={data.id}
            name={data.name}
            location={data}
            platforms={data.platforms}
            linkTo={`/locations/${data.id}`}
          />
        );
      });
    } else {
      /*
      * If locations empty
      * */
      return (
        <div className="row welcome-empty-container">
          <div className="col-12 text-center">
            <img src={emptyLocations} alt=""/>
            <h3>Welcome to ReviewLoop 🎉</h3>
            <p>You have no locations yet. Let's get you started:</p>
            <NavLink className="pointer btn" to="/locations/create">Create Location</NavLink>
          </div>
        </div>
      )
    }
  };

  render() {
    return (
      <ViewWithLoader
        classes="row purple-loader"
        isLoading={this.state.isFetching}
      >
        <div className="col-12 mb-4 text-right">
          <NavLink className="pointer btn" to="/locations/create">Create Location</NavLink>
        </div>

        {this.renderLocation()}
      </ViewWithLoader>
    )
  }
}
const mapStateToProps = ({
  locations: { index: { data: locationsData } },
}) => ({
  locationsData,
});

const connected = connect(mapStateToProps, {
  fetchLocations
})(LocationsIndex);

export { connected as LocationsIndex };