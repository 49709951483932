import React, { Component } from 'react';

class Toggle extends Component {

  state = {
    toggle: false,
  }

  componentDidMount() {
    this.state.toggle = this.props.toggle;
  }

  componentDidUpdate(old, nw) {
    this.state.toggle = this.props.toggle;
  }

  handleToggleClick(e) {
    //const newToggleState = (this.state.toggle) ? 0 : 1;
    //this.setState({ toggle: newToggleState })
    this.props.onChange(e);
  }

  render() {
    return (
      <div className={"toggle-comp " + (this.props.toggle === true || this.props.toggle === 1 ? "is-active" : "")}>
        <span className="toggle-comp_label">{this.props.label}</span>
        <a className="toggle-comp_toggle" href="#" onClick={(e) => this.handleToggleClick(e)}/>
      </div>
    )
  }
};
export { Toggle };