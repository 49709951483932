import React from 'react';

export const AppBanner = (props) => {
  return(
    <div className="container-fluid app-banner">
      <div className="row">
        <div className="col-12">
          <h3><span className="banner-label">{props.label}</span>{props.text}</h3>
        </div>
      </div>
    </div>
  )
};