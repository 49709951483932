import React, { Component, Fragment } from "react";
import moment from "moment";
import { RatingsEmoji } from "../../../common";
import ReviewCard from "../components/ReviewCard";
import Check from "../../../images/FA-Check.svg";
import Triangle from "../../../images/FA-Triangle.svg";

class CustomersTable extends Component {
  state = {
    id: null,
    filter: "",
  };

  renderStatus = (props) => {
    const className = `status-${props.value.toLowerCase()}`;
    return <span className={className}>{props.value}</span>;
  };

  renderRating = (props) => {
    return props.value !== "-" ? (
      <div>
        <img
          alt="ratings_emoji"
          src={RatingsEmoji[parseInt(props.value, 10)]}
        />
        <span>{parseInt(props.value, 10)}</span>
      </div>
    ) : (
      <span className="rating">-</span>
    );
  };

  renderToolTip = (error_message) => {
    return <span className="error-message-twilio">{error_message}</span>;
  };

  setId = (id) => {
    id !== this.state.id && this.setState({ id });
  };

  clearId = () => {
    this.setState({ id: null });
  };

  deliveryStatus = (props) => {
    let error_message = props.record.error_message;
    let id = props.record.id;
    return props.record.error_message.length !== 0 ? (
      <span>
        <img
          className="icon-sizes failure-red"
          src={Triangle}
          onMouseOver={() => this.setId(id)}
          onMouseLeave={() => this.clearId()}
        />
        {this.state.id === id ? this.renderToolTip(error_message, id) : null}
      </span>
    ) : (
      <img className="icon-sizes success-green" src={Check} />
    );
  };

  formatPhoneNumber = (s) => {
    let s2 = ("" + s).replace(/\D/g, "");
    let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  };

  handleFilterChange = (e) => {
    this.setState({
      filter: String(e.target.value).toLowerCase(),
    });
  };

  render() {
    const fields = [
      {
        name: "delivery_status",
        displayName: "Delivered",
        inputFilterable: true,
        sortable: false,
        render: this.deliveryStatus,
      },
      {
        name: "name",
        displayName: "Customer",
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "created_at",
        displayName: "Date",
        inputFilterable: true,
        exactFilterable: true,
        sortable: false,
      },
      { name: "phone_number", displayName: "Phone" },
      {
        name: "status",
        displayName: "Status",
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        render: this.renderStatus,
      },
      {
        name: "rating",
        displayName: "Rating",
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        render: this.renderRating,
      },
      {
        name: "review",
        displayName: "Review",
        inputFilterable: true,
        sortable: true,
      },
    ];

    const data = this.props.reviews
      .map((el, i) => {
        el.customer === null &&
          (el.customer = {
            created_at: "",
            custom_message: null,
            customer: null,
            error_message: "",
            featured: 0,
            hash: "",
            id: null,
            pos: null,
            rating: null,
            review: null,
            status: { id: null, name: "" },
            updated_at: "",
          });
        return {
          ...el,
          rating: el.rating !== null ? el.rating : "-",
          created_at: moment(el.created_at).format("l"),
          phone_number: this.formatPhoneNumber(el.customer.phone_number),
          error_message: el.error_message,
          name: `${el.customer.first_name} ${el.customer.last_name}`,
          status: el.status.name,
        };
      })
      .filter((n) => {
        return (
          this.state.filter.trim() === "" ||
          String(n.name || "")
            .toLowerCase()
            .includes(this.state.filter) ||
          String(n.rating || "")
            .toLowerCase()
            .includes(this.state.filter) ||
          String(n.status || "")
            .toLowerCase()
            .includes(this.state.filter) ||
          String(n.review || "")
            .toLowerCase()
            .includes(this.state.filter) ||
          false
        );
      });

    return (
      <Fragment>
        <div className={"review-controls"}>
          <input
            type={"text"}
            className={"review-card-filter form-control"}
            placeholder={"Search Reviews"}
            onChange={this.handleFilterChange}
          />
          <div className={"review-control-buttons"}>&nbsp;</div>
        </div>
        <div className={"review-card-wrapper"}>
          {data.map((n, idx) => (
            <ReviewCard
              key={idx}
              reviewId={n.id}
              name={n.name}
              rating={n.rating}
              review={n.review}
              customMessage={n.custom_message}
              resendCount={n.resend_count}
              lastResendDate={n.last_resend}
              phoneNumber={n.phone_number}
              status={n.status}
              createdAt={n.created_at}
              platformIds={n.platform_ids}
              data={n}
              platforms={this.props.platforms}
              linksClicked={n.links_clicked}
              onResendClick={this.props.onResendClick}
            />
          ))}
        </div>
      </Fragment>
    );
  }
}

export default CustomersTable;
