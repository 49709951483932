import React, { Component } from 'react'
import { FormGroup, Button } from "../../../common";

export default class ReviewInput extends Component {

    state = {
        "firstName": this.props.firstName,
        "lastName": this.props.lastName,
        "phoneNumber": this.props.phoneNumber
    }

    handleInputChange = (e, type) => {
        this.setState({ [type]: e.target.value });
    };

    render() {
        let hightlightInvalidDataPhone = false;
        let hightlightInvalidFirstName = false;
        let highlightInvalidLastName = false;
        // Phone check
        let phone = this.state.phoneNumber.replace(/[^0-9]/g, '');
        phone.length !== 10 && (hightlightInvalidDataPhone = true);
        let isNum = /^\d+$/.test(phone);
        isNum === false && (hightlightInvalidDataPhone = true);
        // First name check
        this.state.firstName.length === 0 && (hightlightInvalidFirstName = true);
        // Last name check
        this.state.lastName.length === 0 && (highlightInvalidLastName = true);
        let obj = {
            "first_name": this.state.firstName,
            "last_name": this.state.lastName,
            "phone_number": this.state.phoneNumber
        };
        let invalidMessageLastName = "";
        let invalidMessageFirstName = "";
        let invalidMessagePhoneNumber = "";
        highlightInvalidLastName === true && (invalidMessageLastName = "Please enter a last name");
        hightlightInvalidFirstName === true && (invalidMessageFirstName = "Please enter a first name");
        hightlightInvalidDataPhone === true && (invalidMessagePhoneNumber = "Please enter a valid phone number");
        let buttonDisabled = hightlightInvalidDataPhone || hightlightInvalidFirstName || highlightInvalidLastName;
        return (
            <div className="invalid-container">
                <div className="customer-info">
                    {
                        this.props.editing === true ?
                            <div>
                                <FormGroup
                                    classes=""
                                    classesInput="invalid-input"
                                    label="First Name"
                                    value={this.state.firstName}
                                    onChange={e => this.handleInputChange(e, "firstName")}

                                />
                                <p className="invalid-highlight-p">{invalidMessageFirstName}</p>
                            </div>
                            :
                            <div className="inline-highlight-container">
                                <i className={hightlightInvalidFirstName === false ? `fa fa-check fa-lg` : `fa fa-times fa-lg`}></i>
                                <p className={`valid ` + (hightlightInvalidFirstName === true && `highlight-invalid`)} >
                                    First Name: {this.props.firstName}
                                </p>
                            </div>

                    }
                    {
                        this.props.editing === true ?
                            <div>
                                <FormGroup
                                    classes=""
                                    classesInput="invalid-input"
                                    label="Last Name"
                                    value={this.state.lastName}
                                    onChange={e => this.handleInputChange(e, "lastName")}
                                />
                                <p className="invalid-highlight-p">{invalidMessageLastName}</p>

                            </div>
                            :
                            <div className="inline-highlight-container">
                                <i className={highlightInvalidLastName === false ? `fa fa-check fa-lg` : `fa fa-times fa-lg`}></i>
                                <p className={`valid ` + (highlightInvalidLastName === true && `highlight-invalid`)} >
                                    Last Name: {this.props.lastName}
                                </p>
                            </div>

                    }
                    {
                        this.props.editing === true ? <div>
                            <FormGroup
                                classes=""
                                classesInput="invalid-input"
                                label="Phone Number"
                                value={this.state.phoneNumber}
                                onChange={e => this.handleInputChange(e, "phoneNumber")}
                            />
                            <p className="invalid-highlight-p">{invalidMessagePhoneNumber}</p>
                        </div> :
                            <div className="inline-highlight-container">
                                <i className={hightlightInvalidDataPhone === false ? `fa fa-check fa-lg` : `fa fa-times fa-lg`}></i>
                                <p className={`valid ` + (hightlightInvalidDataPhone === true && `highlight-invalid`)} >
                                    Phone Number: {this.props.phoneNumber}
                                </p>
                            </div>
                    }

                </div>
                {
                    this.props.editing === true ?
                        <div className="invalid-actions">
                            <Button disabled={buttonDisabled} onClick={() => this.props.saveAndClearStateOnEdit(this.props.id, "save", obj)} >Save</Button>
                            <Button onClick={() => this.props.removeReview(this.props.id)} classes="test-button">Delete</Button>
                        </div >
                        :
                        <div className="invalid-actions">
                            <Button onClick={() => this.props.saveAndClearStateOnEdit(this.props.id, "editing")} >Edit</Button>
                            <Button onClick={() => this.props.removeReview(this.props.id)} classes="test-button">Delete</Button>
                        </div >
                }
            </div>

        )
    }
}
