import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { rootUrl } from "../../../lib/api";
import { requestHeaders, getToken } from "../../../lib/authHelpers";
import { Button, MiniLoader, FormGroup } from "../../../common";
import axios from "axios";

export default class CodeSnippet extends Component {
    state = {
        copySuccess: false,
        email: '',
        emailSuccess: '',
        emailError: ''
    }

    sendEmail = async () => {
        if (this.state.email.length > 0) {
            const token = getToken();
            const url = `${rootUrl}/locations/emailCode`;
            const postData = {
                email: this.state.email,
                snippet: this.codeArea,
                locationEmail: this.props.locationEmail,
                locationName: this.props.locationName
            }
            const headers = requestHeaders(token);
            try {
                const response = await axios.post(url, postData, headers);
                response.status === 200 && this.setState({ emailSuccess: 'Sent!' })
            } catch (err) {
                this.setState({ emailError: "There was a problem sending the email. Please try again." })
            }
        } else {
            this.setState({ emailError: "Please enter an email" })
        }

    }

    resetEmail = () => {
        this.setState({ emailError: '', emailSuccess: '', email: '' })
    }

    copyToClipboard = (e) => {
        let code = this.codeArea;
        code.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: true });
    };

    handleInputChange = (e, type) => {
        this.setState({ [type]: e.target.value });
    };

    generateCode = (code) => {
        return {
            __html: code
        }
    };

    render() {
        let code = `&lt;script rel="text/javascript" name="locationInfo" src="https://rl.tresio.co/widget/script.js" content="${this.props.locId}"&gt;&lt;/script&gt;`;
        this.codeArea = code;

        if (this.props.locId === null) {
            return (
                <MiniLoader />
            )
        } else {
            return (
                <div className="form-page code-area-container row">
                    <div className="email-segment col-12">
                        <h2>Option: 1</h2>
                        <p>Email website embed code to your developer.</p>
                        {this.state.emailSuccess.length > 0 ? <div className="alert alert-success">{this.state.emailSuccess}<Button classes="email-alert-button" onClick={this.resetEmail}>Close</Button></div> :
                            <div className="code-area-form-segment">
                                <FormGroup
                                    classes='new-form'
                                    // classesInput={}
                                    // label="First Name"
                                    placeholder={'email'}
                                    value={this.state.email}
                                    onChange={e => this.handleInputChange(e, "email")}
                                />
                                <Button onClick={this.sendEmail}>Send</Button>
                            </div>
                        }
                    </div>
                    <hr />
                    <h2>Option: 2</h2>
                    <p>Copy the code below, and paste where you want the widget to appear.</p>
                    <div className="code-segment col-12">
                        <code dangerouslySetInnerHTML={this.generateCode(code)}></code>
                        {/* {document.queryCommandSupported('copy') && <Button classes={this.state.copySuccess ? "success-green" : ""} onClick={this.copyToClipboard}>{this.state.copySuccess ? "Copied!" : "Copy"}</Button>} */}
                    </div>
                </div>
            )
        }
    }
}
