import moment from "moment";
import React, { Component } from "react";
import StarRatingComponent from "react-star-rating-component";
import CheckIcon from "../../../images/FA-Check.svg";

export default class ReviewCard extends Component {
  render() {
    let rounded = (Math.round(this.props.rating * 2) / 2).toFixed(1);
    let roundedNumber = parseInt(rounded);
    const resends = this.props.resendCount || 0;
    const maxResends = resends >= 3;
    const resendMetaText = resends > 0 ? ` (${resends} / 3 resends)` : "";
    const showResend =
      this.props.customMessage && this.props.status !== "Reviewed";
    return (
      <div
        className={
          this.props.positionChangeEffect === this.props.id
            ? "review-card-container position-effect"
            : "review-card-container"
        }
      >
        <div className="review-widget-head">
          <div className="review-person-name">
            <div>{`${this.props.name}`}</div>
            <div className={"review-phone"}>{this.props.phoneNumber}</div>
          </div>
          <div className="star-container">
            {this.props.rating !== "-" && (
              <StarRatingComponent
                name="rate2"
                editing={false}
                renderStarIcon={() => <i className="fa fa-star "></i>}
                renderStarIconHalf={() => <i className="fa fa-star-half"></i>}
                emptyStarColor={"#d3d3d3"}
                starColor={"#9964F2"}
                value={roundedNumber}
              />
            )}
            {this.props.lastResendDate ? (
              <div className={`review-status resent`}>
                {`Resent ${moment(this.props.lastResendDate).format(
                  "D MMM YY"
                )}`}
              </div>
            ) : (
              <div
                className={`review-status ${String(
                  this.props.status
                ).toLowerCase()}`}
              >
                {`${this.props.status} ${moment(this.props.createdAt).format(
                  "D MMM YY"
                )}`}
              </div>
            )}
          </div>
        </div>
        <div className="review-widget-review">
          {this.props.review || <span className={"hint"}>No Review</span>}
        </div>
        <div className={"review-widget-delivery-wrapper"}>
          <div className={"delivery-wrapper"}>
            <img className="icon-sizes success-green" src={CheckIcon} />
            {maxResends && (
              <div className={"resend-link"}>{resendMetaText}</div>
            )}
            {!maxResends && (
              <div
                className={"resend-link"}
                onClick={(e) => this.props.onResendClick(e, this.props)}
              >
                <span className={"accent"}>Resend</span>
                {resendMetaText}
              </div>
            )}
          </div>
          <div className={"social-icons"}>
            {this.props.platformIds.map((n, idx) => {
              let platform = (this.props.platforms || []).find(
                (o) => parseFloat(o.id) === parseFloat(n)
              );
              if (platform) {
                const isClicked = this.props.linksClicked.includes(platform.id);
                const title = isClicked
                  ? `Client clicked on ${platform.name}.`
                  : `Client did not click on ${platform.name}.`;
                return (
                  <img
                    key={idx}
                    title={title}
                    className={`social-icon ${isClicked ? "link-clicked" : ""}`}
                    src={platform.icon_url || ""}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

//  ${this.props.linksClicked.includes(platform.id) ? 'link-clicked' : ''}
