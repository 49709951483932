import axios from "axios";
import { rootUrl } from "../../../lib/api";
import { requestHeaders, getToken } from "../../../lib/authHelpers";
import { dispatchHelper } from "../../../lib/actionHelpers";
import { authLogoutAndRedirect } from "./";

import {
  FETCHING_USERS,
  FETCHING_USER,
  CREATING_USER,
  REGISTER_USER,
  UPDATING_USER_PROFILE,
  UPDATING_USER_PASSWORD,
  UPDATING_USER_PAYMENT,
  CANCELLING_USER_SUBSCRIPTION
} from "./types";

export const fetchUsers = () => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_USERS);
  const token = getToken();
  const url = `${rootUrl}/users`;
  const headers = requestHeaders(token);

  try {
    let response = await axios.get(url, headers);
    dispatching(response);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const fetchUser = ( callback = () => {} ) => async dispatch => {
  const dispatching = dispatchHelper(FETCHING_USER);
  const token = getToken();
  const url = `${rootUrl}/user`;
  const headers = requestHeaders(token);
  try {
    let response = await axios.get(url, headers);
    dispatching(response, callback);
  } catch (err) {
    dispatch(authLogoutAndRedirect());
  }
};

export const createUser = params => async () => {
  const dispatching = dispatchHelper(CREATING_USER);
  const token = getToken();
  const url = `${rootUrl}/users/new`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);

  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response);
  }
};

export const updateUserProfile = (params, callback = () => {}) => async () => {
  const dispatching = dispatchHelper(UPDATING_USER_PROFILE);
  const token = getToken();
  const url = `${rootUrl}/user/profile`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response, callback);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response, callback);
  }
};

export const cancelUserSubscription = (params, callback = () => {}) => async () => {
  const dispatching = dispatchHelper(CANCELLING_USER_SUBSCRIPTION);
  const token = getToken();
  const url = `${rootUrl}/user/subscription/${params}`;
  const headers = requestHeaders(token);
  try {
    const response = await axios.delete(url, headers);
    dispatching(response, callback);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response, callback);
  }
};

export const updateUserPassword = (params, callback = () => {}) => async () => {
  const dispatching = dispatchHelper(UPDATING_USER_PASSWORD);
  const token = getToken();
  const url = `${rootUrl}/user/password`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response, callback);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response, callback);
  }
};

export const updateUserPayment = (params, callback = () => {}) => async () => {
  const dispatching = dispatchHelper(UPDATING_USER_PAYMENT);
  const token = getToken();
  const url = `${rootUrl}/user/source`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);
  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response, callback);
  } catch (err) {
    const response = { status: 500 };
    dispatching(response, callback);
  }
};

export const registerUser = (params, callback) => async () => {
  const dispatching = dispatchHelper(REGISTER_USER);
  const token = getToken();
  const url = `${rootUrl}/public/users/register`;
  const postParams = JSON.stringify(params);
  const headers = requestHeaders(token);

  try {
    const response = await axios.post(url, postParams, headers);
    dispatching(response, callback);
  } catch (error) {
    const response = error.response;
    dispatching(response, callback);
  }
};